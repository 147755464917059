import * as React from 'react';
import styled from 'styled-components';
import {Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TableContainer} from '@mui/material';

import { IEstimate, estimationGetHistoryAction } from '../../../modules';
import { useSelector, useDispatch } from 'react-redux';
import { IRootStore } from '../../../RootStore';

const Container = styled.div`
    width: 100%;
`;

export interface IHistoryList {
}

export const HistoryList: React.FC<IHistoryList> = (props: IHistoryList) => {

    const dispatch = useDispatch();

    const {history, estimate} = useSelector((state: IRootStore) => state.estimation);

    const [size, setSize] = React.useState(10);
    const [page, setPage] = React.useState(0);

    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };

    React.useEffect(() => {
        dispatch(estimationGetHistoryAction({estimateId: estimate.id}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangePage = (event: any, _page: number) => {
        setPage(_page);
    };

    const onChangeRowsPerPage = (event: any) => {
        const _size = parseInt(event.target.value, 10);
        setSize(_size);
        setPage(0);
    };

    const startingIndex = page * size;

    const rowEls = [...history]
        .sort((a, b) => {
            if (a.createDate > b.createDate) return -1
            else if (a.createDate < b.createDate) return 1
            else return 0;
        })
        .slice(startingIndex, startingIndex + size)
        .map((x, index) => (
            <TableRow key={index}>
                <TableCell data-testid="date">{ new Intl.DateTimeFormat('en-US', options).format(new Date(x.createDate)) }</TableCell>
                <TableCell data-testid="event">{x.event}</TableCell>
                <TableCell data-testid="user">{x.createUser.name}</TableCell>
            </TableRow>
        ));
    
    return (
        <Container>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Event</TableCell>
                            <TableCell>User</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { rowEls }
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={3}
                    count={history.length}
                    rowsPerPage={size}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    component="div"
                />
            </TableContainer>
        </Container>
    );
};
