import { call, put, takeEvery } from 'redux-saga/effects';

import { SummaryExportCSVAction, SUMMARY_EXPORT_CSV } from './SummaryStore';
import {downloadCsv} from './Service';
import { notificationsAddAction } from '../notification';

export function* exportCSV(action: SummaryExportCSVAction) {

    const {id, versionId, summaryType} = action.payload;

    try {
        const result: unknown = yield call(downloadCsv, id, versionId, summaryType);

        const csvContent = `data:text/csv;charset=utf-8,${result}`;

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('id', `download-${new Date().getTime()}`);
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${id}_export.csv`);
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

    } catch (e: any) {
        console.error(e);

        const message = `Error downloading CSV: ${e.message}`;
        // yield put(EstimateStore.estimationErrorAction({error: e.message}));
        yield put(notificationsAddAction({store: 'estimate', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* summarySagas() {
    yield takeEvery(SUMMARY_EXPORT_CSV, exportCSV);
}