import * as React from 'react';
import styled from 'styled-components';
import {TextField, FormControlLabel, Switch} from '@mui/material';

import {IUser} from '../../../modules';

const Container = styled.div`

    min-width: 400px;

    .email-container, .name-container {
        margin-bottom: 25px;
    }
`;

export interface IEditUserProps {
    user: IUser;
    onChange: (user: IUser) => void;
}

export const EditUser: React.FC<IEditUserProps> = (props: IEditUserProps) => {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [isActive, setIsActive] = React.useState(false);
    const [isAdmin, setIsAdmin] = React.useState(false);

    React.useEffect(() => {
        setName(props.user.name);
        setIsActive(props.user.isActive);
        setIsAdmin(props.user.isAdmin);
    }, [props.user]);

    const onEmailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = e.target;
        setEmail(value);

        const user = {...props.user};
        user.email = value;
        props.onChange(user);
    };


    const onNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = e.target;
        setName(value);

        const user = {...props.user};
        user.name = value;
        props.onChange(user);
    };

    const onActiveChange = () => {
        setIsActive(!isActive);

        const user = {...props.user};
        user.isActive = !isActive;
        props.onChange(user);
    };

    const onAdminChange = () => {
        setIsAdmin(!isAdmin);

        const user = {...props.user};
        user.isAdmin = !isAdmin;
        props.onChange(user);
    };

    return (
        <Container>
            {
                !props.user.id &&
                <div className="email-container">
                    <TextField value={email} variant="standard" size="small" label="Email" fullWidth={true} onChange={onEmailChange} />    
                </div>
            }

            <div className="name-container">
                <TextField value={name} variant="standard" size="small" label="Name" fullWidth={true} onChange={onNameChange} />
            </div>

            <div className="control-container">
                <FormControlLabel
                    label={`Active`}
                    control={<Switch checked={isActive} onChange={() => onActiveChange()} />}
                    />

                <FormControlLabel
                    label={`Admin`}
                    control={<Switch checked={isAdmin} onChange={() => onAdminChange()} />}
                    />
            </div>
        </Container>
    );
};
