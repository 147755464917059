import * as React from 'react';
import styled from 'styled-components';
import {Button, TextField, LinearProgress, Icon, Paper} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

import {IRootStore} from '../../RootStore';
import {authLoginAction} from '../../modules';

const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    .login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #fff;
        max-width: 750px;
        width: 100%;
        height: 350px;
        min-width: 500px;

        padding: 20px;

        > DIV {
            margin: 15px 5px;
        }

        .message-container {

            border-radius: 4px;
            padding: 5px;
            background-color: rgb(253, 236, 234);
            display: flex;
            align-items: center;


            .message {
                display: inline-block;
                color: red;
                margin-left: 15px;
            }
        }
    }
`;

export interface ILoginPageProps {

}

export const LoginPage: React.FC<ILoginPageProps> = (props: ILoginPageProps) => {
    const dispatch = useDispatch();

    const [attempts, setAttempts] = React.useState(0);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const {message, isBusy} = useSelector((state: IRootStore) => state.auth);

    const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUsername(event.target.value);
    };

    const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPassword(event.target.value);
    };

    const onLoginClick = () => {
        setAttempts(attempts + 1);
        dispatch(authLoginAction({email: username, password}));
    };

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            onLoginClick();
        }
    }

    return (
        <Container>
            <Paper elevation={3}>
                <div className="login">

                    {
                        attempts > 0 && message !== '' &&
                        <div>
                            <div className="message-container">
                                <Icon color="error">error_outline</Icon><span className="message">{message}</span>
                            </div>
                        </div>
                    }
                    {
                        isBusy && <LinearProgress />
                    }
                    <div>
                        <TextField placeholder="Username" onChange={onUsernameChange} value={username} required={true} fullWidth={true} onKeyDown={onKeyDown} />
                    </div>
                    <div>
                        <TextField placeholder="Password" type="Password" onChange={onPasswordChange} value={password} required={true} fullWidth={true} onKeyDown={onKeyDown} />
                    </div>
                    <div>
                        <Button variant="contained" color="primary" disabled={isBusy} onClick={onLoginClick}>Login</Button>
                    </div>
                </div>

            </Paper>
        </Container>
    );
};
