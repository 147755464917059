import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Icon, Stepper, Step, StepLabel, StepIconProps, StepConnector, stepConnectorClasses, Tooltip } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { IRootStore } from '../../../RootStore';
import { estimationSetStatusAction } from '../../../modules';
import { ConfirmPublishModal } from '../../modals';

const ColorlibConnector = MuiStyled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 19,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(90deg, rgba(68,177,162,1) 0%, rgba(0,110,144,1) 50%, rgba(0,35,112,1) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(90deg, rgba(68,177,162,1) 0%, rgba(0,110,144,1) 50%, rgba(0,35,112,1) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const Container = styled.div`

    .step-icon {

        background-color: #fff;
        z-index: 1;
        color: #fff;
        width: 50;
        height: 50;
        padding: 8px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;

        &.active {
            /* background-image: linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%); */
            background-image: linear-gradient(90deg, rgba(200,200,200,1) 0%, rgba(234,234,240,1) 50%, rgba(237,237,237,1) 100%);
            box-shadow: 0 4px 10px 0 rgba(0,0,0,.25);
        }

        &.completed {
            background-color: #eee;
            /* background-image: linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%); */
            background-image: linear-gradient(90deg, rgba(200,200,200,1) 0%, rgba(234,234,240,1) 50%, rgba(237,237,237,1) 100%);
        }
    }

`;

const STATUSES_LIST_1 = ['DRAFT', 'REVIEW', 'PENDING', 'PUBLISHED'];
const STATUSES_LIST_2 = ['DRAFT', 'REVIEW', 'PENDING', 'CLOSED'];

export interface IStatusStepper {
}

export const StatusStepper: React.FC<IStatusStepper> = (props: IStatusStepper) => {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = React.useState(0);
  const [isPublishOpen, setIsPublishOpen] = React.useState(false);

  const { status, id } = useSelector((state: IRootStore) => state.estimation.estimate);

  const statusList = status === 'CLOSED' ? STATUSES_LIST_2 : STATUSES_LIST_1;

  React.useEffect(() => {
    setActiveStep(statusList.indexOf(status));
  }, [status, statusList]);

  const getIcon = (props: StepIconProps) => {
    const { active, completed } = props;

    const classActive = (active) ? 'active' : '';
    const classCompleted = (completed) ? 'completed' : '';

    const tooltipText = status === 'CLOSED'
      ? 'Estimate is no longer valid or has no further activity'
      : 'Estimate has been signed and is locked';

    const icons: { [index: string]: React.ReactElement } = {
      1: <Tooltip title="Authors are drafting estimate"><Icon color="primary">edit</Icon></Tooltip>,
      2: <Tooltip title="Estimate is being reviewed internally"><Icon color={(active || completed) ? 'primary' : 'disabled'}>visibility</Icon></Tooltip>,
      3: <Tooltip title="Estimate is being reviewed by customer"><Icon color={(active || completed) ? 'primary' : 'disabled'}>hourglass_bottom</Icon></Tooltip>,
      4: <Tooltip title={tooltipText}><Icon color={(active || completed) ? 'secondary' : 'disabled'}>{status === 'CLOSED' ? 'do_not_disturb' : 'lock'}</Icon></Tooltip>,
    };

    return (
      <div className={`step-icon ${classActive} ${classCompleted}`}>
        {icons[String(props.icon)]}
      </div>
    );
  };

  const stepEls = statusList.map((x, index) => (
    <Step key={index}>
      <StepLabel StepIconComponent={getIcon}>
        <span>{x}</span>
      </StepLabel>
    </Step>
  ));

  const onCancel = () => {
    setIsPublishOpen(false);
  };

  const onConfirm = () => {
    dispatch(estimationSetStatusAction({ id, status: 'PUBLISHED' }));
    setIsPublishOpen(false);
  };

  return (
    <Container>
      <Stepper alternativeLabel={true} activeStep={activeStep} connector={<ColorlibConnector />}>
        {stepEls}
      </Stepper>
      <ConfirmPublishModal
        isOpen={isPublishOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </Container>
  );
};
