import {IUser} from './Models';
import {RequestBuilder} from '../';

const HOST = `${process.env.REACT_APP_BACKEND_HOST}/users`;

export async function getUser(id: string) {

    const url = `${HOST}/${id}`;

    return await new RequestBuilder(url)
        .setMethod('GET')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
};

export async function getAllUsers() {

    const url = `${HOST}`;

    return await new RequestBuilder(url)
        .setMethod('GET')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
};

export async function updateUser(user: IUser) {

    const url = `${HOST}/${user.id}`;

    return await new RequestBuilder(url)
        .setMethod('PUT')
        .setBody(user)
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
};

export async function createUser(user: IUser) {

    const url = `${HOST}/`;

    return await new RequestBuilder(url)
        .setMethod('POST')
        .setBody(user)
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
};

export async function changePassword(user: IUser, password: string, isAdmin: boolean) {

    if (isAdmin) {
        const url = `${HOST}/${user.id}/changePassword`;
    
        return await new RequestBuilder(url)
            .setMethod('PUT')
            .setBody({password})
            .addAuthorizationHeader()
            .addHeader('Content-Type', 'application/json')
            .build();   
    } else {
        const url = `${HOST}/changePassword`;
    
        return await new RequestBuilder(url)
            .setMethod('PATCH')
            .setBody({password})
            .addAuthorizationHeader()
            .addHeader('Content-Type', 'application/json')
            .build(); 
    }
}
