import * as React from 'react';
import styled from 'styled-components';
import {ButtonGroup, Button, Icon, Popper, Grow, MenuList, MenuItem, ClickAwayListener, Paper} from '@mui/material';
import {ArrowDropDown} from '@mui/icons-material';
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';


const Container = styled.div`
`;

export interface IExportButton {
    onClick?: () => void;
    elementRef: React.RefObject<HTMLElement>;
    filename: string;
}

export const ExportButton: React.FC<IExportButton> = (props: IExportButton) => {
    const [exportOpen, setExportOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const options = ['JPG', 'PNG']; // PDF

    const handleClick = () => {
        onExportClick(options[selectedIndex]);

        const {onClick} = props;

        if (onClick) {
            onClick();
        }
    };
    
    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setSelectedIndex(index);
        setExportOpen(false);
    };
    
    const handleToggle = () => {
        setExportOpen((prevOpen) => !prevOpen);
    };
    
    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
    
        setExportOpen(false);
    };

    const onExportClick = (type: string) => {
        const {filename, elementRef} = props;

        const params = {
            fileName: `${filename}.${type.toLowerCase()}`
        };

        switch(type) {
            case 'JPG':
                exportComponentAsJPEG(elementRef, params);
                break;
            case 'PNG':
                exportComponentAsPNG(elementRef, params);
                break;
            case 'PDF':
                exportComponentAsPDF(elementRef, params);
                break;
            default:
                console.warn(`Unknown export type ${type}!!!`);
        }
    };


    return (
        <Container>
            <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                <Button onClick={handleClick}><Icon>image</Icon>&nbsp;&nbsp;Export {options[selectedIndex]}</Button>
                <Button
                    color="primary"
                    size="small"
                    aria-controls={exportOpen ? 'split-button-menu' : undefined}
                    aria-expanded={exportOpen ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper open={exportOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 999}}>
                {({ TransitionProps, placement }) => (
                    <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                    >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                            {options.map((option, index) => (
                            <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={(event) => handleMenuItemClick(event, index)}
                            >
                                <span>Export {option}</span>
                            </MenuItem>
                            ))}
                        </MenuList>
                        </ClickAwayListener>
                    </Paper>
                    </Grow>
                )}
            </Popper>
        </Container>
    );
};
