import * as React from 'react';
import styled from 'styled-components';
import {IconButton} from '@mui/material';
import {Menu as MenuIcon} from '@mui/icons-material';
import {useDispatch} from 'react-redux';
import {useNavigate, useLocation} from 'react-router';

import {estimationSetAction, generateBlankEstimate, authCurrentUserAction} from '../../modules';
import {AppMenu} from '../ui';
import AxianLogo from '../assets/AxianLogo.svg';
import { BreadCrumbs } from './BreadCrumbs';

const Container = styled.div`
    padding: 10px 20px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;

    .title-container {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .icon {
        display: inline-block;
        margin-right: 10px;
    }

    .menu-button {
        margin-right: 5px;
    }
`;

export interface IHeaderProps {

}

export const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    React.useEffect(() => {
        dispatch(authCurrentUserAction());
    }, [dispatch]);

    const onTitleClick = () => {
        dispatch(estimationSetAction({estimate: generateBlankEstimate()}));
        navigate('/estimates');
    };

    const canRenderProtectedContent = location.pathname !== '/login' && location.pathname !== '/';

    return (
        <Container>
            <div className="title-container">
                
                <div onClick={onTitleClick}>
                    <img src={AxianLogo} alt="Axian Swag" />
                </div>
                <div>
                    {
                        canRenderProtectedContent &&
                        <BreadCrumbs />
                    }
                </div>
            </div>
            <AppMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
            <>
                {
                    canRenderProtectedContent &&
                    <div>
                        <IconButton aria-label="Menu" className="menu-button" onClick={() => setIsMenuOpen(true)}>
                            <MenuIcon />
                        </IconButton>
                    </div>
                }
            </>
        </Container>
    );
};
