import { DateTime } from 'luxon';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.span`
    font-size: .65rem;
`;

export interface IUpdatedTimestamp {
    date: Date;
}

export const UpdatedTimestamp: React.FC<IUpdatedTimestamp> = (props: IUpdatedTimestamp) => {
    const {date} = props;

    const [value, setValue] = React.useState<string|null>(null);

    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };

    React.useEffect(() => {
        const interval = setInterval(() => {
            const diff = ((new Date().getTime() - new Date(date).getTime()) / 60) / 1000; // minutes

            let _value;

            if (diff < 1) {
                _value = 'just now'
            } else if (diff < 60 ) {
                _value = DateTime.fromJSDate(new Date(date)).toRelative({unit: 'minutes'});
            } else if (diff < 1400) {
                _value = DateTime.fromJSDate(new Date(date)).toRelative({unit: 'hours'});
            } else if (diff < 129600) { // 90 days 7776
                _value = DateTime.fromJSDate(new Date(date)).toRelative({unit: 'days'});
            } else {
                _value = new Intl.DateTimeFormat('en-US', options).format(new Date(date));
            }

            setValue(_value);

        
        }, 2000);
        return () => {
          clearInterval(interval);
        };
    }, [date]);

    return (
        <Container>
            updated <span>{value}</span>
        </Container>
    );
};
