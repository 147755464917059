import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from '../estimation';
import { SummaryData } from './Models';

//
// CONSTANTS
//
export const REPORTING_LIST = '[REPORTING] List';
export const REPORTING_LIST_SUCCESS = '[REPORTING] List Success';
export const REPORTING_ERROR = '[REPORTING] Error';

//
// ACTIONS
//
export const reportingListAction = createAction<{statuses: StatusType[], daysAgo: number}>(REPORTING_LIST);
export type ReportingListActinon = ReturnType<typeof reportingListAction>;

export const reportingListSuccessAction = createAction<{summary: SummaryData}>(REPORTING_LIST_SUCCESS);
export type ReportingListSuccessAction = ReturnType<typeof reportingListSuccessAction>;

export const reportingErrorAction = createAction<{error: string}>(REPORTING_ERROR);
export type ReportingErrorAction = ReturnType<typeof reportingErrorAction>;

export type ReportingActions = 
    ReportingListActinon
    | ReportingListSuccessAction
    | ReportingErrorAction;

//
// STATE
//
export interface IReportingStore {
    isBusy: boolean;
    error: string;
    filters: {
        statuses: StatusType[];
        daysAgo: number;
    };
    summary: SummaryData;
}

const initialState: IReportingStore = {
    isBusy: false,
    error: '',
    filters: {
        statuses: [],
        daysAgo: 90
    },
    summary: {}
};

//
// REDUCER
//
export const reportingReducer = createReducer(initialState, (builder) => builder
    .addCase(reportingListAction, (state, action) => {
        return {...state, isBusy: true, filters: {...action.payload}};
    })
    .addCase(reportingListSuccessAction, (state, action) => ({
        ...state, isBusy: false, summary: {...action.payload.summary}
    }))
    .addCase(reportingErrorAction, (state, action) => ({ ...state, isBusy: false, error: action.payload.error }))
);