import * as React from 'react';
import styled from 'styled-components';
import {Table, TableRow, TableHead, TableBody, TableCell, Tooltip} from '@mui/material';

import {NumberFormat} from '../../ui';

const Container = styled.div`
    min-width: 150px;
    .total {
        font-weight: bold;
    }
`;

export interface IRoleHourAmountsTable {
    summary: {role: string, hours: number, amount: number, label: string}[];
    totalHours: number;
    totalAmount: number;
    showAmounts: boolean;
}

export const RoleHourAmountsTable: React.FC<IRoleHourAmountsTable> = (props: IRoleHourAmountsTable) => {

    const { summary = [], totalAmount = 0, totalHours = 0, showAmounts} = props;

    const amountsEl = summary
        .sort((a, b) => a.role.localeCompare(b.role))
        .map((x, index) => (
            <TableRow key={index}>
                <TableCell data-testid={`${x.role}-role`}>
                    <Tooltip title={showAmounts ? x.label : x.role} placement='top'>
                        <span>
                            {showAmounts ? x.role : x.label}
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell data-testid={`${x.role}-hours`} align="right">
                    <NumberFormat value={x.hours} />
                </TableCell>
                {
                    showAmounts &&
                    <TableCell data-testid={`${x.role}-amount`} align="right">
                        <NumberFormat value={x.amount} isCurrency={true} />
                    </TableCell>
                }
            </TableRow>
        ));

    const totalsEl = (
        <TableRow>
            <TableCell><span className="total">Total</span></TableCell>
            <TableCell className="hours" align="right">
                <span className="total" data-testid={`total-hours`}>
                    <NumberFormat value={totalHours} />
                </span>
            </TableCell>
            {
                showAmounts &&
                <TableCell className="amount" align="right">
                    <span className="total" data-testid={`total-amount`}>
                        <NumberFormat value={totalAmount} isCurrency={true} />  
                    </span>
                </TableCell>
            }
        </TableRow>
    );
    
    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Role</TableCell>
                        <TableCell align='right'>Hours</TableCell>
                        {
                            showAmounts &&
                            <TableCell align="right">Amount</TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {amountsEl}
                    {totalsEl}
                </TableBody>
            </Table>
        </Container>
    );
};
