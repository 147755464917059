import {createAction, createReducer} from '@reduxjs/toolkit';
import {IEstimate, IEstimateWithVersion, IPagination} from './Models';

//
// CONSTANTS
//

export const LIST_SEARCH = '[List] Search';
export const LIST_SEARCH_SUCCESS = '[List] Search Success';
export const LIST_FILTERS = '[List] Filters';
export const LIST_FILTERS_SUCCESS = '[List] Filters Success';
export const LIST_COPY = '[List] Copy';
export const LIST_COPY_SUCCESS = '[List] Copy Success';
export const LIST_REVISION = '[List] Revision';
export const LIST_REVISION_SUCCESS = '[List] Revision Success';
export const LIST_SEARCH_ERROR = '[List] Search Error';

//
// ACTIONS
//

export const listSearchAction = createAction<{filters: {name: string, value: string}[], page: number, size: number}>(LIST_SEARCH);
export type ListSearchAction = ReturnType<typeof listSearchAction>;

export const listSearchSuccesssAction = createAction<{estimates: IEstimateWithVersion[], pagination: IPagination}>(LIST_SEARCH_SUCCESS);
export type ListSearchSuccessAction = ReturnType<typeof listSearchSuccesssAction>;

export const listFiltersAction = createAction(LIST_FILTERS);
export type ListFiltersAction = ReturnType<typeof listFiltersAction>;

export const listFiltersSuccessAction = createAction<{filterOptions: any}>(LIST_FILTERS_SUCCESS);
export type ListFiltersSuccessAction = ReturnType<typeof listFiltersSuccessAction>;

export const listCopyEstimateAction = createAction<{id: string}>(LIST_COPY)
export type ListCopyEstimateAction = ReturnType<typeof listCopyEstimateAction>;

export const listCopyEstimateSuccessAction = createAction<{estimate: IEstimate}>(LIST_COPY_SUCCESS);
export type ListCopyEstimateSuccessAction = ReturnType<typeof listCopyEstimateSuccessAction>;

export const listRevisionAction = createAction<{id: string}>(LIST_REVISION);
export type ListRevisionAction = ReturnType<typeof listRevisionAction>;

export const listRevisionSuccessAction = createAction<{estimate: IEstimate}>(LIST_REVISION_SUCCESS);
export type ListRevisionSuccessAction = ReturnType<typeof listRevisionSuccessAction>;

export const listSearchErrorAction = createAction<{error: string}>(LIST_SEARCH_ERROR);
export type ListSearchErrorAction = ReturnType<typeof listSearchErrorAction>;

export type ListSearchActions = 
    ListSearchAction
    | ListSearchSuccessAction
    | ListFiltersAction
    | ListFiltersSuccessAction
    | ListCopyEstimateAction
    | ListCopyEstimateSuccessAction
    | ListRevisionAction
    | ListRevisionSuccessAction
    | ListSearchErrorAction;

//
// STATE
//

export interface IListStoreState {
    isBusy: boolean;
    estimates: IEstimateWithVersion[];
    pagination: IPagination;
    page: number;
    size: number;
    filters: {name: string, value: string}[];
    filterOptions: {authors: any[], clients: string[]};
    copyActionSuccess: undefined | IEstimate;
    revisionActionSuccess: undefined | IEstimate;
    error: string;
}

const initialState: IListStoreState = {
    isBusy: false,
    estimates: [],
    pagination: {
        total: 0,
        size: 10,
        totalPages: 0,
        page: 1,
        slNo: 1,
        hasPrevPage: false,
        hasNextPage: false,
        prev: null,
        next: null
    },
    page: 1,
    size: 10,
    filters: [],
    filterOptions: {
        authors: [],
        clients: []
    },
    copyActionSuccess: undefined,
    revisionActionSuccess: undefined,
    error: ''
};

//
// REDUCER
//
export const listReducer = createReducer(initialState, (builder) => builder
    .addCase(listSearchAction, (state, action) => {
        const {filters, page, size} = action.payload;
        return {...state, isBusy: true, copyActionSuccess: undefined, revisionActionSuccess: undefined, filters, page, size};
    })
    .addCase(listSearchSuccesssAction, (state, action) => {
        const {estimates, pagination} = action.payload;
        return {...state, isBusy: false, error: '', estimates, pagination};
    })
    .addCase(listFiltersAction, (state) => ({...state, isBusy: true}))
    .addCase(listFiltersSuccessAction, (state, action) => ({...state, isBusy: false, filterOptions: action.payload.filterOptions}))
    .addCase(listCopyEstimateAction, (state) => ({...state, isBusy: true, copyActionSuccess: undefined}))
    .addCase(listCopyEstimateSuccessAction, (state, action) => ({...state, isBusy: false, copyActionSuccess: action.payload.estimate}))
    .addCase(listRevisionAction, (state) => ({...state, isBusy: true, revisionActionSucess: undefined}))
    .addCase(listRevisionSuccessAction, (state, action) => ({...state, isBusy: false, revisionActionSucess: action.payload.estimate}))
    .addCase(listSearchErrorAction, (state, action) => ({...state, isBusy: false, error: action.payload.error}))
);
