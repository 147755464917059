import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import {Switch, FormControlLabel, ButtonGroup, Button, Icon} from '@mui/material';

import { IRootStore } from '../../../RootStore';
import { summaryDataPositionAction, summaryDisableRoleAction, summaryEnableRoleAction, summaryToggleColumnAction, SummaryType } from '../../../modules';
import {ExportButton, CopyImageButton} from '../../ui';


const Container = styled.div`
    display: flex;
    justify-content: space-between;

    .copy-container {
        margin-bottom: 20px;
    }
`;

export interface IRateCardControls {
    onCopyImageClick: () => void;
    rateCardRef: React.RefObject<HTMLElement>;
}

export const RateCardControls: React.FC<IRateCardControls> = (props: IRateCardControls) => {
    const dispatch = useDispatch();

    const {estimate} = useSelector((state: IRootStore) => state.estimation);
    const {version} = useSelector((state: IRootStore) => state.version);
    const {settings} = useSelector((state: IRootStore) => state.summary);

    const {columns} = settings;


    const onColumnChange = (column: SummaryType) => {
        dispatch(summaryToggleColumnAction({column}));
    };

    const switchLowEl = (<Switch defaultChecked color="primary" checked={!columns.low} onChange={() => onColumnChange('low')} />)
    const switchMidEl = (<Switch defaultChecked color="primary" checked={!columns.mid} onChange={() => onColumnChange('mid')} />)
    const switchHighEl = (<Switch defaultChecked color="primary" checked={!columns.high} onChange={() => onColumnChange('high')} />)


    const onRoleChange = (role: string, checked: boolean) => {
        if (checked) {
            dispatch(summaryEnableRoleAction({role}));
        } else {
            dispatch(summaryDisableRoleAction({role}));
        }
    };

    const roleEls = version.settings.roles
        .filter(x => x.enabled)
        .map((x, index) => (
            <div key={index}>
                <FormControlLabel
                    control={<Switch defaultChecked color="secondary" checked={settings.enabledRoles.includes(x.role)} onChange={(e, checked) => onRoleChange(x.role, checked)} />} 
                    label={`Show ${x.role}`}
                />
            </div>
        ));

    return (
        <Container>
            <div className="columns">
                <h3>Columns</h3>
                <div>
                    <FormControlLabel control={switchLowEl} label={`Hide Low Estimate`} />
                    
                </div>
                    {
                        settings.isMidAvailable &&
                        <div><FormControlLabel control={switchMidEl} label={`Hide Mid Estimate`} /></div>
                    }
                <div>
                    <FormControlLabel control={switchHighEl} label={`Hide High Estimate`} />
                </div>
            </div>
            <div className="roles">
                <h3>Roles</h3>
                {roleEls}
            </div>
            <div>
                <h3>Alignment</h3>
                <ButtonGroup color="info">
                    <Button variant={settings.dataPosition === 'left' ? 'contained' : 'outlined'} onClick={() => dispatch(summaryDataPositionAction({dataPosition: 'left'}))}><Icon>format_align_left</Icon></Button>
                    <Button variant={settings.dataPosition === 'center' ? 'contained' : 'outlined'} onClick={() => dispatch(summaryDataPositionAction({dataPosition: 'center'}))}><Icon>format_align_center</Icon></Button>
                    <Button variant={settings.dataPosition === 'right' ? 'contained' : 'outlined'} onClick={() => dispatch(summaryDataPositionAction({dataPosition: 'right'}))}><Icon>format_align_right</Icon></Button>
                </ButtonGroup>

                <h3>Export</h3>
                <div className="copy-container">
                    <CopyImageButton onClick={props.onCopyImageClick} elementRef={props.rateCardRef} />
                </div>
                <div className="export-container">
                    <ExportButton elementRef={props.rateCardRef} filename={`${estimate.id}_rate_card`} />
                </div>
            </div>

        </Container>
    );
};
