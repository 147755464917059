import * as React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import {App} from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import '@mui/styles';

import {appTheme} from './Theme';
import {store, initSagas} from './RootStore';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

initSagas();

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
