import {createAction, createReducer} from '@reduxjs/toolkit';
import { EstimateModeType, ISection, ISettings, ITask, IVersion } from './Models';
import { IUser } from '../user';
import { calculateRateCard, calculateTaskSummary, calculateVersionSummary, recalculateEstimateOnModeChange } from './Helper';

//
// CONSTANTS
//

export const VERSION_LIST = '[Version] List';
export const VERSION_LIST_SUCCESS = '[Version] List Success';
export const VERSION_SET_VERSION = '[Version] Set Version';
export const VERSION_NEW = '[Version] New';
export const VERSION_NEW_SUCCESS = '[Version] New Success';
export const VERSION_UPDATE = '[Version] Update';
export const VERSION_SECTION_ADD = '[Version] Section Add';
export const VERSION_SECTION_UPDATE = '[Version] Section Update';
export const VERSION_SECTION_DELETE = '[Version] Section Delete';
export const VERSION_TASK_ADD = '[Version] Task Add';
export const VERSION_TASK_ADD_SUCCESS = '[Version] Task Add Success';
export const VERSION_TASK_UPDATE = '[Version] Task Update';
export const VERSION_TASK_UPDATE_SUCCESS = '[Version] Task Update Success';
export const VERSION_TASK_DELETE = '[Version] Task Delete';
export const VERSION_TASK_DELETE_SUCCESS = '[Version] Task Delete Success';
export const VERSION_SETTINGS_RESET = '[Version] Settings Reset';
export const VERSION_SETTINGS_CHANGE = '[Version] Settings Change';
export const VERSION_SETTINGS_CHANGE_MODE = '[Version] Settings Change Mode';
export const VERSION_SETTINGS_CHANGE_ROLE = '[Version] Settings Change Role';
export const VERSION_SET_PRIMARY_VERSION = '[Version] Set Primary Version';
export const VERSION_SET_PRIMARY_VERSION_SUCCESS = '[Version] Set Primary Version Success';

export const VERSION_SUCCESS = '[Version] Success';
export const VERSION_ERROR = '[Version] Error';

//
// ACTIONS
//

export const versionListAction = createAction<{estimateId: string}>(VERSION_LIST);
export type VersionListAction = ReturnType<typeof versionListAction>;

export const versionListSuccessAction = createAction<{versions: IVersion[]}>(VERSION_LIST_SUCCESS);
export type VersionListSuccessAction = ReturnType<typeof versionListSuccessAction>;

export const versionSetVersionAction = createAction<{versionId: string}>(VERSION_SET_VERSION);
export type VersionSetVersionAction = ReturnType<typeof versionSetVersionAction>;

export const versionNewAction = createAction<{estimateId: string}>(VERSION_NEW);
export type VersionNewAction = ReturnType<typeof versionNewAction>;

export const versionNewSuccessAction = createAction<{version: IVersion}>(VERSION_NEW_SUCCESS);
export type VersionNewSuccessAction = ReturnType<typeof versionNewSuccessAction>;

export const versionUpdateAction = createAction<{version: IVersion}>(VERSION_UPDATE);
export type VersionUpdateAction = ReturnType<typeof versionUpdateAction>;

export const versionSectionAddAction = createAction<{section: ISection}>(VERSION_SECTION_ADD);
export type VersionSectionAddAction = ReturnType<typeof versionSectionAddAction>;

export const versionSectionUpdateAction = createAction<{section: ISection}>(VERSION_SECTION_UPDATE);
export type VersionSectionUpdateAction = ReturnType<typeof versionSectionUpdateAction>;

export const versionSectionDeleteAction = createAction<{id: number | string}>(VERSION_SECTION_DELETE);
export type VersionSectionDeleteAction = ReturnType<typeof versionSectionDeleteAction>;

export const versionTaskAddAction = createAction<{task: ITask, sectionId: string | number}>(VERSION_TASK_ADD);
export type VersionTaskAddAction = ReturnType<typeof versionTaskAddAction>;

export const versionTaskAddSuccessAction = createAction<{task: ITask, sectionId: string | number}>(VERSION_TASK_ADD_SUCCESS);
export type VersionTaskAddSuccessAction = ReturnType<typeof versionTaskAddSuccessAction>;

export const versionTaskUpdateAction = createAction<{task: ITask, sectionId: string | number}>(VERSION_TASK_UPDATE);
export type VersionTaskUpdateAction = ReturnType<typeof versionTaskUpdateAction>;

export const versionTaskUpdateSuccessAction = createAction<{task: ITask, sectionId: string | number}>(VERSION_TASK_UPDATE_SUCCESS);
export type VersionTaskUpdateSuccessAction = ReturnType<typeof versionTaskUpdateSuccessAction>;

export const versionTaskDeleteAction = createAction<{sectionId: string | number, id: number | string}>(VERSION_TASK_DELETE);
export type VersionTaskDeleteAction = ReturnType<typeof versionTaskDeleteAction>;

export const versionTaskDeleteSuccessAction = createAction<{id: number | string}>(VERSION_TASK_DELETE_SUCCESS);
export type VersionTaskDeleteSuccessAction = ReturnType<typeof versionTaskDeleteSuccessAction>;

export const versionSettingsChangeAction = createAction<{settings: ISettings}>(VERSION_SETTINGS_CHANGE);
export type VersionSettingsChangeAction = ReturnType<typeof versionSettingsChangeAction>;

export const versionSettingsChangeModeAction = createAction<{mode: EstimateModeType}>(VERSION_SETTINGS_CHANGE_MODE);
export type VersionSettingsChangeModeAction = ReturnType<typeof versionSettingsChangeModeAction>;

export const versionSettingsChangeRoleAction = createAction<{versionId: string, primaryRole: string}>(VERSION_SETTINGS_CHANGE_ROLE);
export type VersionSettingsChangeRoleAction = ReturnType<typeof versionSettingsChangeRoleAction>;

export const versionSettingsResetAction = createAction<{estimateId: string, versionId: string}>(VERSION_SETTINGS_RESET);
export type VersionSettingsResetAction = ReturnType<typeof versionSettingsResetAction>;

export const versionSuccessAction = createAction<{version: IVersion}>(VERSION_SUCCESS);
export type VersionSuccessAction = ReturnType<typeof versionSuccessAction>;

export const versionErrorAction = createAction<{error: string}>(VERSION_ERROR);
export type VersionErrorAction = ReturnType<typeof versionErrorAction>;

export type VersionActionsType = 
    VersionListAction |
    VersionListSuccessAction |
    VersionSetVersionAction |
    VersionNewAction |
    VersionNewSuccessAction | 
    VersionUpdateAction |
    VersionSectionAddAction |
    VersionSectionUpdateAction |
    VersionSectionDeleteAction |
    VersionTaskAddAction |
    VersionTaskAddSuccessAction |
    VersionTaskUpdateAction |
    VersionTaskUpdateSuccessAction |
    VersionTaskDeleteAction |
    VersionTaskDeleteSuccessAction |
    VersionSettingsChangeAction |
    VersionSettingsChangeModeAction |
    VersionSettingsChangeRoleAction |
    VersionSettingsResetAction |
    VersionSuccessAction |
    VersionErrorAction;

//
// STATE
//

export interface IVersionState {
    isBusy: boolean;
    error: string;
    versions: IVersion[];
    selectedVersionId: string;
    version: IVersion;
}

const generateBlankVersion = (): IVersion => {
    return {
        id: '',
        estimateId: '',
        version: -1,
        description: '',
        settings: {
            mode: 'COMPUTED',
            roles: [],
            ranges: {
                high: 1, low: 0.8
            }
        },
        sections: [],
        summary: {
            amount: 0,
            hours: 0
        },
        createUser: {} as IUser,
        createDate: new Date(),
        updateUser: {} as IUser,
        updateDate: new Date(),
    };
};

const initialState: IVersionState = {
    isBusy: false,
    error: '',
    versions: [],
    selectedVersionId: '',
    version: {...generateBlankVersion()},
};

//
// REDUCER
//

export const versionReducer = createReducer(initialState, (builder) => builder
    .addCase(versionListAction, (state, action) => ({...state, isBusy: true }))
    .addCase(versionListSuccessAction, (state, action) => ({...state, isBusy: false, versions: action.payload.versions}))
    .addCase(versionSetVersionAction, (state, action) => {
        const selectedVersionId = action.payload.versionId;
        const [version] = state.versions.filter(v => v.id === selectedVersionId);
        if (version) {
            return {...state, selectedVersionId, version};
        } else {
            return {...state, selectedVersionId, version: {...generateBlankVersion()}};
        }
    })
    .addCase(versionSectionAddAction, (state, action) => {
        const version = {...state.version};
        version.sections = [...version.sections, action.payload.section];
        return {...state, version};
    })
    .addCase(versionSectionUpdateAction, (state, action) => {
        const {version: _version} = state;
        
        const sections = [..._version.sections];
        const index = sections.findIndex((x) => x.id === action.payload.section.id);

        sections[index] = {...action.payload.section};

        const version = {..._version, sections};

        const rateCard = calculateRateCard(version);

        version.summary = {hours: rateCard.Total.hours, amount: rateCard.Total.amount};

        return {...state, version};
    })
    .addCase(versionSectionDeleteAction, (state, action) => {
        const {version: _version} = state;
        const sections = _version.sections.filter(x => x.id !== action.payload.id);
        const version = {..._version, sections};

        version.summary = calculateVersionSummary(_version);

        return {...state, version};
    })
    .addCase(versionTaskAddAction, (state, action) => {
        // const {task, sectionId} = action.payload;

        // const _version = {...state.version};
        // const sectionIndex = _version.sections.findIndex(s => s.id === sectionId);
        // _version.sections[sectionIndex].tasks.push(_task);

        return {...state, isBusy: true }; //version: _version};
    })
    .addCase(versionTaskAddSuccessAction, (state, action) => {
        const {task: _task, sectionId} = action.payload;

        const _version = {...state.version};
        // _version.sections = _version.sections.map((s) => {
        //     const tasks = s.tasks.map((t) => {
        //         if (t.id === _task.id) {
        //             return _task;
        //         }

        //         return t;
        //     });

        //     return {...s, tasks}
        // });

        // const newTasks = state.newTasks.filter(x => x.temporaryId !== temporaryId)
        _version.sections = _version.sections.map((s) => {
            if (s.id === sectionId) {
                return {
                    ...s,
                    tasks: [...s.tasks, _task]
                };
            }

            return s;
        });

        _version.summary = calculateVersionSummary(_version);

        return {...state, isBusy: false, version: _version};
    })
    .addCase(versionTaskUpdateAction, (state, action) => {
        return {...state, isBusy: true };
    })
    .addCase(versionTaskUpdateSuccessAction, (state, action) => {
        const {task: _task, sectionId} = action.payload;
        const _version = {...state.version};
        _version.sections = _version.sections.map((s) => {
            if (s.id === sectionId) {
                const _tasks = s.tasks.map((t) => {
                    if (t.id === _task.id) {
                        return {..._task};
                    }
                    return t;
                });

                return {
                    ...s,
                    tasks: _tasks
                };
            }

            return s;
        });

        _version.summary = calculateVersionSummary(_version);

        return {...state, isBusy: false, version: _version};
    })
    .addCase(versionTaskDeleteAction, (state, action) => {
        return {...state, isBusy: true };
    })
    .addCase(versionTaskDeleteSuccessAction, (state, action) => {
        const { id } = action.payload;
        const _version = {...state.version};

        _version.sections = _version.sections.map((s) => {
            const tasks = s.tasks.filter((t) => t.id !== id);

            return {
                ...s,
                tasks
            };
        });

        return {...state, isBusy: false, version: _version};
    })
    .addCase(versionSettingsChangeAction, (state, action) => {
        const version = {
            ...state.version, 
            settings: action.payload.settings
        };

        const sections = [...version.sections]
            .map(s => { // recalculate the sections
                const tasks = [...s.tasks]
                    .map(t => { // recalculate and rebuild the tasks
                        const task = {...t};
                        task.summary = {...calculateTaskSummary(task, version.settings.roles, version.settings.ranges)}
                        return task;
                    });

                // rebuild the section
                const section = {...s, tasks};
                return section;
            });

        // Reassign to the estimate
        version.sections = sections;

        return {...state, version};
    })
    .addCase(versionSettingsChangeModeAction, (state, action) => {
        const {mode} = action.payload;
        const {version} = state;

        const newVersion = recalculateEstimateOnModeChange(version, mode);

        return {...state, version: newVersion};
    })
    .addCase(versionSettingsChangeRoleAction, (state) => ({...state, isBusy: true}))
    .addCase(versionSettingsResetAction, (state) => ({...state}))
    .addCase(versionNewAction, (state, action) => ({...state, isBusy: true}))
    .addCase(versionNewSuccessAction, (state, action) => {

        const versions = [...state.versions, action.payload.version];

        return {...state, isBusy: false, versions};
    })
    .addCase(versionUpdateAction, (state, action) => ({...state, isBusy: true}))
    .addCase(versionSuccessAction, (state, action) => {
        const {version} = action.payload;
        const index = state.versions.findIndex(v => v.id === version.id);

        
        if (index > -1) {
            const _versions = [...state.versions];
            _versions[index] = version;

            return {...state, isBusy: false, version: action.payload.version, versions: _versions};
        }

        return {...state, isBusy: false, version: action.payload.version}
        // ({...state, isBusy: false, version: action.payload.version})
    })
    .addCase(versionErrorAction, (state, action) => ({...state, isBusy: false, error: action.payload.error}))
);
