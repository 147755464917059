import * as React from 'react';
import {Dialog, DialogContent, DialogActions, DialogTitle, Button} from '@mui/material';

import {EditUser} from '../ui';
import {IUser} from '../../modules';

export interface IEditUserModalProps {
    user: IUser
    open: boolean;
    onCancel: () => void;
    onSave: (user: IUser) => void;
}

export const EditUserModal: React.FC<IEditUserModalProps> = (props: IEditUserModalProps) => {

    const [editingUser, setEditingUser] = React.useState<IUser>({} as IUser);

    React.useEffect(() => {
        setEditingUser(props.user);
    }, [props.user]);

    const onUserChange = (user: IUser) => {
        const _user = {...props.user, ...user};
        setEditingUser(_user);
    };

    const onSaveClick = () => {
        props.onSave(editingUser);
    }

    return (
        <div>
            <Dialog open={props.open}>
                <DialogTitle>{props.user.name}</DialogTitle>
                <DialogContent>
                    <EditUser user={editingUser} onChange={onUserChange} />
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={props.onCancel}>Cancel</Button>
                    <Button color="primary" variant="contained" onClick={onSaveClick}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
