import * as React from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {List, ListItem, Typography, Icon, Tooltip} from '@mui/material';

import {IRootStore} from '../../../RootStore';
import {ItemSummary} from './ItemSummary';
import {calculateSectionSummary} from '../../../modules';

const Container = styled.div`
    width: 100%;

    .section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background-color: #eee;
        font-weight: bold;
        position: sticky;
        top: 60px;
        z-index: 2;
    }

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eee;
        padding: 10px 3px;
        
        .icon {
            display: inline-block;
            margin-left: 10px;
        }

        .description {
            margin-top: 10px;
            white-space: pre-wrap;
            font-style: italic;
            font-size: 0.9rem;
        }

    }

    .description-container {
        padding: 15px 5px;
        width: 40%;
    }

`;

export interface IWbsReadOnlyProps {
}

export const WbsReadOnly: React.FC<IWbsReadOnlyProps> = (props: IWbsReadOnlyProps) => {

    const {settings: {showAmounts}} = useSelector((state: IRootStore) => state.estimation);
    const {version: {sections, settings, description}} = useSelector((state: IRootStore) => state.version);

    const sectionEls = sections.map((section, idx) => (
        <div key={idx}>
            <div className="section">
                <div>
                    <Typography variant="button" >{section.name}</Typography>
                </div>
                <div>
                    <ItemSummary summary={calculateSectionSummary(section, settings.roles, settings.ranges)} showAmounts={showAmounts} strike={false} />
                </div>
            </div>
            <List dense={true}>
                {
                    section.tasks.map((t, index) => (
                        <ListItem className="item" key={index}>
                            <div>
                                {t.name}
                                {
                                    t.isOverride &&
                                    <Tooltip title="Task hours were overridden">
                                        <Icon className="icon" color="secondary">flag</Icon>
                                    </Tooltip>
                                }
                                {
                                    !t.isVisible &&
                                    <Tooltip title="Task was excluded">
                                        <Icon className="icon" color={t.isVisible ? 'disabled' : 'secondary'}>visibility_off</Icon>
                                    </Tooltip>
                                }
                                {
                                    t.description &&
                                    <div className="description">{t.description}</div>
                                }
                            </div>
                            <div className="summary">
                                <ItemSummary summary={t.summary} showAmounts={showAmounts} strike={!t.isVisible} />
                            </div>
                        </ListItem>
                    ))
                }
            </List>
        </div>
    ));

    return (
        <Container>
            <div className="description-container">
                <Typography variant="body2">
                    {description}
                </Typography>
            </div>

            {sectionEls}
        </Container>
    );
}
