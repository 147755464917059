import * as React from 'react';
import styled from 'styled-components';
import {IconButton, Menu, MenuItem} from '@mui/material';
import {MoreVert} from '@mui/icons-material';
import {useDispatch} from 'react-redux';

import {IEstimate, estimationDeleteAction, listCopyEstimateAction, listRevisionAction} from '../../../modules';

const Container = styled.div`
`;

export interface IEstimateActionsProps {
    estimate: IEstimate;
}

export const EstimateActions: React.FC<IEstimateActionsProps> = (props: IEstimateActionsProps) => {
    const dispatch = useDispatch();
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const stopEvent = (event: React.MouseEvent<any>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleClose = (event: React.MouseEvent<any>) => {
        stopEvent(event);
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        stopEvent(event);
        setAnchorEl(event.currentTarget);
    };

    const onCopyClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        stopEvent(event);
        const {id} = props.estimate;
        dispatch(listCopyEstimateAction({id}));
        handleClose(event);
    };

    const onRevisionClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        stopEvent(event);
        const {id} = props.estimate;
        dispatch(listRevisionAction({id}));
        handleClose(event);
    };

    const onDeleteClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        stopEvent(event);
        dispatch(estimationDeleteAction({id: props.estimate.id}));
        handleClose(event);
    };

    return (
        <Container>
            <IconButton onClick={handleClick} size="large">
                <MoreVert />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={onCopyClick}>Copy</MenuItem>

                {/* {
                    props.estimate.status === 'PUBLISHED' &&
                    !props.estimate.revisions!.childId &&
                    <MenuItem onClick={onRevisionClick}>New Revision</MenuItem>
                } */}
                {
                    props.estimate.status === 'DRAFT' &&
                    <MenuItem onClick={onDeleteClick}>Delete</MenuItem>
                }
            </Menu>
        </Container>
    );
}
