import * as React from 'react';
import styled from 'styled-components';
import {Drawer, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Divider, Box} from '@mui/material';
import { FormatListBulleted, SupervisorAccount, Logout, StackedBarChart, Person, Lock } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { authLogoutAction, userChangePasswordAction } from '../../modules';
import { IRootStore } from '../../RootStore';
import { ChangePasswordModal } from '../modals';

const Container = styled.div`

    LI {
        padding: 0;
        min-width: 250px;
    }

`;

export interface IAppMenu {
    isOpen: boolean;
    onClose: () => void;
}

export const AppMenu: React.FC<IAppMenu> = (props: IAppMenu) => {
    const {isOpen, onClose} = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isChangePwdModalOpen, setIsChangePwdModalOpen] = React.useState(false);

    const {user} = useSelector((state: IRootStore) => state.auth);

    const onLogoutClick = () => {
        dispatch(authLogoutAction());
        onClose();
        navigate('/login');
    };

    const onAdminClick = () => {
        onClose();
        navigate('/admin');
    };

    const onEstimatesClick = () => {
        onClose();
        navigate('/estimates');
    };

    const onReportingClick = () => {
        onClose();
        navigate('/reporting');
    };

    const onChangePasswordClick = () => {
        setIsChangePwdModalOpen(true);
    }

    const onChangePwdModalCancel = () => {
        setIsChangePwdModalOpen(false);
    };

    const onChangePwdModalSave = (password: string) => {
        dispatch(userChangePasswordAction({user, password, isAdmin: false}));
        setIsChangePwdModalOpen(false);
    };

    return (
        <Container>
            <ChangePasswordModal user={user} open={isChangePwdModalOpen} onCancel={onChangePwdModalCancel} onSave={onChangePwdModalSave} />
            <Drawer
                anchor='right'
                open={isOpen}
                onClose={onClose}
            >
                <Box sx={{ width: 250 }} role="presentation">
                <List>
                    <ListItem disablePadding={true}>
                        <ListItemButton>
                            <ListItemIcon>
                                <Person />
                            </ListItemIcon>
                            <ListItemText primary={user.name} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding={true}>
                        <ListItemButton onClick={onChangePasswordClick}>
                            <ListItemIcon>
                                <Lock />
                            </ListItemIcon>
                            <ListItemText primary={'Change Password'} />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding={true}>
                        <ListItemButton onClick={onEstimatesClick}>
                            <ListItemIcon>
                                <FormatListBulleted />
                            </ListItemIcon>
                            <ListItemText primary={'Estimates'} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding={true}>
                        <ListItemButton onClick={onReportingClick}>
                            <ListItemIcon>
                                <StackedBarChart />
                            </ListItemIcon>
                            <ListItemText primary={'Reporting'} />
                        </ListItemButton>
                    </ListItem>
                    {
                        user.isAdmin &&
                        <ListItem disablePadding={true}>
                            <ListItemButton onClick={onAdminClick}>
                                <ListItemIcon>
                                    <SupervisorAccount />
                                </ListItemIcon>
                                <ListItemText primary={'Admin'} />
                            </ListItemButton>
                        </ListItem>
                    }
                    <Divider />
                    <ListItem disablePadding={true}>
                        <ListItemButton onClick={onLogoutClick}>
                            <ListItemIcon>
                                <Logout />
                            </ListItemIcon>
                            <ListItemText primary={'Logout'} />
                        </ListItemButton>
                    </ListItem>
                </List>
                </Box>
            </Drawer>
        </Container>
    );
};
