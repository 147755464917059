import {createAction, createReducer} from '@reduxjs/toolkit';
import { IEstimate, IAssumption, IHistory, IComment, IRoleSetting, EstimateModeType, EstimateDecision } from './Models';
import {generateBlankEstimate} from './Helper';

//
// CONSTANTS
//
export const ESTIMATION_SET = '[Estimation] Set';
export const ESTIMATION_GET = '[Estimation] Get';
export const ESTIMATION_SAVE = '[Estimation] Save';
export const ESTIMATION_CREATE = '[Estimation] Create';
export const ESTIMATION_DELETE = '[Estimation] Delete';
export const ESTIMATION_ERROR = '[Estimation] Error';
export const ESTIMATION_INFO_UPDATE = '[Estimation] Info Update';
export const ESTIMATION_ADD_DECISION = '[Estimation] Add Decision';
export const ESTIMATION_ADD_RESOURCE = '[Estimation] Add Resource';
export const ESTIMATION_DELETE_RESOURCE = '[Estimation] Delete Resource';
export const ESTIMATION_SET_STATUS = '[Estimation] Set Status';
export const ESTIMATION_SET_STATUS_SUCCESS = '[Estimation] Set Status Success';
export const ESTIMATION_LIST_COMMENTS = '[Estimation] List Comments';
export const ESTIMATION_LIST_COMMENTS_SUCCESS = '[Estimation] List Comments Success';
export const ESTIMATION_ADD_COMMENT = '[Estimation] Add Comment';
export const ESTIMATION_ADD_COMMENT_SUCCESS = '[Estimation] Add Comment Success';
export const ESTIMATION_VIEW_SETTINGS = '[Estimation] Set View Settings';
export const ESTIMATION_SET_ASSUMPTIONS = '[Estimation] Set Assumptions';
export const ESTIMATION_GET_HISTORY = '[Estimation] Get History';
export const ESTIMATION_SET_HISTORY = '[Estimation] Set History';
export const ESTIMATION_SET_PRIMARY_VERSION = '[Estimation] Set Primary Version';
export const ESTIMATION_SET_PRIMARY_VERSION_SUCCESS = '[Estimation] Set Primary Version Success';
export const ESTIMATION_SUCCESS = '[Estimation] Success';

//
// ACTIONS
//

export const estimationSetAction = createAction<{estimate: IEstimate}>(ESTIMATION_SET)
export type EstimationSetAction = ReturnType<typeof estimationSetAction>;

export const estimationErrorAction = createAction<{error: string}>(ESTIMATION_ERROR);
export type EstimationErrorAction = ReturnType<typeof estimationErrorAction>;

export const estimationGetAction = createAction<{id: string}>(ESTIMATION_GET);
export type EstimationGetAction = ReturnType<typeof estimationGetAction>;

export const estimationSaveAction = createAction<{estimate: IEstimate}>(ESTIMATION_SAVE);
export type EstimationSaveAction = ReturnType<typeof estimationSaveAction>;

export const estimationCreateAction = createAction<{estimate: IEstimate, primaryRole: IRoleSetting, mode: EstimateModeType, additionalRoles?: IRoleSetting[]}>(ESTIMATION_CREATE);
export type EstimationCreateAction = ReturnType<typeof estimationCreateAction>;

export const estimationDeleteAction = createAction<{id: string}>(ESTIMATION_DELETE);
export type EstimationDeleteAction = ReturnType<typeof estimationDeleteAction>;

export const estimationInfoUpdateAction = createAction<{client: string, title: string, projectCode: string, description: string}>(ESTIMATION_INFO_UPDATE);
export type EstimationInfoUpdateAction = ReturnType<typeof estimationInfoUpdateAction>;

export const estimationAddDecisionAction = createAction<{id: string; action: EstimateDecision, comment?: string}>(ESTIMATION_ADD_DECISION);
export type EstimationAddDecisionAction = ReturnType<typeof estimationAddDecisionAction>;

export const estimationAddResorceAction = createAction<{id: string; name: string; href: string; description?: string}>(ESTIMATION_ADD_RESOURCE);
export type EstimationAddResourceAction = ReturnType<typeof estimationAddResorceAction>;

export const estimationDeleteResourceAction = createAction<{id: string, resourceId: string}>(ESTIMATION_DELETE_RESOURCE);
export type EstimationDeleteResourceAction = ReturnType<typeof estimationDeleteResourceAction>;

export const estimationSetStatusAction = createAction<{id: string, status: string}>(ESTIMATION_SET_STATUS);
export type EstimationSetStatusAction = ReturnType<typeof estimationSetStatusAction>;

export const estimationSetStatusSuccessAction = createAction<{ estimate: IEstimate }>(ESTIMATION_SET_STATUS_SUCCESS);
export type EstimationSetStatusSuccessAction = ReturnType<typeof estimationSetStatusSuccessAction>;

export const estimationListCommentsAction = createAction<{id: string}>(ESTIMATION_LIST_COMMENTS);
export type EstimationListCommentsAction = ReturnType<typeof estimationListCommentsAction>;

export const estimationListCommentsSuccessAction = createAction<{comments: IComment[]}>(ESTIMATION_LIST_COMMENTS_SUCCESS);
export type EstimationListCommentsSuccessAction = ReturnType<typeof estimationListCommentsSuccessAction>;

export const estimationAddCommentAction = createAction<{id: string, comment: string}>(ESTIMATION_ADD_COMMENT);
export type EstimationAddCommentAction = ReturnType<typeof estimationAddCommentAction>;

export const estimationAddCommentSuccessAction = createAction<{comment: IComment}>(ESTIMATION_ADD_COMMENT_SUCCESS);
export type EstimationAddCommentSuccessAction = ReturnType<typeof estimationAddCommentSuccessAction>;

export const estimationViewSettingsAction = createAction<{showAmounts: boolean}>(ESTIMATION_VIEW_SETTINGS);
export type EstimationViewSettingsAction = ReturnType<typeof estimationViewSettingsAction>;

export const estimationSetAssumptionsAction = createAction<{assumptions: IAssumption[]}>(ESTIMATION_SET_ASSUMPTIONS);
export type EstimationSetAssumptionsAction = ReturnType<typeof estimationSetAssumptionsAction>;

export const estimationGetHistoryAction = createAction<{estimateId: string}>(ESTIMATION_GET_HISTORY);
export type EstimationGetHistoryAction = ReturnType<typeof estimationGetHistoryAction>;

export const estimationSetHistoryAction = createAction<{history: IHistory[]}>(ESTIMATION_SET_HISTORY);
export type EstimationSetHistoryAction = ReturnType<typeof estimationSetHistoryAction>;

export const estimationSetPrimaryVersionAction = createAction<{estimateId: string, versionId: string}>(ESTIMATION_SET_PRIMARY_VERSION);
export type EstimationSetPrimaryVersionAction = ReturnType<typeof estimationSetPrimaryVersionAction>;

export const estimationSetPrimaryVersionSuccessAction = createAction<{estimate: IEstimate}>(ESTIMATION_SET_PRIMARY_VERSION_SUCCESS);
export type EstimationSetPrimaryVersionSuccessAction = ReturnType<typeof estimationSetPrimaryVersionSuccessAction>;

export const estimationSuccessAction = createAction(ESTIMATION_SUCCESS);
export type EstimationSuccessAction = ReturnType<typeof estimationSuccessAction>;

export type EstimationActionsType = 
    EstimationSetAction
    | EstimationGetAction
    | EstimationSaveAction
    | EstimationCreateAction
    | EstimationDeleteAction
    | EstimationErrorAction
    | EstimationInfoUpdateAction
    | EstimationAddDecisionAction
    | EstimationSetStatusAction
    | EstimationSetStatusSuccessAction
    | EstimationListCommentsAction
    | EstimationListCommentsSuccessAction
    | EstimationAddCommentAction
    | EstimationAddCommentSuccessAction
    | EstimationViewSettingsAction
    | EstimationSetAssumptionsAction
    | EstimationGetHistoryAction
    | EstimationSetHistoryAction
    | EstimationSetPrimaryVersionAction
    | EstimationSetPrimaryVersionSuccessAction
    | EstimationSuccessAction;


//
// STATE
//

export interface IEstimationState {
    isBusy: boolean;
    hasChanged: boolean;
    estimate: IEstimate;
    history: IHistory[];
    comments: IComment[]
    settings: {
        showAmounts: boolean;
    };
    error: string;
}

const initialState: IEstimationState = {
    isBusy: false,
    hasChanged: false,
    error: '',
    estimate: generateBlankEstimate(),
    history: [],
    comments: [],
    settings: {
        showAmounts: false
    }
};

//
// REDUCER
//

export const estimationReducer = createReducer(initialState, (builder) => builder
    .addCase(estimationSetAction, (state, action) => {
        const {estimate} = action.payload;
        return {...state, estimate, isBusy: false, hasChanged: false};
    })
    .addCase(estimationInfoUpdateAction, (state, action) => {
        const {estimate} = state;
        return {...state, estimate: {...estimate, ...action.payload}, hasChanged: true };
    })
    .addCase(estimationAddDecisionAction, (state, action) => ({...state, isBusy: true}))
    .addCase(estimationAddResorceAction, (state, action) => ({...state, isBusy: true}))
    .addCase(estimationDeleteResourceAction, (state, action) => ({...state, isBusy: true}))
    .addCase(estimationSaveAction, (state, action) => ({...state, isBusy: true}))
    .addCase(estimationSetStatusAction, (state, action) => ({...state, isBusy: true}))
    .addCase(estimationSetStatusSuccessAction, (state, action) => {
        const {status, updateDate, updateUser} = action.payload.estimate;
        const estimate = {...state.estimate, status, updateDate, updateUser};
        return {...state, estimate, isBusy: false};
    })
    .addCase(estimationCreateAction, (state, action) => ({...state, isBusy: true}))
    .addCase(estimationDeleteAction, (state, action) => ({...state, isBusy: true}))
    .addCase(estimationListCommentsAction, (state, action) => ({...state, isBusy: true}))
    .addCase(estimationListCommentsSuccessAction, (state, action) => ({...state, isBusy: false, comments: action.payload.comments}))
    .addCase(estimationAddCommentAction, (state, action) => ({...state, isBusy: true}))
    .addCase(estimationAddCommentSuccessAction, (state, action) => {
        const comments = [...state.comments, action.payload.comment];
        return {...state, isBusy: false, comments};
    })
    .addCase(estimationSetAssumptionsAction, (state, action) => {
        const {assumptions} = action.payload;
        const {estimate} = state;
        return {...state, estimate: {...estimate, assumptions}, isBusy: true, hasChanged: true};
    })
    .addCase(estimationViewSettingsAction, (state, action) => ({...state, settings: {...action.payload}}))
    .addCase(estimationGetHistoryAction, (state, action) => ({...state, isBusy: true}))
    .addCase(estimationSetHistoryAction, (state, action) => ({...state, isBusy: false, history: action.payload.history}))
    .addCase(estimationSetPrimaryVersionAction, (state, action) => ({...state, isBusy: true, estimate: {...state.estimate, version: action.payload.versionId}}))
    .addCase(estimationSetPrimaryVersionSuccessAction, (state, action) => ({...state, isBusy: false, estimate: action.payload.estimate}))
    .addCase(estimationErrorAction, (state, action) => ({...state, isBusy: false, error: action.payload.error}))
    .addCase(estimationSuccessAction, (state) => ({...state, isBusy: false}))
);
