import { ArrowDropDown } from '@mui/icons-material';
import { Button, ButtonGroup, ClickAwayListener, Grow, Icon, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { IRootStore } from '../../../RootStore';
import { summaryExportCsvAction } from '../../../modules';

const Container = styled.div`
`;

export interface IExportCsvButton {
}

export const ExportCsvButton: React.FC<IExportCsvButton> = (props: IExportCsvButton) => {
    const [exportOpen, setExportOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const dispatch = useDispatch();

    const { estimate: {id}} = useSelector((state: IRootStore) => state.estimation);
    const { version: {id: versionId}} = useSelector((state: IRootStore) => state.version);

    const options = ['High', 'Mid', 'Low']; // PDF

    const handleClick = () => {

        const summaryType = options[selectedIndex].toLowerCase() as 'high'|'mid'|'low';

        dispatch(summaryExportCsvAction({id, versionId, summaryType}));
    };
    
    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setSelectedIndex(index);
        setExportOpen(false);
    };
    
    const handleToggle = () => {
        setExportOpen((prevOpen) => !prevOpen);
    };
    
    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
    
        setExportOpen(false);
    };

    return (
        <Container>
            <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                <Button onClick={handleClick}><Icon>file_download</Icon>&nbsp;&nbsp;Download CSV: {options[selectedIndex]}</Button>
                <Button
                    color="primary"
                    size="small"
                    aria-controls={exportOpen ? 'split-button-menu' : undefined}
                    aria-expanded={exportOpen ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper open={exportOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 999}}>
                {({ TransitionProps, placement }) => (
                    <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                    >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                            {options.map((option, index) => (
                            <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={(event) => handleMenuItemClick(event, index)}
                            >
                                <span>{option}</span>
                            </MenuItem>
                            ))}
                        </MenuList>
                        </ClickAwayListener>
                    </Paper>
                    </Grow>
                )}
            </Popper>
        </Container>
    );
};
