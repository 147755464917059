import {RequestBuilder} from '../';

const HOST = `${process.env.REACT_APP_BACKEND_HOST}/config`;

export async function getConfig(name: string) {

    const url = `${HOST}/${name}`;

    return await new RequestBuilder(url)
        .setMethod('GET')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();

};

export async function updateConfig(name: string, body: any) {

    const url = `${HOST}/${name}`;

    return await new RequestBuilder(url)
        .setMethod('PUT')
        .setBody(body)
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();

};