import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import { ProjectPlan } from '../ui';

const Container = styled.div`
`;

const ContentContainer = styled.div`
    min-width: 400px;
    padding: 10px 20px;
`;

export interface IProjectPlanModal {
    open: boolean;
    onClose: () => void;
}

export const ProjectPlanModal: React.FC<IProjectPlanModal> = (props: IProjectPlanModal) => {

    const { open, onClose } = props;

    return (
        <Container>
            <Dialog open={open} fullScreen={true}>
                <DialogTitle>Project Plan</DialogTitle>
                <DialogContent>
                    <ContentContainer>
                        <ProjectPlan />
                    </ContentContainer>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};
