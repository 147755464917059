import * as React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { CopyImageButton, ExportButton, ExportCsvButton, TaskSummary, TaskSummaryControls } from '../summary';
import { notificationsAddAction } from '../../../modules';
import { IRootStore } from '../../../RootStore';

const Container = styled.div`

    display: flex;
    flex-direction: row;

    .task-button-container {
        margin-left: 10px;

        > DIV {
            margin-bottom: 20px;
        }
    }

    .task-summary-container {

        position: relative;
        overflow: hidden;

        .water-mark {
            position: absolute;
            color: rgba(255, 0, 0, .15);
            font-stretch: expanded;
            font-size: 6rem;
            width: 100%;
            text-align: center;
            transform: rotate(-10deg);
            top: 0;
            left: 55px;
        }
    }


`;

export interface ISalesSummaryTaskSummary {
}

export const SalesSummaryTaskSummary: React.FC<ISalesSummaryTaskSummary> = (props: ISalesSummaryTaskSummary) => {
    const dispatch = useDispatch();

    const {taskSummarySettings} = useSelector((state: IRootStore) => state.summary);
    const {estimate} = useSelector((state: IRootStore) => state.estimation);

    const {height, width} = taskSummarySettings;
    
    const taskSummaryRef = React.useRef<HTMLDivElement>(null);

    const PrintTaskSummary = React.forwardRef<HTMLDivElement>((props, ref) => (
        <div style={{border: '1px dashed gray', width: `${width}px`}}>
            <div ref={ref} style={{width: `${width}px`, height: `${height}px`, overflowY: 'scroll', overflowX: 'hidden'}}>
                <TaskSummary showDescriptions={taskSummarySettings.showDescriptions} valueType={taskSummarySettings.summaryType} showRoleHours={taskSummarySettings.showRoleHours} />
                {/* White space for scroll! */}
                <div style={{width: `${width}px`, height: `${height}px`}} /> 
            </div>
        </div>
    ));

    const onCopyTaskSummaryClick = async () => {
        dispatch(notificationsAddAction({store: 'estimate', dismissable: true, message: 'Task Summary copied to clipboard!', severity: 'success', timeout: 5000}));
    };

    return (
        <Container>
            <div className="task-summary-container">
                <PrintTaskSummary ref={taskSummaryRef} />
                {
                    estimate.status === 'DRAFT' &&
                    <div className="water-mark">{ new Array(20).fill('DRAFT').join(' ') }</div>
                }
            </div>
            <div>
                <TaskSummaryControls />
                <div className="task-button-container">
                    <div>
                        <CopyImageButton onClick={onCopyTaskSummaryClick} elementRef={taskSummaryRef} />
                    </div>
                    <div>
                        <ExportButton elementRef={taskSummaryRef} filename={`${estimate.id}_task_summary`} />
                    </div>
                    <div>
                        <ExportCsvButton />
                    </div>
                </div>
            </div>
        </Container>
    );
};
