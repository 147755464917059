import * as React from 'react';
import {Button} from '@mui/material';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {IRootStore} from '../../../RootStore';
import {estimationSaveAction} from '../../../modules';
import { ProjectPlanModal } from '../../modals';

const Container = styled.div`
    margin-top: 30px;

    BUTTON {
        margin-bottom: 10px;
    }

    .save-button {
        transform: translate3d(0, 0, 0);
        animation: pulse 1.25s infinite cubic-bezier(0.66, 0.33, 0, 1);
        transition: all 600ms ease-in-out;

        @keyframes pulse {
            to {
                box-shadow: 0 0 0 12px transparent, 0 0 0 24px rgba(227, 115, 14, 0);
            }
        }
    }

    .secondary-info-container {
        border-top: 1px solid #eee;
        padding-top: 10px;
        margin-top: 20px;
        font-size: .65rem;
        /* max-width: 200px; */
        text-align: right;

        > span {
            padding: 10px 0;
        }
    }
`;

const disabledStatuses = ['PUBLISHED', 'CLOSED'];
export interface IActionsProps {

}

export const Actions: React.FC<IActionsProps> = (props: IActionsProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isSaveDisabled, setIsSaveDisabled] = React.useState(true);
    const [projectPlanOpen, setProjectPlanOpen] = React.useState(false);
    
    const {estimate, hasChanged, isBusy} = useSelector((state: IRootStore) => state.estimation);

    const saveDisabledRef = React.useRef(isSaveDisabled);   

    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyPress, this);

        return () => {
        window.removeEventListener('keydown', handleKeyPress, this);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimate]);

    React.useEffect(() => {
        const _isSaveDisabled = disabledStatuses.includes(estimate.status)
            || (estimate.client || '').trim().length < 1
            || (estimate.title || '').trim().length < 1
            || (estimate.projectCode || '').trim().length < 1
            // || estimate.sections.length < 1
            // || estimate.sections.map(x => x.tasks).flat().length < 1
            || isBusy
            || !hasChanged;

        setIsSaveDisabled(_isSaveDisabled);
        saveDisabledRef.current = _isSaveDisabled;

    }, [estimate, hasChanged, isBusy]);

    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.ctrlKey && e.key === 's' && !saveDisabledRef.current) {         
          e.preventDefault();
          e.stopImmediatePropagation();
          onSaveClick();
        }
    }

    const onSaveClick = () => {
        dispatch(estimationSaveAction({estimate}));
    };

    const onShowSummary = () => {
        navigate(`/estimate/${estimate.id}/review`);
    };


    return (
        <Container>
            {
                estimate.id && estimate.id !== '' &&
                <Button variant="outlined" onClick={onShowSummary} fullWidth={true}>Sales Summary</Button>
            }
            
            <Button variant="outlined" fullWidth={true} onClick={() => setProjectPlanOpen(true)}>Project Plan</Button>
            
            {
                (estimate.status !== 'PUBLISHED' && estimate.status !== 'CLOSED') &&
                <Button className="save-button" variant="contained" color="primary" disabled={isSaveDisabled} fullWidth={true} onClick={onSaveClick}>
                    { isBusy ? 'Saving...' : 'Save'}
                </Button>
            }
            <ProjectPlanModal open={projectPlanOpen} onClose={() => setProjectPlanOpen(false)} />
        </Container>
    );
};
