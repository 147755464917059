import {call, takeLatest, put, takeEvery, select} from 'redux-saga/effects';
import {IComment, IEstimate, IHistory} from './Models';
import * as EstimateStore from './EstimationStore';
import * as Service from './Service';

import {notificationsAddAction} from '../notification';
import { refreshEstimateList } from './ListSagas';

export function* getEstimateSaga(action: EstimateStore.EstimationGetAction) {
    try {
        const estimate: IEstimate = yield call(Service.getEstimate, action.payload.id);
        yield put(EstimateStore.estimationSetAction({estimate}));
        
        yield put(EstimateStore.estimationGetHistoryAction({estimateId: action.payload.id}));

    } catch (e) {
        console.error(e);

        const error = `Error retreiving estimate: ${e}`;
        yield put(EstimateStore.estimationErrorAction({error}));
        yield put(notificationsAddAction({store: 'estimate', message: error, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* getHistorySaga(action: EstimateStore.EstimationGetHistoryAction) {
    try {        
        const history: IHistory[] = yield call(Service.getEstimateHistory, action.payload.estimateId);
        yield put(EstimateStore.estimationSetHistoryAction({history}));

    } catch (e) {
        console.error(e);

        const error = `Error retreiving history: ${e}`;
        yield put(notificationsAddAction({store: 'estimate', message: error, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* createEstimateSaga(action: EstimateStore.EstimationCreateAction) {
    try {

        const {estimate: {title, client, projectCode}, mode, primaryRole, additionalRoles} = action.payload;

        const request = {
            title,
            client,
            projectCode,
            mode,
            primaryRole,
            additionalRoles
        };

        const result: IEstimate = yield call(Service.createEstimate, request);
        yield put(EstimateStore.estimationSetAction({estimate: result}));
        yield put(notificationsAddAction({store: 'estimate', message: `Estimate created!`, severity: 'success', dismissable: true, timeout: 10000}));

        yield put(EstimateStore.estimationGetHistoryAction({estimateId: result.id}));

    } catch (e) {
        console.error(e);

        const error = `Error creating estimate: ${e}`;
        yield put(EstimateStore.estimationErrorAction({error}));
        yield put(notificationsAddAction({store: 'estimate', message: error, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* updateEstimateSaga(action: EstimateStore.EstimationSaveAction) {
    try {
        const {estimate} = action.payload;
        // if (!estimate.id || estimate.id === '') {
        //     // create
        //     const {estimate: {title, client, projectCode, settings: {mode, roles}}} = action.payload;
        //     const request = {
        //         title, 
        //         client, 
        //         projectCode, 
        //         mode,
        //         primaryRole: roles.find(x => x.isPrimary === true),
        //         additionalRoles: roles.filter(x => x.isPrimary === false),
        //     };
        //     const result: IEstimate = yield call(Service.createEstimate, request);
        //     yield put(EstimateStore.estimationSetAction({estimate: result}));
        //     yield put(notificationsAddAction({store: 'estimate', message: `Estimate created!`, severity: 'success', dismissable: true, timeout: 10000}));

        //     yield put(EstimateStore.estimationGetHistoryAction({estimateId: result.id}));
        // } else {
            // update
            const result: IEstimate = yield call(Service.updateEstimate, estimate);
            yield put(EstimateStore.estimationSetAction({estimate: result}));
            yield put(notificationsAddAction({store: 'estimate', message: `Estimate saved!`, severity: 'success', dismissable: true, timeout: 5000}));

            yield put(EstimateStore.estimationGetHistoryAction({estimateId: result.id}));
        // }

    } catch (e) {
        console.error(e);

        const error = `Error updating estimate: ${e}`;
        yield put(EstimateStore.estimationErrorAction({error}));
        yield put(notificationsAddAction({store: 'estimate', message: error, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* deleteEstimateSaga(action: EstimateStore.EstimationDeleteAction) {
    try {

        // delete
        yield call(Service.deleteEstimate, action.payload.id);
        yield put(EstimateStore.estimationSuccessAction());

        // TODO: Don't cross the streams! Move this or put() an action!
        yield refreshEstimateList();

        yield put(notificationsAddAction({store: 'estimate', message: `Estimate deleted!`, severity: 'success', dismissable: true, timeout: 10000}));
        
    } catch (e) {
        console.error(e);

        const message = `Error deleting estimate: ${e}`;
        yield put(EstimateStore.estimationErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'estimate', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* setStatusSaga(action: EstimateStore.EstimationSetStatusAction) {
    try {
        const estimate: IEstimate = yield call(Service.setStatus, action.payload.id, action.payload.status);
        yield put(EstimateStore.estimationSetStatusSuccessAction({estimate}));
        yield put(notificationsAddAction({store: 'estimate', message: `Estimate set to ${estimate.status}`, severity: 'success', dismissable: true, timeout: 5000}));

        yield put(EstimateStore.estimationGetHistoryAction({estimateId: estimate.id}));

    } catch (e) {
        console.error(e);

        const message = `Error setting status: ${e}`;
        yield put(EstimateStore.estimationErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'estimate', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* listCommentsSaga(action: EstimateStore.EstimationListCommentsAction) {
    try {
        const comments: IComment[] = yield call(Service.getComments, action.payload.id);
        yield put(EstimateStore.estimationListCommentsSuccessAction({comments}));

    } catch (e) {
        console.error(e);

        const message = `Error listing comments: ${e}`;
        yield put(EstimateStore.estimationErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'estimate', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* addCommentSaga(action: EstimateStore.EstimationAddCommentAction) {
    try {
        const comment: IComment = yield call(Service.addComment, action.payload.id, action.payload.comment);
        yield put(EstimateStore.estimationAddCommentSuccessAction({comment}));

    } catch (e) {
        console.error(e);

        const message = `Error adding comment: ${e}`;
        yield put(EstimateStore.estimationErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'estimate', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* setPrimaryVersionSaga(action: EstimateStore.EstimationSetPrimaryVersionAction) {
    try {
        const estimate: IEstimate = yield call(Service.setPrimaryVersion, action.payload.estimateId, action.payload.versionId);
        yield put(EstimateStore.estimationSetAction({estimate}));

        yield put(EstimateStore.estimationGetHistoryAction({estimateId: estimate.id}));

    } catch (e) {
        console.error(e);

        const message = `Error setting primary version: ${e}`;
        yield put(EstimateStore.estimationErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'estimate', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* autoSaveEstimate(action: EstimateStore.EstimationInfoUpdateAction) {
    try {
        const {estimate} = yield select((store) => store.estimation);
        
        if (!estimate.id) {
            return;
        }

        const result: IEstimate = yield call(Service.updateEstimate, estimate);
        yield put(EstimateStore.estimationSetAction({estimate: result}));

    } catch (e: any) {
        console.error(e);

        const message = `Error auto saving estimate: ${e.message}`;
        yield put(EstimateStore.estimationErrorAction({error: e.message}));
        yield put(notificationsAddAction({store: 'estimate', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* addDecisionSaga(action: EstimateStore.EstimationAddDecisionAction) {
    try {
        const {id, action: decision, comment} = action.payload;

        const result: IEstimate = yield call(Service.addDecision, id, decision, comment);
        yield put(EstimateStore.estimationSetAction({estimate: result}));

    } catch (e: any) {
        console.error(e);

        const message = `Error adding decision to estimate: ${e.message}`;
        yield put(EstimateStore.estimationErrorAction({error: e.message}));
        yield put(notificationsAddAction({store: 'estimate', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* addResourceSaga(action: EstimateStore.EstimationAddResourceAction) {
    try {
        const {id, name, href, description} = action.payload;

        const result: IEstimate = yield call(Service.addResource, id, name, href, description);
        yield put(EstimateStore.estimationSetAction({estimate: result}));

    } catch (e: any) {
        console.error(e);

        const message = `Error adding resource to estimate: ${e.message}`;
        yield put(EstimateStore.estimationErrorAction({error: e.message}));
        yield put(notificationsAddAction({store: 'estimate', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* deleteResourceSaga(action: EstimateStore.EstimationDeleteResourceAction) {
    try {
        const {id, resourceId} = action.payload;

        const result: IEstimate = yield call(Service.deleteResource, id, resourceId);
        yield put(EstimateStore.estimationSetAction({estimate: result}));

    } catch (e: any) {
        console.error(e);

        const message = `Error deleting resource from estimate: ${e.message}`;
        yield put(EstimateStore.estimationErrorAction({error: e.message}));
        yield put(notificationsAddAction({store: 'estimate', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* estimationSaga() {
    yield takeLatest(EstimateStore.ESTIMATION_GET, getEstimateSaga);
    yield takeLatest(EstimateStore.ESTIMATION_SAVE, updateEstimateSaga);
    yield takeLatest(EstimateStore.ESTIMATION_CREATE, createEstimateSaga);
    yield takeLatest(EstimateStore.ESTIMATION_SET_STATUS, setStatusSaga);
    yield takeEvery(EstimateStore.ESTIMATION_DELETE, deleteEstimateSaga);
    yield takeEvery(EstimateStore.ESTIMATION_ADD_COMMENT, addCommentSaga);
    yield takeEvery(EstimateStore.ESTIMATION_LIST_COMMENTS, listCommentsSaga);
    yield takeEvery(EstimateStore.ESTIMATION_GET_HISTORY, getHistorySaga);

    yield takeEvery(EstimateStore.ESTIMATION_INFO_UPDATE, autoSaveEstimate);
    yield takeEvery(EstimateStore.ESTIMATION_SET_PRIMARY_VERSION, setPrimaryVersionSaga);  
    yield takeEvery(EstimateStore.ESTIMATION_ADD_DECISION, addDecisionSaga);
    yield takeEvery(EstimateStore.ESTIMATION_ADD_RESOURCE, addResourceSaga);
    yield takeEvery(EstimateStore.ESTIMATION_DELETE_RESOURCE, deleteResourceSaga);
}
