import {createAction, createReducer} from '@reduxjs/toolkit';
import {IAuthUser} from './Models';

//
// CONSTANTS
//
export const AUTH_LOGIN = '[Auth] Login';
export const AUTH_LOGIN_SUCCESS = '[Auth] Login Success';
export const AUTH_ERROR = '[Auth] Error';
export const AUTH_LOGOUT = '[Auth] Logout';
export const AUTH_CURRENT_USER = '[Auth] Current User';
export const AUTH_CURRENT_USER_SUCCESS = '[Auth] Current User Success';

//
// ACTIONS
//

export const authLoginAction = createAction<{email: string, password: string}>(AUTH_LOGIN);
export type AuthLoginAction = ReturnType<typeof authLoginAction>;

export const authLoginSuccessAction = createAction<{token: string, user: IAuthUser}>(AUTH_LOGIN_SUCCESS)
export type AuthLoginSuccessAction = ReturnType<typeof authLoginSuccessAction>;

export const authErrorAction = createAction<{message: string}>(AUTH_ERROR);
export type AuthErrorAction = ReturnType<typeof authErrorAction>;

export const authLogoutAction = createAction(AUTH_LOGOUT);
export type AuthLogoutAction = ReturnType<typeof authLogoutAction>;

export const authCurrentUserAction = createAction(AUTH_CURRENT_USER);
export type AuthCurrentUserAction = ReturnType<typeof authCurrentUserAction>;

export const authCurrentUserSuccessAction = createAction<{user: IAuthUser}>(AUTH_CURRENT_USER_SUCCESS);
export type AuthCurrentUserSuccessAction = ReturnType<typeof authCurrentUserSuccessAction>;

export type AuthActions = 
    AuthLoginAction
    | AuthLoginSuccessAction
    | AuthErrorAction
    | AuthLogoutAction
    | AuthCurrentUserAction
    | AuthCurrentUserSuccessAction;

//
// STATE
//

export interface IAuthState {
    isBusy: boolean;
    message: string;
    token: string;
    user: IAuthUser;
}

const initialState: IAuthState = {
    isBusy: false,
    message: '',
    token: '',
    user: {} as IAuthUser
};

//
// REDUCER
//

export const authReducer = createReducer(initialState, (builder) => builder
    .addCase(authLoginAction, (state) => ({...state, isBusy: true, message: ''}))
    .addCase(authLoginSuccessAction, (state, action) => ({...state, isBusy: false, token: action.payload.token, user: action.payload.user}))
    .addCase(authErrorAction, (state, action) => ({...state, isBusy: false, message: action.payload.message}))
    .addCase(authLogoutAction, (state) => ({...state}))
    .addCase(authCurrentUserAction, (state) => ({...state, isBusy: true}))
    .addCase(authCurrentUserSuccessAction, (state, action) => ({...state, isBusy: false, user: action.payload.user}))
);
