import * as React from 'react';
import styled from 'styled-components';
import {Icon, Tooltip, Typography} from '@mui/material';

import { StatusType } from '../../modules';

const Container = styled.div`
    display: inline-flex;
    align-items: baseline;

    .status-text {
        display: inline-block;
    }
`;

export interface IStatusIndicator {
    status: StatusType;
    showText: boolean;
}

const statusMap: {[key in StatusType]: string} = {
    DRAFT: 'Draft',
    REVIEW: 'Review',
    PUBLISHED: 'Published',
    PENDING: 'Pending',
    CLOSED: 'Closed'
};

export const StatusIndicator: React.FC<IStatusIndicator> = (props: IStatusIndicator) => {
    const {showText, status} = props;

    const getIcon = (status: StatusType) => {
        switch(status) {
            case 'CLOSED':
                return (
                    <Tooltip title={showText ? 'Estimate is closed and not editable' : 'Closed'}>
                        <Icon color="secondary">do_not_disturb</Icon>
                    </Tooltip>
                );
            case 'DRAFT':
                return (
                    <Tooltip title={showText ? 'Authors are drafting estimate' : 'Draft'}>
                        <Icon color="disabled">edit</Icon>
                    </Tooltip>
                );
            case 'PENDING':
                return (
                    <Tooltip title={showText ? 'Estimate is being reviewed by customer' : 'Pending'}>
                        <Icon color="primary">hourglass_bottom</Icon>
                    </Tooltip>
                );
            case 'PUBLISHED':
                return (
                    <Tooltip title={showText ? 'Estimate has been signed' : 'Published'}>
                        <Icon color="secondary">lock</Icon>
                    </Tooltip>
                );
            case 'REVIEW':
                return (
                    <Tooltip title={showText ? 'Estimate is being reviewed internally' : 'Review'}>
                        <Icon color="primary">visibility</Icon>
                    </Tooltip>
                );
        }
    };

    const iconEl = getIcon(status);

    return (
        <Container>
                <div className="icon-container">
                {
                    iconEl
                }
                </div>
                {
                    showText &&
                    <Typography variant="button" color="text.secondary" className="status-text">{statusMap[status]}</Typography>
                }
        </Container>
    );
};
