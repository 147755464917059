import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as VersionStore from './VersionStore';
import * as EstimateStore from './EstimationStore';
import * as Service from './Service';
import { ITask, IVersion } from './Models';
import { notificationsAddAction } from '../notification';
import { IRootStore } from '../../RootStore';

export function* getVersionsSaga(action: VersionStore.VersionListAction) {
    try {
        const versions: IVersion[] = yield call(Service.getEstimateVersions, action.payload.estimateId);
        yield put(VersionStore.versionListSuccessAction({versions}));

        const {selectedVersionId} = yield select((store: IRootStore) => store.version);

        // now that the list has loaded, tell the reducer to reset the version object
        if (selectedVersionId !== '') {
            yield put(VersionStore.versionSetVersionAction({versionId: selectedVersionId}));
        }

    } catch (e) {
        console.error(e);

        const error = `Error retreiving versions: ${e}`;
        yield put(VersionStore.versionErrorAction({error}));
        yield put(notificationsAddAction({store: 'version', message: error, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* newVersionSaga(action: VersionStore.VersionNewAction) {
    try {

        const version: IVersion = yield call(Service.revision, action.payload.estimateId);
        yield put(VersionStore.versionNewSuccessAction({version}));

        yield put(notificationsAddAction({store: 'version', message: `Version ${version.version} created!`, severity: 'success', dismissable: true, timeout: 10000}));

        const versions: IVersion[] = yield call(Service.getEstimateVersions, action.payload.estimateId);
        yield put(VersionStore.versionListSuccessAction({versions}));

    } catch (e) {
        console.error(e);

        const error = `Error creating new version: ${e}`;
        yield put(VersionStore.versionErrorAction({error}));
        yield put(notificationsAddAction({store: 'version', message: error, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* updateVersionSaga(action: VersionStore.VersionUpdateAction) {
    try {
        const {id, estimateId, description} = action.payload.version;
        const version: IVersion = yield call(Service.updateVersion, estimateId, id, description);
        yield put(VersionStore.versionSuccessAction({version}));

        const versions: IVersion[] = yield call(Service.getEstimateVersions, estimateId);
        yield put(VersionStore.versionListSuccessAction({versions}));

    } catch (e) {
        console.error(e);

        const error = `Error updating version: ${e}`;
        yield put(VersionStore.versionErrorAction({error}));
        yield put(notificationsAddAction({store: 'version', message: error, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* addSectionSaga(action: VersionStore.VersionSectionAddAction) {
    try {
        const {selectedVersionId, version: {estimateId}} = yield select((store: IRootStore) => store.version);
        yield call(Service.addSection, estimateId, selectedVersionId, action.payload.section);
        const version: IVersion = yield call(Service.getEstimateVersionById, estimateId, selectedVersionId);
        yield put(VersionStore.versionSuccessAction({version}));

    } catch (e) {
        console.error(e);

        const error = `Error adding section: ${e}`;
        yield put(VersionStore.versionErrorAction({error}));
        yield put(notificationsAddAction({store: 'version', message: error, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* updateSectionSaga(action: VersionStore.VersionSectionUpdateAction) {
    try {
        const {selectedVersionId, version: {estimateId}} = yield select((store: IRootStore) => store.version);
        yield call(Service.updateSection, estimateId, selectedVersionId, action.payload.section);
        const version: IVersion = yield call(Service.getEstimateVersionById, estimateId, selectedVersionId);
        yield put(VersionStore.versionSuccessAction({version}));

    } catch (e) {
        console.error(e);

        const error = `Error updating section: ${e}`;
        yield put(VersionStore.versionErrorAction({error}));
        yield put(notificationsAddAction({store: 'version', message: error, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* deleteSectionSaga(action: VersionStore.VersionSectionDeleteAction) {
    try {
        const {selectedVersionId, version: {estimateId}} = yield select((store: IRootStore) => store.version);
        yield call(Service.deleteSection, estimateId, selectedVersionId, `${action.payload.id}`);
        const version: IVersion = yield call(Service.getEstimateVersionById, estimateId, selectedVersionId);
        yield put(VersionStore.versionSuccessAction({version}));

    } catch (e) {
        console.error(e);

        const error = `Error deleting section: ${e}`;
        yield put(VersionStore.versionErrorAction({error}));
        yield put(notificationsAddAction({store: 'version', message: error, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* addTaskSaga(action: VersionStore.VersionTaskAddAction) {
    try {
        const { task, sectionId } = action.payload;
        const {selectedVersionId, version: {estimateId}} = yield select((store: IRootStore) => store.version);
        const result: ITask = yield call(Service.addTask, estimateId, selectedVersionId, `${sectionId}`, task);

        yield put(VersionStore.versionTaskAddSuccessAction({task: result, sectionId}));

        const version: IVersion = yield call(Service.getEstimateVersionById, estimateId, selectedVersionId);
        yield put(VersionStore.versionSuccessAction({version}));

    } catch (e) {
        console.error(e);

        const error = `Error adding task: ${e}`;
        yield put(VersionStore.versionErrorAction({error}));
        yield put(notificationsAddAction({store: 'version', message: error, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* updateTaskSaga(action: VersionStore.VersionTaskUpdateAction) {
    try {
        const { task, sectionId } = action.payload;
        const {selectedVersionId, version: {estimateId}} = yield select((store: IRootStore) => store.version);
        const result: ITask = yield call(Service.updateTask, estimateId, selectedVersionId, `${sectionId}`, task);

        yield put(VersionStore.versionTaskUpdateSuccessAction({task: result, sectionId}));

        const version: IVersion = yield call(Service.getEstimateVersionById, estimateId, selectedVersionId);
        yield put(VersionStore.versionSuccessAction({version}));

    } catch (e) {
        console.error(e);

        const error = `Error updating task: ${e}`;
        yield put(VersionStore.versionErrorAction({error}));
        yield put(notificationsAddAction({store: 'version', message: error, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* deleteTaskSaga(action: VersionStore.VersionTaskDeleteAction) {
    try {
        const { id, sectionId } = action.payload;
        const {selectedVersionId, version: {estimateId}} = yield select((store: IRootStore) => store.version);
        
        yield call(Service.deleteTask, estimateId, selectedVersionId, `${sectionId}`, `${id}`);

        yield put(VersionStore.versionTaskDeleteSuccessAction({ id: id }));
        
        const version: IVersion = yield call(Service.getEstimateVersionById, estimateId, selectedVersionId);
        yield put(VersionStore.versionSuccessAction({version}));

    } catch (e) {
        console.error(e);

        const error = `Error deleting task: ${e}`;
        yield put(VersionStore.versionErrorAction({error}));
        yield put(notificationsAddAction({store: 'version', message: error, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* resetSettingsSaga(action: VersionStore.VersionSettingsResetAction): any {
    try {
        const {estimateId, versionId} = action.payload;
        const version: IVersion = yield call(Service.resetSettings, estimateId, versionId);

        yield put(VersionStore.versionSuccessAction({version}));

        // Update the list and reset the selected Version.
        // yield put(VersionStore.versionListAction({estimateId}));

        yield afterSuccessSaga();


    } catch (e: any) {
        console.error(e);

        const message = `Error resetting version settings: ${e.message}`;
        yield put(VersionStore.versionErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'version', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* changePrimaryRoleSaga(action: VersionStore.VersionSettingsChangeRoleAction): any {
    try {
        const {primaryRole, versionId} = action.payload;

        const {estimate} = yield select((store) => store.estimation);
        
        const version: IVersion = yield call(Service.changePrimaryRole, estimate.id, versionId, primaryRole);

        yield put(VersionStore.versionSuccessAction({version}));
        
        // // Update the list and reset the selected Version.
        // yield put(VersionStore.versionListAction({estimateId: estimate.id}));

        yield put(notificationsAddAction({store: 'version', message: `Primary role updated!`, severity: 'success', dismissable: true, timeout: 10000}));

        yield afterSuccessSaga();

    } catch (e: any) {
        console.error(e);

        const message = `Error changing the primary role: ${e.message}`;
        yield put(VersionStore.versionErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'version', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* updateSettingsSaga(action: VersionStore.VersionSettingsChangeAction) {
    try {
        const {settings} = action.payload;
        const {estimate} = yield select((store) => store.estimation);
        const {selectedVersionId} = yield select((store) => store.version);
        
        const version: IVersion = yield call(Service.updateSettings, estimate.id, selectedVersionId, settings);

        yield put(VersionStore.versionSuccessAction({version}));

        // // Update the list and reset the selected Version.
        // yield put(VersionStore.versionListAction({estimateId: estimate.id}));

        yield put(notificationsAddAction({store: 'version', message: `Settings updated!`, severity: 'success', dismissable: true, timeout: 10000}));

        yield afterSuccessSaga();

    } catch (e: any) {
        console.error(e);

        const message = `Error updating settings for version: ${e.message}`;
        yield put(VersionStore.versionErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'version', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* afterSuccessSaga() {
    const {estimate} = yield select((store) => store.estimation);
    yield put(VersionStore.versionListAction({estimateId: estimate.id}));
    yield put(EstimateStore.estimationGetHistoryAction({estimateId: estimate.id}));
}

export function* updateSettingsModeSaga(action: VersionStore.VersionSettingsChangeModeAction) {
    try {
        const {mode} = action.payload;
        const {estimate} = yield select((store) => store.estimation);
        const {selectedVersionId, version: {settings}} = yield select((store) => store.version);
        
        const version: IVersion = yield call(Service.updateSettings, estimate.id, selectedVersionId, {...settings, mode});

        yield put(VersionStore.versionSuccessAction({version}));

        // // Update the list and reset the selected Version.
        // yield put(VersionStore.versionListAction({estimateId: estimate.id}));

        yield put(notificationsAddAction({store: 'version', message: `Mode updated to ${mode}!`, severity: 'success', dismissable: true, timeout: 10000}));

    } catch (e: any) {
        console.error(e);

        const message = `Error updating mode for version: ${e.message}`;
        yield put(VersionStore.versionErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'version', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* versionSagas() {
    yield takeEvery(VersionStore.VERSION_LIST, getVersionsSaga);
    yield takeEvery(VersionStore.VERSION_SECTION_ADD, addSectionSaga);
    yield takeEvery(VersionStore.VERSION_SECTION_UPDATE, updateSectionSaga);
    yield takeEvery(VersionStore.VERSION_SECTION_DELETE, deleteSectionSaga);
    yield takeEvery(VersionStore.VERSION_TASK_ADD, addTaskSaga);
    yield takeEvery(VersionStore.VERSION_TASK_UPDATE, updateTaskSaga);
    yield takeEvery(VersionStore.VERSION_TASK_DELETE, deleteTaskSaga);
    yield takeEvery(VersionStore.VERSION_NEW, newVersionSaga);
    yield takeEvery(VersionStore.VERSION_SETTINGS_RESET, resetSettingsSaga);
    yield takeEvery(VersionStore.VERSION_SETTINGS_CHANGE_ROLE, changePrimaryRoleSaga);
    yield takeEvery(VersionStore.VERSION_SETTINGS_CHANGE, updateSettingsSaga);
    yield takeEvery(VersionStore.VERSION_SETTINGS_CHANGE_MODE, updateSettingsModeSaga);
    yield takeEvery(VersionStore.VERSION_UPDATE, updateVersionSaga);

    // yield takeEvery(VersionStore.versionSuccessAction, afterSuccessSaga);
}