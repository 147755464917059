import { createTheme } from '@mui/material/styles';
// import { green, orange } from '@mui/core/colors';

// https://material-ui.com/customization/palette/#using-a-color-object

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      tertiary: React.CSSProperties["color"];
    };
  }

  interface Palette {
    tertiary: Palette["primary"];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions["primary"];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
  interface ThemeOptions {
    status: {
      tertiary: React.CSSProperties["color"];
    };
  }
}

export const appTheme = createTheme({
    palette: {
        primary: {
            main: '#006E90'
            // main: '#99C24D'
        },
        secondary: {
            // main: orange[500]
            light: '#D93030',
            main: '#D62828',
            // contrastText: '#671313'
        },
        tertiary: {
            light: '#199b78',
            main: '#00906a',
        },
        // #00906A Green color for status?
    },
    status: {
      tertiary: '#00906a'
    }
});