import * as React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {NumberFormat, RoleHourAmountsTable} from '../../ui';
import {calculateRateCard, ITaskSummary} from '../../../modules';
import {IRootStore} from '../../../RootStore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const Container = styled.div`
    min-width: 225px;
`;

export interface IWbsSummaryProps {
}

export const WbsSummary: React.FC<IWbsSummaryProps> = (props: IWbsSummaryProps) => {

    const {settings: {showAmounts}} = useSelector((state: IRootStore) => state.estimation);
    const {version} = useSelector((state: IRootStore) => state.version);

    const roleMap = version.settings.roles.reduce((accumulator: {[key: string]: string}, cur) => {
        accumulator[cur.role] = cur.name;
        return accumulator;
    }, {});

    const rateCard: {[key: string]: ITaskSummary} = calculateRateCard(version);
    const summary = Object.entries(rateCard)
        .filter(([key,]) => key !== 'Total')
        .map(([key, _summary]) => ({
            role: key,
            hours: _summary.hours,
            amount: _summary.amount,
            label: roleMap[key],
        }));

    return (
        <Container>
            <Accordion defaultExpanded={false} elevation={0}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h5">
                        {
                            showAmounts &&
                            <span>{rateCard.Total.hours} hrs / <NumberFormat value={rateCard.Total.amount} isCurrency={true} /></span>

                        }
                        {
                            !showAmounts &&
                            <span>{rateCard.Total.hours} hours</span>
                        }
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <RoleHourAmountsTable summary={summary} totalHours={rateCard.Total.hours} totalAmount={rateCard.Total.amount} showAmounts={showAmounts} />
                </AccordionDetails>
            </Accordion>
        </Container>
    );
};
