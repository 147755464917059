//
// CONSTANTS
//

import { createAction, createReducer } from "@reduxjs/toolkit";
import { IAssumption } from "./Models";

export const ASSUMPTIONS_LIST = '[Assumptions] List';
export const ASSUMPTIONS_LIST_SUCCESS = '[Assumptions] List Success';
export const ASSUMPTIONS_CREATE = '[Assumptions] Create';
export const ASSUMPTIONS_CREATE_SUCCESS = '[Assumptions] Create Success';
export const ASSUMPTIONS_UPDATE = '[Assumptions] Update';
export const ASSUMPTIONS_UPDATE_SUCCESS = '[Assumptions] Update Success';
export const ASSUMPTIONS_DELETE = '[Assumptions] Delete';
export const ASSUMPTIONS_DELETE_SUCCESS = '[Assumptions] Delete Success';
export const ASSUMPTIONS_ERROR = '[Assumptions] Error';


//
// ACTIONS
//

export const assumptionsListAction = createAction<{estimateId: string}>(ASSUMPTIONS_LIST);
export type AssumptionsListAction = ReturnType<typeof assumptionsListAction>;

export const assumptionsListSuccessAction = createAction<{assumptions: IAssumption[]}>(ASSUMPTIONS_LIST_SUCCESS);
export type AssumptionsListSuccessAction = ReturnType<typeof assumptionsListSuccessAction>;

export const assumptionsCreateAction = createAction<{assumption: string}>(ASSUMPTIONS_CREATE);
export type AssumptionsCreateAction = ReturnType<typeof assumptionsCreateAction>;

export const assumptionsCreateSuccessAction = createAction<{assumption: IAssumption}>(ASSUMPTIONS_CREATE_SUCCESS);
export type AssumptionsCreateSuccessAction = ReturnType<typeof assumptionsCreateSuccessAction>;

export const assumptionsUpdateAction = createAction<{assumption: IAssumption}>(ASSUMPTIONS_UPDATE);
export type AssumptionsUpdateAction = ReturnType<typeof assumptionsUpdateAction>;

export const assumptionsUpdateSuccessAction = createAction<{assumption: IAssumption}>(ASSUMPTIONS_UPDATE_SUCCESS);
export type AssumptionsUpdateSuccessAction = ReturnType<typeof assumptionsUpdateSuccessAction>;

export const assumptionsDeleteAction = createAction<{id: string}>(ASSUMPTIONS_DELETE);
export type AssumptionsDeleteAction = ReturnType<typeof assumptionsDeleteAction>;

export const assumptionsDeleteSuccessAction = createAction<{id: string}>(ASSUMPTIONS_DELETE_SUCCESS);
export type AssumptionsDeleteSuccessAction = ReturnType<typeof assumptionsDeleteSuccessAction>;

export const assumptionsErrorAction = createAction<{error: string}>(ASSUMPTIONS_ERROR);
export type AssumptionsErrorAction = ReturnType<typeof assumptionsErrorAction>;

//
// TYPES
//

export type AssumptionsActionsType = 
    AssumptionsListAction |
    AssumptionsListSuccessAction |
    AssumptionsCreateAction |
    AssumptionsCreateSuccessAction |
    AssumptionsUpdateAction |
    AssumptionsUpdateSuccessAction |
    AssumptionsDeleteAction |
    AssumptionsDeleteSuccessAction |
    AssumptionsErrorAction;

//
// STATE
//

export interface IAssumptionState {
    isBusy: boolean;
    error: string;
    estimateId: string;
    assumptions: IAssumption[];
}

const initialState: IAssumptionState = {
    isBusy: false,
    error: '',
    estimateId: '',
    assumptions: []
};

//
// REDUCER
//

export const assumptionsReducer = createReducer(initialState, (builder) => builder
    .addCase(assumptionsListAction, (state, action) => ({...state, isBusy: true, error: '', assumptions: [], estimateId: action.payload.estimateId}))
    .addCase(assumptionsListSuccessAction, (state, action) => ({...state, isBusy: false, assumptions: action.payload.assumptions}))
    .addCase(assumptionsCreateAction, (state, action) => ({...state, isBusy: true}))
    .addCase(assumptionsCreateSuccessAction, (state, action) => ({...state, isBusy: false, assumptions: [...state.assumptions, action.payload.assumption]}))
    .addCase(assumptionsUpdateAction, (state, action) => ({...state, isBusy: true}))
    .addCase(assumptionsUpdateSuccessAction, (state, action) => {
        const {id} = action.payload.assumption;

        const _assumptions = [...state.assumptions];
        const index = _assumptions.findIndex(x => x.id === id);
        _assumptions[index] = {...action.payload.assumption};

        return {...state, isBusy: false, assumptions: _assumptions}
    })
    .addCase(assumptionsDeleteAction, (state, action) => ({...state, isBusy: true}))
    .addCase(assumptionsDeleteSuccessAction, (state, action) => {
        const {id} = action.payload;

        const _assumptions = [...state.assumptions];
        const index = _assumptions.findIndex(x => x.id === id);
        _assumptions.splice(index, 1);

        return {...state, isBusy: false, assumptions: _assumptions}
    })
    .addCase(assumptionsErrorAction, (state, action) => ({...state, isBusy: false, error: action.payload.error}))
);