import {createAction, createReducer} from '@reduxjs/toolkit';
import {IUser} from './Models';

//
// CONSTANTS
//
export const USER_CURRENT_USER = '[User] Current User';
export const USER_CURRENT_USER_SUCCESS = '[User] Current User Success';
export const USER_LIST = '[User] List';
export const USER_LIST_SUCCESS = '[User] List Success';
export const USER_UPDATE = '[User] Update';
export const USER_UPDATE_SUCCESS = '[User] Update Success';
export const USER_CHANGE_PWD = '[User] Change password';
export const USER_CHANGE_PWD_SUCCESS = '[User] Change password success';
export const USER_ERROR = '[User] Error';

//
// ACTIONS
//

export const userCurrentUserAction = createAction<{currentUser: IUser}>(USER_CURRENT_USER);
export type UserCurrentUserAction = ReturnType<typeof userCurrentUserAction>;

export const userCurrentUserSuccessAction = createAction<{currentUser: IUser}>(USER_CURRENT_USER_SUCCESS);
export type UserCurrentUserSuccessAction = ReturnType<typeof userCurrentUserSuccessAction>;

export const userListAction = createAction(USER_LIST);
export type UserListAction = ReturnType<typeof userListAction>;

export const userListSuccessAction = createAction<{userList: IUser[]}>(USER_LIST_SUCCESS);
export type UserListSuccessAction = ReturnType<typeof userListSuccessAction>;

export const userUpdateAction = createAction<{user: IUser}>(USER_UPDATE);
export type UserUpdateAction = ReturnType<typeof userUpdateAction>;

export const userUpdateSuccessAction = createAction(USER_UPDATE_SUCCESS);
export type UserUpdateSuccessAction = ReturnType<typeof userUpdateSuccessAction>;

export const userErrorAction = createAction<{message: string}>(USER_ERROR);
export type UserErrorAction = ReturnType<typeof userErrorAction>;

export const userChangePasswordAction = createAction<{user: IUser, password: string, isAdmin: boolean}>(USER_CHANGE_PWD);
export type UserChangePasswordAction = ReturnType<typeof userChangePasswordAction>;

export const userChangePasswordSuccessAction = createAction(USER_CHANGE_PWD_SUCCESS);
export type UserChangePasswordSuccessAction = ReturnType<typeof userChangePasswordSuccessAction>;

export type UserActions = 
    UserCurrentUserAction
    | UserCurrentUserSuccessAction
    | UserListAction
    | UserListSuccessAction
    | UserUpdateAction
    | UserUpdateSuccessAction
    | UserChangePasswordAction
    | UserChangePasswordSuccessAction
    | UserErrorAction;

//
// STATE
//

export interface IUserState {
    isBusy: boolean;
    message: string;
    currentUser: IUser;
    userList: IUser[];
}

const initialState: IUserState = {
    isBusy: false,
    message: '',
    currentUser: {} as IUser,
    userList: []
};

//
// REDUCER
//

export const userReducer = createReducer(initialState, (builder) => builder
    .addCase(userCurrentUserAction, (state, action) => ({...state, isBusy: true, currentUser: action.payload.currentUser}))
    .addCase(userCurrentUserSuccessAction, (state, action) => ({...state, isBusy: false, currentUser: action.payload.currentUser}))
    .addCase(userListAction, (state) => ({...state, isBusy: true}))
    .addCase(userListSuccessAction, (state, action) => ({...state, isBusy: false, userList: action.payload.userList}))
    .addCase(userUpdateAction, (state) => ({...state, isBusy: true}))
    .addCase(userUpdateSuccessAction, (state) => ({...state, isBusy: false}))
    .addCase(userChangePasswordAction, (state) => ({...state, isBusy: false}))
    .addCase(userChangePasswordSuccessAction, (state) => ({...state, isBusy: false}))
    .addCase(userErrorAction, (state, action) => ({...state, isBusy: false, message: action.payload.message}))
);
