import * as React from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {TextField, Button, Icon} from '@mui/material';

import {versionSettingsChangeAction, EstimateModeType, versionSettingsChangeModeAction, versionSettingsResetAction, versionSettingsChangeRoleAction} from '../../../modules';
import {IRootStore} from '../../../RootStore';
import {ConfirmModal, ChangePrimaryRoleModal} from '../../modals'
import {EstimateRoleTable, AddRole, ModeSwitcher} from '..';

const Container = styled.div`
    width: 100%;

    .ranges-and-mode-container {
        display: flex;
        
        .mode-container {
        }
    }

    .ranges {
        margin-right: 50px;

        .range-input {
            margin-right: 20px;
        }
    }

    .roles-and-rates-actions {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

export interface ISettingsProps {

}

export const Settings: React.FC<ISettingsProps> = (props: ISettingsProps) => {

    const dispatch = useDispatch();

    const {roles, ranges, mode} = useSelector((state: IRootStore) => state.version.version.settings);

    const [isModeConfirmModalOpen, setIsModeConfirmModalOpen] = React.useState(false);
    const [isResetConfirmModalOpen, setIsResetConfirmModalOpen] = React.useState(false);
    const [isChangeRoleModalOpen, setIsChangeRoleModalOpen] = React.useState(false);
    const [, setMode] = React.useState<EstimateModeType>(mode);

    const onRangeChange = (type: 'high' | 'low', value: string) => {
        const val = parseFloat(value);
        const _ranges = {...ranges};
        _ranges[type] = val;

        const settings = {roles, ranges: _ranges, mode};

        dispatch(versionSettingsChangeAction({settings}));
    };

    const confirmModeChange = () => {
        const _mode: EstimateModeType = (mode === 'MANUAL') ? 'COMPUTED' : 'MANUAL';
        setMode(_mode);

        dispatch(versionSettingsChangeModeAction({mode: _mode}));

        setIsModeConfirmModalOpen(false);
    }

    const onModeChange = () => {
        setIsModeConfirmModalOpen(true);
    };

    const onResetClick = () => {
        setIsResetConfirmModalOpen(true);
    }

    const confirmResetClick = () => {
        // dispatch(versionSettingsResetAction());
        setIsResetConfirmModalOpen(false);
    };

    const onChangePrimaryRoleClick = (primaryRole: string) => {
        // dispatch(versionSettingsChangeRoleAction({primaryRole}));
        setIsChangeRoleModalOpen(false);
    };

    return (
        <Container>
            <div className="ranges-and-mode-container">
                <div className="ranges">
                    <h3>Range</h3>
                    <TextField
                        type="number"
                        label="High"
                        variant="standard"
                        size="small"
                        className="range-input"
                        inputProps={{min: 0, step: '0.05', style: { textAlign: 'right' }}}
                        value={ranges.high}
                        onChange={(event) => onRangeChange('high', event.currentTarget.value)}
                    />
                    <TextField
                        type="number"
                        label="Low"
                        variant="standard"
                        size="small"
                        className="range-input"
                        inputProps={{min: 0, step: '0.05', style: { textAlign: 'right' }}}
                        value={ranges.low}
                        onChange={(event) => onRangeChange('low', event.currentTarget.value)}
                    />
                </div>
                <div className="mode-container">
                    <h3>Mode</h3>
                    <ModeSwitcher mode={mode} onChange={onModeChange} />
                </div>
            </div>
            
            <div className="roles-and-rates">
                <h3>Roles</h3>
                <EstimateRoleTable />
            </div>
            <div className="roles-and-rates-actions">
                <div>
                    <AddRole />
                </div>
                <div>
                <Button  variant="contained" color="primary" onClick={() => setIsChangeRoleModalOpen(true)}>
                        <Icon>person</Icon> Change Primary Role
                    </Button>
                </div>
                <div>
                    <Button  variant="contained" color="secondary" onClick={onResetClick}>
                        <Icon>sync</Icon> Resync from global
                    </Button>
                </div>
            </div>
            <div className="modals">
                <ConfirmModal isOpen={isModeConfirmModalOpen} onConfirm={confirmModeChange} onCancel={() => setIsModeConfirmModalOpen(false)}>
                    <p>Changing the mode will alter all tasks on the estimate. Do you wish to continue?</p>
                </ConfirmModal>
                <ConfirmModal isOpen={isResetConfirmModalOpen} onConfirm={confirmResetClick} onCancel={() => setIsResetConfirmModalOpen(false)}>
                    <p>Resyncing settings with the values from the global configuration will overwrite all settings in your estimate will affect all tasks. Do you wish to continue?</p>
                </ConfirmModal>
                <ChangePrimaryRoleModal open={isChangeRoleModalOpen} onCancel={() => setIsChangeRoleModalOpen(false)} onSave={onChangePrimaryRoleClick} />
            </div>
        </Container>
    );
};
