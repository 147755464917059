import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Popover, Typography } from '@mui/material';
import { KeyboardDoubleArrowDown } from '@mui/icons-material';

import { IRootStore } from '../../../RootStore';
import { ITaskSummary, StatusType, calculateRateCard, estimationSetStatusAction, versionSetVersionAction } from '../../../modules';
import { NumberFormat } from '../NumberFormat';
import { RoleHourAmountsTable } from './RoleHourAmountsTable';
import { StatusButton } from './StatusButton';
import { ConfirmPublishModal, EstimateVersionModal } from '../../modals';
import { SettingsMenu } from './SettingsMenu';
import { Approval } from './Approval';

const Container = styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px;
    box-sizing: border-box;

    .left-column {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;

        .first-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
        }

        .second-line {
            
        }

        .version-container {
            margin-left: 10px;
            display: flex;
            align-items: center;

            .warning-text {
                margin-left: 10px;
            }
        }
        .status-container {
            margin-left: 20px;
        }
        .settings-container {
            margin-left: 10px;
        }
        
    }
    .right-column {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
        /* flex-basis: 365px; */

        > BUTTON {
            margin-left: 10px;
        }
    }

    .title-text {
        max-width: 30vw;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .secondary-info-container {
        font-size: .65rem;
        text-align: left;
        white-space: nowrap;
    }

    .blink {
        animation: blinker 3s linear infinite;
    }

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
`;

export interface IEstimationHeader {
}

export const EstimationHeader: React.FC<IEstimationHeader> = (props: IEstimationHeader) => {

    const dispatch = useDispatch();

    const {estimate, settings: {showAmounts}} = useSelector((store: IRootStore) => store.estimation);
    const {version} = useSelector((store: IRootStore) => store.version);

    const {summary} = version;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [isPublishOpen, setIsPublishOpen] = React.useState(false);
    const [isVersionsOpen, setIsVersionsOpen] = React.useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onStatusClick = (_status: StatusType) => {
        if (_status === 'PUBLISHED') {
          setIsPublishOpen(true);
        } else {
          dispatch(estimationSetStatusAction({ id: estimate.id, status: _status }));
        }
    }

    const onCancel = () => {
        setIsPublishOpen(false);
    };

    const onConfirm = () => {
        dispatch(estimationSetStatusAction({ id: estimate.id, status: 'PUBLISHED' }));
        setIsPublishOpen(false);
    };

    const onVersionSelect = (versionId: string | null) => {
        if (versionId) {
            dispatch(versionSetVersionAction({versionId}));
        }

        setIsVersionsOpen(false);
    };

    const open = Boolean(anchorEl);

    const rateCard: {[key: string]: ITaskSummary} = calculateRateCard(version);

    const roleMap = version.settings.roles.reduce((accumulator: {[key: string]: string}, cur) => {
        accumulator[cur.role] = cur.name;
        return accumulator;
    }, {});

    const roleSummary = Object.entries(rateCard)
        .filter(([key,]) => key !== 'Total')
        .map(([key, _summary]) => ({
            role: key,
            hours: _summary.hours,
            amount: _summary.amount,
            label: roleMap[key],
        }));

    return (
        <Container>
            <div className="left-column">
                <div className="first-line">
                    <div className="settings-container">
                        <SettingsMenu />
                    </div>
                    <div className="version-container">
                        <Button
                            variant="text" 
                            color="primary"
                            onClick={() => setIsVersionsOpen(true)}
                        >
                            v{version.version}
                        </Button>
                        <EstimateVersionModal open={isVersionsOpen} onCancel={() => setIsVersionsOpen(false)} onSelect={onVersionSelect} />
                    </div>
                    <div>
                    {
                        estimate.version !== version.id &&
                        <Typography className="warning-text blink" color="secondary" variant="button">Viewing non-primary version</Typography>
                    }
                    </div>
                </div>
            </div>
            <div className="right-column">
                <div className="status-container">
                    <StatusButton currentStatus={estimate.status} onClick={onStatusClick} disabled={estimate.version !== version.id} />
                    <ConfirmPublishModal
                        isOpen={isPublishOpen}
                        onCancel={onCancel}
                        onConfirm={onConfirm}
                    />
                </div>
                <Approval disabled={estimate.version !== version.id || ['PUBLISHED', 'CLOSED'].includes(estimate.status)} />
                <Button variant="text" onClick={handleClick} tabIndex={-1} color="inherit" endIcon={<KeyboardDoubleArrowDown />}>
                        {
                            showAmounts &&
                            <span><NumberFormat value={summary.hours} /> hrs / <NumberFormat value={summary.amount} isCurrency={true} /></span>

                        }
                        {
                            !showAmounts &&
                            <span><NumberFormat value={summary.hours} /> hours</span>
                        }
                </Button>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <RoleHourAmountsTable summary={roleSummary} totalHours={summary.hours} totalAmount={summary.amount} showAmounts={showAmounts} />
                </Popover>

            </div>
        </Container>
    );
};
