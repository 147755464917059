import { DeleteForever, MoreVert, VisibilityOff } from '@mui/icons-material';
import { Button, Icon, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`

    BUTTON {
        padding: 3px;
    }
`;

export interface ITaskActions {
    onCopyClick: () => void;
    onVisibilityClick: () => void;
    onDeleteClick: () => void;
    isVisible: boolean;
}

export const TaskActions: React.FC<ITaskActions> = (props: ITaskActions) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const {isVisible, onCopyClick, onVisibilityClick, onDeleteClick} = props;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Container>
            <IconButton size="small" onClick={handleClick} tabIndex={-1}>
                <MoreVert fontSize="inherit" />
            </IconButton>
            {/* <Button onClick={handleClick} tabIndex={-1}>
                <Icon color="disabled">more_vert</Icon>
            </Button> */}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => { onCopyClick(); handleClose(); }}>
                    <ListItemIcon>
                        <Icon color="action">file_copy</Icon>
                    </ListItemIcon>
                    <span>Copy</span>
                </MenuItem>
                <MenuItem onClick={() => { onVisibilityClick();  handleClose(); }}>
                    <ListItemIcon>
                        <VisibilityOff fontSize='small' color={isVisible ? 'disabled' : 'primary'} />
                    </ListItemIcon>
                    <span>{ isVisible ? 'Hide Task' : 'Show Task' }</span>
                </MenuItem>
                <MenuItem onClick={() => { onDeleteClick(); handleClose(); } }>
                    <ListItemIcon>
                        <DeleteForever fontSize='small' color="secondary" />
                    </ListItemIcon>
                    <span>Delete</span>
                </MenuItem>
            </Menu>
        </Container>
    );
};
