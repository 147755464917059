import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RoleSelector } from '..';
import { IRootStore } from '../../../RootStore';
import { Button, Icon } from '@mui/material';
import { versionSettingsChangeAction, IRoleSetting } from '../../../modules';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .role-selector-container {
        min-width: 300px;
        margin-right: 20px;
    }
`;

export interface IAddRole {
}

export const AddRole: React.FC<IAddRole> = (props: IAddRole) => {
    const dispatch = useDispatch();

    const [roleSetting, setRoleSetting] = React.useState<IRoleSetting | undefined>()

    const {roles, ranges, mode} = useSelector((state: IRootStore) => state.version.version.settings);
    const {rolesAndRates} = useSelector((state: IRootStore) => state.config);

    const onRoleAdd = (role: string) => {
        const newRole = rolesAndRates.find(x => x.role === role);

        if (!newRole) {
            return;
        }

        const _role = {...newRole, isPrimary: false};

        setRoleSetting(_role);
    };

    const onAddRoleClick = () => {
        if (!roleSetting) {
            return;
        }

        const settings = {roles: [...roles, roleSetting], ranges, mode};

        dispatch(versionSettingsChangeAction({settings}));

        setRoleSetting(undefined);
    };
    
    return (
        <Container>
            <div className="role-selector-container">
                <RoleSelector excludedRoles={roles.map((x) => x.role)} onChange={onRoleAdd} />
            </div>
            <div>
                <Button variant="contained" onClick={onAddRoleClick} disabled={!roleSetting}>
                    <Icon>add_box</Icon>
                    <span>Add Role</span>
                </Button>
            </div>
        </Container>
    );
};
