import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {Table, TableHead, TableBody, TableRow, TableCell, Button, Icon} from '@mui/material';

import {IRootStore} from '../../../RootStore';
import {getConfigAction, RateAndRole, updateConfigAction} from '../../../modules';
import {DefaultRoleEditor} from './DefaultRoleEditor';

const Container = styled.div`

    TABLE {
        margin-bottom: 20px;
    }

    .button-container {
        BUTTON {
            margin-right: 20px;
        }
    }
`;

export interface IDefaultRatesAndRoles {

}

export const DefaultRatesAndRoles: React.FC<IDefaultRatesAndRoles> = (props: IDefaultRatesAndRoles) => {
    const dispatch = useDispatch();

    const {rolesAndRates, isBusy} = useSelector((store: IRootStore) => store.config);

    const [changes, setChanges] = React.useState([...rolesAndRates]);

    React.useEffect(() => {
        dispatch(getConfigAction({context: 'defaultRolesAndRates'}));
    }, [dispatch]);

    React.useEffect(() => {
        setChanges(rolesAndRates);
    }, [rolesAndRates]);

    const onSaveClick = () => {
        dispatch(updateConfigAction({context: 'defaultRolesAndRates', content: changes}));
    };

    const onRemoveClick = (index: number) => {
        const newChanges = [...changes];
        newChanges.splice(index, 1);
        setChanges(newChanges);
    };

    const onRowUpdated = (index: number, rateAndRole: RateAndRole) => {
        const updated = [...changes];
        updated[index] = rateAndRole;

        setChanges(updated);
    };

    const onAddClick = () => {
        const newRole: RateAndRole = {
            role: '',
            name: '',
            enabled: false,
            rate: 0,
            multiplier: 0
        };
        const updated = [...changes, newRole];
        setChanges(updated);
    };

    return (
        <Container>
            <h3>Roles &amp; Rates</h3>
            <p>Changing these settings will only affect newly created estimates. Prior estimates will not be changed or updated.</p>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell data-testid="header-role">Role</TableCell>
                        <TableCell data-testid="header-name">Name</TableCell>
                        <TableCell data-testid="header-enabled">Enabled</TableCell>
                        <TableCell data-testid="header-rate">Rate</TableCell>
                        <TableCell data-testid="header-multiplier">Multiplier</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        changes.map((x, idx) => (
                            <DefaultRoleEditor
                                key={idx}
                                rateAndRole={x}
                                onRemoveClick={() => onRemoveClick(idx)}
                                onChange={(rateAndRole) => onRowUpdated(idx, rateAndRole)}
                            />
                        ))
                    }
                </TableBody>
            </Table>
            <div className="button-container">
                <Button variant="contained" onClick={onAddClick} disabled={isBusy}>
                    <Icon>add</Icon>
                    Add
                </Button>
                <Button variant="contained" color="primary" onClick={onSaveClick} disabled={isBusy}>Save</Button>
            </div>
        </Container>
    );
};