import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    padding: 10px;
    min-width: 350px;
`;

export interface ISettingsModal {
    open: boolean;
    onClose: () => void;
    title: string;
    children?: React.ReactNode;
}

export const SettingsModal: React.FC<ISettingsModal> = (props: ISettingsModal) => {

    const {open, onClose, title, children} = props;

    return (
        <div>
            <Dialog open={open} maxWidth="lg">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Container>
                        {children}
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
        );
    };
