import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import { StatusType } from '../../modules';

const Container = styled.div`
    /* min-width: 300px; */
    width: 100%;
`;

export interface IStatusSelect {
    selectedStatuses?: StatusType[];
    onChange: (statuses: StatusType[]) => void;
}

export const StatusSelect: React.FC<IStatusSelect> = (props: IStatusSelect) => {
    const {onChange, selectedStatuses} = props;
    
    const [statuses, setStatuses] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (selectedStatuses) {
            setStatuses(selectedStatuses as string[]);
        }
    }, [selectedStatuses])

    const onStatusChange = (event: SelectChangeEvent<string[]>) => {
        const val = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
        setStatuses(val);
        onChange(val as StatusType[]);
    }

    const statusEls = ['DRAFT', 'REVIEW', 'PENDING', 'PUBLISHED', 'CLOSED']
        .map(_status => (
            <MenuItem value={_status} key={_status}>
                <Checkbox checked={statuses.includes(_status)} />
                <ListItemText primary={_status} />
            </MenuItem>
        ));

    return (
        <Container>
            <FormControl variant="standard" style={{width: 'auto', minWidth: '300px'}}>
                <InputLabel id="select-status">Status</InputLabel>
                <Select
                    labelId="select-status"
                    className="field"
                    multiple={true}
                    value={statuses}
                    displayEmpty={true}
                    style={{width: '100%'}}
                    fullWidth={true}
                    renderValue={(selected) => selected.join(', ')}
                    onChange={onStatusChange}
                >
                    {statusEls}
                </Select>
            </FormControl>
        </Container>
    );
};
