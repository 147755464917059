import * as React from 'react';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import {useParams, useNavigate, Params} from 'react-router-dom';
import {Button, Tabs, Tab, Paper, List, ListItem} from '@mui/material';
import {ArrowBack} from '@mui/icons-material';
import {ResizeCallbackData, Resizable} from 'react-resizable';

import {IRootStore} from '../../RootStore';
import {estimationGetAction, summaryEstimateSetAction, notificationsAddAction, defaultTaskSummarySettings, summaryTaskSummarySettingsAction } from '../../modules';
import {TaskSummary, StatusIndicator, RateCard, RateCardControls, CopyImageButton, ExportButton, TaskSummaryControls, ExportCsvButton} from '../ui';

const Container = styled.div`
    background-color: #fff;
    padding: 20px;

    .numeric {
        text-align: right;
    }

    .totals {
        background-color: #eee;

        .numeric, .total {
            font-weight: bold;
        }
    }

    .info-container {
        padding: 10px 40px;
        display: flex;
        width: 70%;
        justify-content: space-between;
    }

    .tab-container {
        padding-top: 35px;
    }

    .rate-card-and-control-container {
        max-width: 100%;
        padding-right: 75px;

        .rate-card-container {
            min-height: 250px;
        }

        .controls {
            margin-top: 30px;
        }
    }

    .task-summary-container {
        /* margin-top: 75px;
        padding-top: 75px;
        padding-right: 75px;
        width: 800px; */

        > DIV {
            display: flex;
            flex-direction: row;
        }
    }

    .task-button-container {
        /* margin-top: 30px;
        display: flex; */
        margin-left: 10px;

        > DIV {
            /* display: inline-block; */
            margin-bottom: 20px;
        }
    }

    .react-resizable {
        position: relative;
    }
    .react-resizable-handle {
        position: absolute;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-origin: content-box;
        box-sizing: border-box;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
        background-position: bottom right;
        background-size: 20px;
        padding: 0 3px 3px 0;
    }
    .react-resizable-handle-sw {
        bottom: 0;
        left: 0;
        cursor: sw-resize;
        transform: rotate(90deg);
    }
    .react-resizable-handle-se {
        bottom: 0;
        right: 0;
        cursor: se-resize;
    }
    .react-resizable-handle-nw {
        top: 0;
        left: 0;
        cursor: nw-resize;
        transform: rotate(180deg);
    }
    .react-resizable-handle-ne {
        top: 0;
        right: 0;
        cursor: ne-resize;
        transform: rotate(270deg);
    }
    .react-resizable-handle-w,
    .react-resizable-handle-e {
        top: 50%;
        margin-top: -10px;
        cursor: ew-resize;
    }
    .react-resizable-handle-w {
        left: 0;
        transform: rotate(135deg);
    }
    .react-resizable-handle-e {
        /* right: 0; */
        right: -22px;
        transform: rotate(315deg);
    }
    .react-resizable-handle-n,
    .react-resizable-handle-s {
        left: 50%;
        margin-left: -10px;
        cursor: ns-resize;
    }
    .react-resizable-handle-n {
        top: 0;
        transform: rotate(225deg);
    }
    .react-resizable-handle-s {
        /* bottom: 0; */
        bottom: -22px;
        transform: rotate(45deg);
    }
`;

export interface IEstimateSummaryPageProps {

}

export const EstimateSummaryPage: React.FC<IEstimateSummaryPageProps> = (props: IEstimateSummaryPageProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [tabValue, setTabValue] = React.useState(0);
    const [rateCardSize, setRateCardSize] = React.useState<{width: number, height: number}>({width: 800, height: 400});
    // const [tasksSize, setTasksSize] = React.useState<{width: number, height: number}>({width: 800, height: 500});

    const params: Readonly<Params<string>> = useParams();
    const {estimate} = useSelector((state: IRootStore) => state.estimation);
    const {taskSummarySettings} = useSelector((state: IRootStore) => state.summary);

    React.useEffect(() => {
        // set the estimate in the store
        // dispatch(summaryEstimateSetAction({estimate}));

        // reset settings for the page
        const settings = defaultTaskSummarySettings();
        dispatch(summaryTaskSummarySettingsAction({settings}));
    }, [estimate, dispatch]);

    React.useEffect(() => {
        // scroll to Top on page load
        window.scrollTo({top: 0, left: 0});
    }, []);

    React.useEffect(() => {
        if (params.id && params.id !== estimate.id) {
            const {id} = params;
            dispatch(estimationGetAction({id}));
        }
    }, [params, dispatch, estimate.id]);

    const onBackClick = () => {
        navigate(`/estimate/${estimate.id}`);
    };

    const onRateCardResize = (e: React.SyntheticEvent, data: ResizeCallbackData) => {
        const {width, height} = data.size;
        setRateCardSize({width, height});
    };

    // const onTasksResise = (e: React.SyntheticEvent, data: ResizeCallbackData) => {
    //     let {width, height} = data.size;

    //     if (height < 200) {
    //         height = 200;
    //     }

    //     if (width < 200) {
    //         width = 200;
    //     }

    //     setTasksSize({width, height});
    // };

    // PRINT: RateCard

    const rateCardRef = React.useRef<HTMLDivElement>(null);

    const PrintRateCard = React.forwardRef<HTMLDivElement>((props, ref) => (
        <div ref={ref}>
            <RateCard />
        </div>
    ));
    
    // PRINT: TaskSummary
    const {height, width} = taskSummarySettings;

    const taskSummaryRef = React.useRef<HTMLDivElement>(null);

    const PrintTaskSummary = React.forwardRef<HTMLDivElement>((props, ref) => (
        <div style={{border: '1px dashed gray', width: `${width}px`}}>
            <div ref={ref} style={{width: `${width}px`, height: `${height}px`, overflowY: 'scroll', overflowX: 'hidden'}}>
                <TaskSummary estimate={estimate} showDescriptions={taskSummarySettings.showDescriptions} valueType={taskSummarySettings.summaryType} showRoleHours={taskSummarySettings.showRoleHours} />
                {/* White space for scroll! */}
                <div style={{width: `${width}px`, height: `${height}px`}} /> 
            </div>
        </div>
    ));

    // const onCopyClick = () => {
    //     const table = taskSummaryRef.current;

    //     if (!table) {
    //         return;
    //     }

    //     const range = document.createRange();

    //     range.selectNode(table);

    //     window.getSelection()?.addRange(range);

    //     document.execCommand('copy');
    // };

    const onCopyTaskSummaryClick = async () => {
        dispatch(notificationsAddAction({store: 'estimate', dismissable: true, message: 'Task Summary copied to clipboard!', severity: 'success', timeout: 5000}));
    };

    const onCopyRateCardClick = async () => {
        dispatch(notificationsAddAction({store: 'estimate', dismissable: true, message: 'Rate Card copied to clipboard!', severity: 'success', timeout: 5000}));
    };

    return (
        <div>
            <Button startIcon={<ArrowBack />} color="primary" onClick={onBackClick}>Back to estimate</Button>
        <Container>
            <Paper elevation={3}>
                <div className="info-container">
                    <div>
                        <h2>{estimate.client}</h2>
                        <h3>{estimate.projectCode}: {estimate.title}</h3>
                    </div>
                    <div>
                        <h4><StatusIndicator status={estimate.status} showText={true} /></h4>
                        {/* <h4>v: {estimate.version}</h4> */}
                    </div>
                    {/* <hr /> */}
                </div>
            </Paper>
            <div className="tab-container">
                <Tabs value={tabValue}>
                    <Tab label="Rate Card" onClick={() => setTabValue(0)} />
                    <Tab label="Task Summary" onClick={() => setTabValue(1)} />
                    <Tab label="Assumptions" onClick={() => setTabValue(2)} />
                    {/* <Tab label="Risks" onClick={() => setTabValue(3)} /> */}
                </Tabs>
            </div>
            {
                tabValue === 0 &&
                <Resizable width={rateCardSize.width} height={rateCardSize.height} onResize={onRateCardResize} resizeHandles={['e']}>
                    <div className="rate-card-and-control-container tab-container" style={{width: `${rateCardSize.width}px`, minHeight: `${rateCardSize.height}px`, borderRight: '1px solid #eee'}}>
                        {/* <h3>Rate Card</h3> */}
                            <div className="rate-card-container">
                                <PrintRateCard ref={rateCardRef} />
                            </div>
                            <div className="controls">
                                <RateCardControls onCopyImageClick={onCopyRateCardClick} rateCardRef={rateCardRef} />
                            </div>
                    </div>
                </Resizable>
            }
            {/* <Resizable width={tasksSize.width} height={tasksSize.height} onResize={onTasksResise} resizeHandles={['e', 's']}> */}
                {/* <div className="task-summary-container" style={{width: `${tasksSize.width}px`, height: `${tasksSize.height + 176}px`, borderRight: '1px solid #eee', borderBottom: '1px solid #eee'}}> */}
            {
                tabValue === 1 &&
                <div className="task-summary-container tab-container">
                    {/* <h3>Task Summary</h3> */}
                    <div>
                        <div>
                            <PrintTaskSummary ref={taskSummaryRef} />
                        </div>
                        <div>
                            <TaskSummaryControls />
                            <div className="task-button-container">
                                <div>
                                    <CopyImageButton onClick={onCopyTaskSummaryClick} elementRef={taskSummaryRef} />
                                </div>
                                <div>
                                    <ExportButton elementRef={taskSummaryRef} filename={`${estimate.id}_task_summary`} />
                                </div>
                                <div>
                                    <ExportCsvButton />
                                </div>
                                {/* <div>
                                    <Button variant='outlined' onClick={onCopyClick}>Copy HTML</Button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                }
                {/* </div> */}
                {/* </Resizable> */}
                {
                    tabValue === 2 &&
                    <div className="assumptions-container tab-container">
                        <List>
                            {/* {
                                estimate.assumptions.map((x) => (
                                    <ListItem>{x.assumption}</ListItem>
                                ))
                            } */}
                        </List>
                    </div>
                }
        </Container>
        </div>
    );
};
