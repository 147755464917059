import * as React from 'react';
import styled from 'styled-components';
import {Dialog, DialogContent, DialogActions, DialogTitle, Button, Select, MenuItem, SelectChangeEvent, InputLabel, FormControl, Alert} from '@mui/material';
import { useSelector } from 'react-redux';
import { IRootStore } from '../../RootStore';

const Container = styled.div`
    .text-container {
        margin-bottom: 30px;
    }

`;

export interface IChangePrimaryRoleModal {
    open: boolean;
    onCancel: () => void;
    onSave: (role: string) => void;
}

export const ChangePrimaryRoleModal: React.FC<IChangePrimaryRoleModal> = (props: IChangePrimaryRoleModal) => {
    const {open, onCancel, onSave} = props;

    const [selectedRole, setSelectedRole] = React.useState('');
    const [currentPrimaryRole, setCurrentPrimaryRole] = React.useState('');

    const {settings: {roles}} = useSelector((state: IRootStore) => state.version.version);

    const roleEls = roles.map((r, index) => <MenuItem value={r.role} key={index}>{r.name}</MenuItem>);
    
    React.useEffect(() => {
        const [primaryRole = {role: ''}] = roles.filter((x) => x.isPrimary);
        setSelectedRole(primaryRole.role);
        setCurrentPrimaryRole(primaryRole.role);
    }, [roles]);


    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedRole(event.target.value);
    };

    const handleChangeRole = () => {
        onSave(selectedRole);
    };

    return (
        <div>
            <Dialog open={open}>
                <DialogTitle>Change Primary Role</DialogTitle>
                <DialogContent>
                    <Container>
                        <div className="text-container">
                            <Alert severity="warning">Changing the primary role will update all tasks!</Alert>
                        </div>
                        <p className="text-container">The Primary Role is used as the basis for all calculated hours on tasks.</p>
                        <FormControl fullWidth={true}>
                            <InputLabel id="primary-role-select-label">Primary Role</InputLabel>
                            <Select
                                labelId="primary-role-select-label"
                                value={selectedRole}
                                label="Primary Role"
                                fullWidth={true}
                                onChange={handleChange}
                            >
                                {roleEls}
                            </Select>
                        </FormControl>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={onCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" disabled={(selectedRole === currentPrimaryRole)} onClick={handleChangeRole}>Change Role</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
