import * as React from 'react';
import styled from 'styled-components';
import {Button, IconButton, TextareaAutosize, Table, TableBody, TableHead, TableRow, TableCell, TextField} from '@mui/material';
import {DeleteForever, Edit, Add} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import { assumptionsCreateAction, assumptionsDeleteAction, assumptionsListAction, assumptionsUpdateAction, IAssumption } from '../../../modules';
import { IRootStore } from '../../../RootStore';

const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
const Container = styled.div`

    .item {
        padding: 10px;

        &:hover {
            background-color: #eee;
        }

        .input-container {
            width: 90%;
        }

        .item-text {
            display: inline-block;
            margin: 5px 0;
        }

        .audit {
            font-size: .65rem;
        }
    }

    .button-container {
        margin-top: 10px;

    }

    .action-button-cell {
        BUTTON {
            margin-right: 5px;
        }
    }
`;

export interface IAssumptionsEditor {
}

export const AssumptionsEditor: React.FC<IAssumptionsEditor> = (props: IAssumptionsEditor) => {
    const dispatch = useDispatch();

    const [editingAssumption, setEditingAssumption] = React.useState<IAssumption|null>(null);
    const [editingIndex, setEditingIndex] = React.useState<number|null>(null);

    const { estimate } = useSelector((state: IRootStore) => state.estimation);
    const { assumptions } = useSelector((state: IRootStore) => state.assumptions);

    const canEdit = !['PUBLISHED', 'PENDING', 'CLOSED'].includes(estimate.status);

    React.useEffect(() => {
        dispatch(assumptionsListAction({estimateId: estimate.id}));
    }, [dispatch, estimate.id]);

    const onAddClick = () => {
        const assumption = {assumption: '', createDate: new Date()} as IAssumption;

        setEditingIndex(-1);
        setEditingAssumption(assumption);
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, index: number) => {

        let isChanged = false;

        if (index !== -1) {
            const assumption = assumptions[index];
            if (assumption.assumption !== '' && assumption.assumption !== editingAssumption?.assumption) {
                isChanged = true;
            }
        } else if (index === -1) {
            if (editingAssumption?.assumption !== '') {
                isChanged = true;
            }
        }

        if (isChanged) {
            if (editingAssumption?.id) {
                dispatch(assumptionsUpdateAction({assumption: {...editingAssumption} }));
            } else {
                const {assumption} = editingAssumption as IAssumption;
                dispatch(assumptionsCreateAction({assumption}));
            }
        }

        setEditingAssumption(null);
        setEditingIndex(null);
    }

    const onDeleteClick = (index: number) => {

        const assumption = assumptions[index];
        const {id} = assumption;

        if (id) {
            dispatch(assumptionsDeleteAction({id}));
            setEditingIndex(null);
            setEditingAssumption(null);
        }
    };

    const onEditClick = (index: number) => {
        setEditingIndex(index);
        setEditingAssumption(assumptions[index]);
    };

    // const onFocus = (event: React.FocusEvent) => {
    //     (event.currentTarget as HTMLInputElement).select();
    // };

    const tableEls = assumptions.map((a: IAssumption, index: number) => (
        <TableRow key={index} className="item">
            <TableCell style={{maxWidth: '400px'}}>
                <div className="input-container">
                    {
                        editingIndex === index &&
                        <TextField
                            minRows={5}
                            maxRows={10}
                            multiline={true}
                            value={editingAssumption?.assumption || ''}
                            // style={{width: 600 }}
                            style={{width: '100%' }}
                            onBlur={(e) => onBlur(e, index)}
                            onChange={(e) => setEditingAssumption({...a, assumption: e.target.value})}
                            autoFocus={true}
                        />
                    }
                    {editingIndex !== index && <span className="item-text">{a.assumption}</span>}
                </div>
            </TableCell>
            <TableCell>{a.createUser?.name ? `${a.createUser?.name}` : ''}</TableCell>
            <TableCell>{new Intl.DateTimeFormat('en-US', options).format(new Date(a.createDate))}</TableCell>
            <TableCell className="action-button-cell">
                {
                    canEdit &&
                    <>
                        <IconButton edge="end" aria-label="edit" onClick={() => onEditClick(index)}>
                            <Edit />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => onDeleteClick(index)}>
                            <DeleteForever />
                        </IconButton>
                    </>
                }
            </TableCell>
        </TableRow>
    ));

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Assumption</TableCell>
                        <TableCell>Created By</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableEls}
                    {
                        editingIndex === -1 && editingAssumption &&
                        <TableRow>
                            <TableCell colSpan={4}>
                                <TextField
                                    minRows={5}
                                    multiline={true}
                                    value={editingAssumption.assumption}
                                    // style={{width: 600 }}
                                    style={{width: '100%'}}
                                    onBlur={(e) => onBlur(e, editingIndex)}
                                    onChange={(e) => setEditingAssumption({...editingAssumption, assumption: e.target.value})}
                                    autoFocus={true}
                                />
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            <div className="button-container">
                {
                    canEdit &&
                    <Button variant="contained" color="primary" onClick={onAddClick}>
                        <Add />
                        <span>Add</span>
                    </Button>
                }
            </div>
        </Container>
    );
};
