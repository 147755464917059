import * as React from 'react';
import {Table, TableBody, TableHead, TableRow, TableCell, Icon} from '@mui/material';
import styled from 'styled-components';

import {IUser} from '../../../modules';
import {UserActions} from './UserActions';

const Container = styled.div`
    width: 100%;

    TABLE {
        width: 100%;
    }

    .inactive {
        background-color: #eee;
    }

`;

export interface IUserListProps {
    users: IUser[];
    onlyActiveUsers: boolean;
    onEditUser: (user: IUser) => void;
    onChangePassword: (user: IUser) => void;
}

export const UserList: React.FC<IUserListProps> = (props: IUserListProps) => {

    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

    const {users = []} = props;
    const userEls = users
        .filter(x => (props.onlyActiveUsers && x.isActive) || (!props.onlyActiveUsers))
        .map((x, index) => (
            <TableRow key={index} className={`${!x.isActive ? 'inactive' : ''}`}>
                <TableCell>{x.name}</TableCell>
                <TableCell>{x.email}</TableCell>
                <TableCell>
                    {x.isActive && <Icon color="primary">check_circle</Icon> }
                </TableCell>
                <TableCell>
                    {x.isAdmin && <Icon color="secondary">vpn_key</Icon> }
                </TableCell>
                <TableCell>{new Intl.DateTimeFormat('en-US', options).format(new Date(x.createDate))}</TableCell>
                <TableCell>{new Intl.DateTimeFormat('en-US', options).format(new Date(x.updateDate))}</TableCell>
                <TableCell>
                    <UserActions user={x} onEditUser={props.onEditUser} onChangePassword={props.onChangePassword} />
                </TableCell>
            </TableRow>
        ));

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Updated</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userEls}
                </TableBody>
            </Table>
        </Container>
    );
};
