import {call, put, takeEvery} from 'redux-saga/effects';
import * as Store from './ConfigStore';
import * as Service from './Service';

import {notificationsAddAction} from '../notification';

export function* getConfigSaga(action: Store.GetConfigAction) {
    try {
        const {context} = action.payload;
        const result: Array<any> = yield call(Service.getConfig, context);

        yield put(Store.getConfigSuccessAction({context, content: result}));

    } catch (e) {
        console.error(e);
        const message = `Could not get configuration item: ${e}`;
        yield put(Store.getConfigErrorAction({message}));
        yield put(notificationsAddAction({store: 'config', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* updateConfigSaga(action: Store.UpdateConfigAction) {
    try {
        const {context, content} = action.payload;
        const result: Array<any> = yield call(Service.updateConfig, context, content);

        yield put(Store.updateConfigSuccessAction({context, content: result}));

        yield put(notificationsAddAction({store: 'config', message: `Configuration updated!`, severity: 'success', dismissable: true, timeout: 10000}));

    } catch (e) {
        console.error(e);
        const message = `Could not update configuration item: ${e}`;
        yield put(Store.updateConfigErrorAction({message}));
        yield put(notificationsAddAction({store: 'config', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}


export function* configSagas() {
    yield takeEvery(Store.CONFIG_GET, getConfigSaga);
    yield takeEvery(Store.CONFIG_UPDATE, updateConfigSaga);
}