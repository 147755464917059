import { Breadcrumbs, Link, LinkProps, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { IRootStore } from '../../RootStore';

const Container = styled.div`
    padding-top: 15px;
    padding-left: 30px;
`;

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
    return <Link {...props} component={RouterLink as any} />;
}

export interface IBreadCrumbs {
}

export const BreadCrumbs: React.FC<IBreadCrumbs> = (props: IBreadCrumbs) => {
    const location = useLocation();
    const {estimate} = useSelector((state: IRootStore) => state.estimation);
    const {version} = useSelector((state: IRootStore) => state.version);

    // const getEstimateRouteCrumbs = () => {
    //     const {pathname} = location;

    //     const path = pathname.split('/').slice(-1).join('/');
    //     console.log(pathname, path);

    //     if (pathname.includes('/review')) {
    //         return [
    //             <LinkRouter underline="hover" color="inherit" to={path} key="1">
    //                 {pathname.split('/')[2]}
    //             </LinkRouter>,
    //             <Typography color="text.primary" key={''}>Sales Summary</Typography>
    //         ];
    //     } else {
    //         return [
    //             <Typography color="text.primary" key={''}>{pathname.split('/')[2]}</Typography>
    //         ];
    //     }
    // }

    // const getCrumbs = () => {
    //     return pathnames.map((value, index) => {
    //         const last = index === pathnames.length - 1;
    //         const to = `/${pathnames.slice(0, index + 1).join('/')}`;

    //         const label = (!to.includes('/estimate/'))
    //             ? breadcrumbNameMap[to]
    //             : (last)
    //                 ? breadcrumbNameMap[value] || value
    //                 : value;

    //         return last ? (
    //         <Typography color="text.primary" key={to}>
    //             {label}
    //         </Typography>
    //         ) : (
    //         <LinkRouter underline="hover" color="inherit" to={to} key={to}>
    //             {label}
    //         </LinkRouter>
    //         );
    //     })
    // }
    
    // const pathnames = location.pathname.split('/').filter((x) => x);

    // const isEstimateRoute = location.pathname.includes('/estimate/');
    // console.log(isEstimateRoute);

    // const crumbEls = isEstimateRoute
    //     ? getEstimateRouteCrumbs()
    //     : getCrumbs();

    const getCrumbs = () => {

        const {pathname} = location;

        if (pathname === '/estimates') {
            return [
                {text: 'Search', link: '/estimates'}
            ];
        } else if (pathname === '/reporting') {
            return [
                {text: 'Reports', link: '/reporting'}
            ];
        } else if (pathname === '/admin') {
            return [
                {text: 'Admin', link: '/admin'}
            ];
        } else if (pathname.includes('/estimate/')) {

            const parts = pathname.split('/');

            const _crumbs = [
                // {text: parts[2], link: `/estimate/${parts[2]}`},
                {text: `${estimate.client} - ${estimate.projectCode}: ${estimate.title}`, link: `/estimate/${parts[2]}`},
                // {text: `v${version.version}`, link: `/estimate/${parts[2]}`},
            ];

            if (pathname.includes('/review')) {
                _crumbs.push({text: 'Sales Summary', link: `/estimate/${parts[2]}/review`});
            }

            return _crumbs;
        } else {
            return [];
        }
    };

    const crumbEls = getCrumbs().map((_crumb, index, arr) => {
        const last = index === arr.length - 1;

        return last ? (
            <Typography color="text.primary" key={_crumb.link}>
                {_crumb.text}
            </Typography>
            ) : (
            <LinkRouter underline="hover" color="inherit" to={_crumb.link} key={_crumb.link}>
                {_crumb.text}
            </LinkRouter>
        );
    });

    return (
        <Container>
            <Breadcrumbs>
                <Link underline="hover" color="inherit" href="#/estimates">
                    SWAG
                </Link>
                {
                    crumbEls
                }
            </Breadcrumbs>
        </Container>
    );
};
