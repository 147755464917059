import {ISection, IRoleSetting, ITask, IEstimate, EstimateModeType, TaskSummaryLine, RoleHourSummaryType, IRangeSetting, IVersion} from './Models';
import {IUser} from '../user';
import { DateTime } from 'luxon';

export const generateBlankEstimate = (): IEstimate => {
    const blankEstimate: IEstimate = {
        id: '',
        client: '',
        title: '',
        projectCode: '',
        status: 'DRAFT',
        version: '123948723',
        description: '',
        approvals: [],
        resources: [],
        publishedDate: undefined,
        createUser: {} as IUser,
        createDate: new Date(),
        updateUser: {} as IUser,
        updateDate: new Date(),
    };

    return blankEstimate;
};

type SummaryType = 'high' | 'mid' | 'low';

const createEmptySummary = () => ({
    hours: 0,
    amount: 0,
    high: {
        hours: 0,
        amount: 0,
    },
    low: {
        hours: 0,
        amount: 0,
    }
});

/**
 * Produces the calculated lines for the Task Summary. Will calculate the correct hours for a given summary type
 * @param version 
 * @param summaryType 
 * @returns 
 */
export const calculateTaskSummaryLines = (version: IVersion, summaryType: SummaryType): TaskSummaryLine[] => {
    const { ranges, roles } = version.settings;

    const lines = version.sections.map(s => {

        const summary = calculateSectionSummary(s, roles, ranges);
        const hours = summaryType === 'mid' ? summary.Total.hours : summary.Total[summaryType].hours;

        const roleHours = Object.entries(summary)
            .filter(([key, ]) => key !== 'Total')
            .reduce((acc, [key, _summary]) => {
                const value = summaryType === 'mid' ? _summary.hours : _summary[summaryType].hours
                return {...acc, [key]: value};
            }, {});

        const line = {
            name: s.name,
            description: '',
            hours,
            roleHours,
            section: true,
        };

        const taskLines = s.tasks
            .filter(x => x.isVisible)
            .map(t => {
                const summary = calculateTaskSummary(t, roles, ranges);
                
                const hours = summaryType === 'mid' ? summary.Total.hours : summary.Total[summaryType].hours;

                const roleHours = Object.entries(summary)
                    .filter(([key, ]) => key !== 'Total')
                    .reduce((acc, [key, _summary]) => {
                        const value = summaryType === 'mid' ? _summary.hours : _summary[summaryType].hours
                        return {...acc, [key]: value};
                    }, {});

            return {
                name: t.name,
                description: t.description,
                hours,
                roleHours,
            };
        });

        return [line, ...taskLines];
    });

    return lines.flat();
};

/**
 * Produces a summary object for a given section. Will (re)calculate all tasks within the section.
 * @param section 
 * @param roleSettings 
 * @param ranges 
 * @returns 
 */
export const calculateSectionSummary = (section: ISection, roleSettings: IRoleSetting[], ranges: IRangeSetting): RoleHourSummaryType => {

    const summary = section.tasks
        .filter(task => task.isVisible)
        .map(task => calculateTaskSummary(task, roleSettings, ranges))
        .flatMap(summary => Object.entries(summary))
        .reduce((acc: RoleHourSummaryType, [key, summaryObj]) => {

            const entry = acc[key] ? {...acc[key]} : createEmptySummary();

            entry.hours += summaryObj.hours;
            entry.amount += summaryObj.amount;
            entry.low.hours += summaryObj.low.hours;
            entry.low.amount += summaryObj.low.amount;
            entry.high.hours += summaryObj.high.hours;
            entry.high.amount += summaryObj.high.amount;

            return {...acc, [key]: entry};

        }, { Total: createEmptySummary() });

    return summary;
};

/**
 * Produces the summary object for a given task.
 * Used as a base for all other calculations. AKA, we do bottom up calculations. Calculate the task(s) and role up.
 * @param task 
 * @param roleSettings 
 * @param ranges 
 * @returns 
 */
export const calculateTaskSummary = (task: ITask, roleSettings: IRoleSetting[], ranges: IRangeSetting): RoleHourSummaryType => {

    const summary = roleSettings
        .filter(x => x.enabled)
        .map(x => {
            // pull all the data we need for the calculation
            const {role, rate} = x;
            const overrides = task.overrides || {};
            const hours = (task.isOverride)
                ? (overrides![x.role] || 0) // user the override value
                : Math.round((task.hours || 0) * x.multiplier); // calculate the hours for the role
            const amount = x.rate * hours;
            
            return {role, rate, hours, amount};
        })
        .reduce((acc, cur) => {
            const {role, rate, hours, amount} = cur;

            // Create the summary object for the task
            const hoursLow = Math.ceil(hours * ranges.low);
            const hoursHigh = Math.ceil(hours * ranges.high);
            const amountLow = Math.ceil(hoursLow * rate);
            const amountHigh = Math.ceil(hoursHigh * rate);

            const roleSummary = {
                hours: hours,
                amount: amount,
                low: {
                    hours: hoursLow,
                    amount: amountLow
                },
                high: {
                    hours: hoursHigh,
                    amount: amountHigh
                }
            };

            // Update the totals
            const {Total} = acc;
            Total.hours += hours;
            Total.amount += amount;
            Total.low.hours += hoursLow;
            Total.low.amount += amountLow;
            Total.high.hours += hoursHigh;
            Total.high.amount += amountHigh;

            return {...acc, [role]: roleSummary, Total};

        }, { Total: {...createEmptySummary()} });


        return summary;
};

/**
 * Produces a rate card for the entire estimate. This will also calculate the summary for an estimate.
 * @param version 
 * @returns 
 */
export const calculateRateCard = (version: IVersion): RoleHourSummaryType => {

    const { settings:  { ranges = {high: 0, low: 0}, roles = [] } } = version;

    const rateCard = version.sections
        .flatMap(section => section.tasks)
        .filter(task => task.isVisible)
        .map(task => calculateTaskSummary(task, roles, ranges))
        .flatMap(summary => Object.entries(summary))
        .reduce((acc: RoleHourSummaryType, [key, summary]) => {

            const entry = acc[key] ? {...acc[key]} : createEmptySummary();

            entry.hours += summary.hours;
            entry.amount += summary.amount;
            entry.low.hours += summary.low.hours;
            entry.low.amount += summary.low.amount;
            entry.high.hours += summary.high.hours;
            entry.high.amount += summary.high.amount;

            return {...acc, [key]: entry};

        }, { Total: createEmptySummary() });

    return rateCard;

};

/**
 * Recalculate all tasks and summary when the mode changes.
 * @param version 
 * @param mode 
 * @returns 
 */
export const recalculateEstimateOnModeChange = (version: IVersion, mode: EstimateModeType): IVersion => {
    const { settings: { roles, ranges }, sections } = version;

    const primaryRole = roles.find(x => x.isPrimary);

    const newSections = sections.map(s => {
        const tasks = [...s.tasks]
        .map(t => {
            const task = {...t};

            if (mode === 'MANUAL') {
                if (task.isOverride) {
                    return task;
                }

                // move the task hours into the overrides
                const hours = task.hours;
                task.overrides={[primaryRole!.role]: hours};
                task.isOverride = true;
    
                task.hours = 0;
            } else {

                // add up the total hours from the override and set it as the task hours
                task.hours = Object.entries(task.overrides || {})
                    .map(([, hours]) => hours || 0)
                    .reduce((agg, curr) => agg + curr, 0);
    
                task.overrides = {};
                task.isOverride = false;
            }
    
            task.summary = calculateTaskSummary(task, roles, ranges);
    
            return task;
        });

        return {...s, tasks};
    });


    return {
        ...version, 
        sections: newSections,
        settings: {...version.settings, mode}
    };
};

/**
 * Creates the summary object for a Version
 * @param version 
 * @returns 
 */
export const calculateVersionSummary = (version: IVersion): {hours: number, amount: number} => {
    const rateCard = calculateRateCard(version);
    return {
        hours: rateCard.Total.hours,
        amount: rateCard.Total.amount
    };
};

// export const calculateBurndownLine = (startDate: Date, hours: number, decrementVal: number): {x: string | null, y: number}[] => {
    
//     let date = DateTime.fromJSDate(startDate);
//     let _hours = hours;
    
//     const result: {x: string | null, y: number}[] = [{x: date.toSQLDate(), y: hours}];

//     if (hours <= 0 || decrementVal <= 0) {
//         console.warn('Invalid values for burndown');
//         return result;
//     }

//     while (_hours > 0) {

//         date = date.plus({days: 1});

//         // check for weekends
//         // monday = 1, ... saturday = 6, sunday = 7
//         if (date.weekday < 6) { 
//             _hours -= decrementVal;

//             const val = {
//                 x: date.toSQLDate(), 
//                 y: _hours > 0 ? _hours : 0
//             };
    
//             result.push(val);
//         }
//     }

//     return result;
// };

export type BurndownInput = {
    startDate: Date;
    endDate?: Date;
    // hours: number;
    decrementVal: number;
}

export const calculateBurndownLine = (inputs: BurndownInput[], hours: number): {x: string | null, y: number}[] => {

    const startDates: {[key: string]: number} = {};
    const endDates: {[key: string]: number} = {};

    // identify the dates for resources
    
    inputs.forEach(i => {
        const date = DateTime.fromJSDate(i.startDate).toSQLDate();

        if (!date) {
            return;
        }

        if (!startDates[date]) {
            startDates[date] = 0;
        }
        
        startDates[date] += i.decrementVal;
    });

    inputs.forEach(i => {
        if (!i.endDate) {
            return;
        }
        const date = DateTime.fromJSDate(i.endDate).toSQLDate();

        if (!date) {
            return;
        }

        if (!endDates[date]) {
            endDates[date] = 0;
        }
        
        endDates[date] -= i.decrementVal;
    });

    /////

    // figure out what to start the decrementVal at
    const _startDate = inputs.map(i => i.startDate).sort((a, b) => {
        if (a > b) return 1;
        else if (a < b) return -1;
        else return 0;
    }).shift();
    const startDate = DateTime.fromJSDate(_startDate!).toSQLDate() ;
    let decrementVal = startDates[startDate!];
    
    // identify the start date
    let date = DateTime.fromJSDate(_startDate!);
    let _hours = hours;
    
    const result: {x: string | null, y: number}[] = [{x: date.toSQLDate(), y: hours}];

    if (hours <= 0 || decrementVal <= 0) {
        console.warn('Invalid values for burndown');
        return result;
    }

    while (_hours > 0 && decrementVal > 0) {

        date = date.plus({days: 1});

        if (startDates[date.toSQLDate()!]) {
            decrementVal += startDates[date.toSQLDate()!];
        }

        if (endDates[date.toSQLDate()!]) {
            // numbers should be stored as negative, so we will add instead of subtract
            decrementVal += endDates[date.toSQLDate()!];
        }

        // check for weekends
        // monday = 1, ... saturday = 6, sunday = 7
        if (date.weekday < 6) { 

            _hours -= decrementVal;

            const val = {
                x: date.toSQLDate(), 
                y: _hours > 0 ? _hours : 0
            };
    
            result.push(val);
        }
    }

    return result;
};