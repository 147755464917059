import * as React from 'react';
import styled from 'styled-components';
import {Dialog, DialogContent, DialogActions, DialogTitle, Button} from '@mui/material';

const Container = styled.div`
`;

export interface IConfirmPublishModalProps {
    isOpen: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

export const ConfirmPublishModal: React.FC<IConfirmPublishModalProps> = (props: IConfirmPublishModalProps) => {
    return (
        <Container>
            <Dialog open={props.isOpen}>
                <DialogTitle>Confirm Publish</DialogTitle>
                <DialogContent>
                    <p>
                        Are you sure you want to publish this estimate?
                        <br/>
                        Once published, this estimate cannot be modified!
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={props.onCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={() => props.onConfirm()}>Publish</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
