import {takeEvery, put, call, takeLatest} from 'redux-saga/effects';
import * as Store from './UserStore';
import * as Service from './Service';
import {IUser} from './Models';

import {notificationsAddAction} from '../notification';

export function* getUsersSaga() {
    try {
    const result: IUser[] = yield call(Service.getAllUsers);
    yield put(Store.userListSuccessAction({userList: result}));

    } catch (e: any) {
        console.error(e);
        yield put(Store.userErrorAction(e.message));
        yield put(notificationsAddAction({store: 'user', message: e.message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* saveUserSaga(action: Store.UserUpdateAction) {
    const {user} = action.payload;

    try {
        if (user.id) {
            yield call(Service.updateUser, user);
        } else {
            yield call(Service.createUser, user);
        }

        yield put(Store.userUpdateSuccessAction());

        yield put(notificationsAddAction({store: 'user', message: `User saved!`, severity: 'success', dismissable: true, timeout: 5000}));

        yield getUsersSaga();
    
        } catch (e: any) {
            console.error(e);
            const message = e as string;
            yield put(Store.userErrorAction({message}));
            yield put(notificationsAddAction({store: 'user', message: e.message, severity: 'error', dismissable: true, timeout: undefined}));
        }

}

export function* changePwdSaga(action: Store.UserChangePasswordAction) {
    const {user, password, isAdmin} = action.payload;

    try {
        yield call(Service.changePassword, user, password, isAdmin);
        yield put(Store.userChangePasswordSuccessAction());
        yield put(notificationsAddAction({store: 'config', message: `Password changed successfully!`, severity: 'success', dismissable: true, timeout: 10000}));
    } catch (e: any) {
        console.error(e);
        const message = e as string;
        yield put(Store.userErrorAction({message}));
        yield put(notificationsAddAction({store: 'user', message: e.message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* userSagas() {
    yield takeEvery(Store.USER_LIST, getUsersSaga);
    yield takeLatest(Store.USER_UPDATE, saveUserSaga);
    yield takeLatest(Store.USER_CHANGE_PWD, changePwdSaga);
}
