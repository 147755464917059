import {RequestBuilder} from '../';

const HOST = `${process.env.REACT_APP_BACKEND_HOST}`;

export async function login(email: string, password: string) {

    const url = `${HOST}/auth/login`;

    return await new RequestBuilder(url)
        .setMethod('POST')
        .addHeader('Content-Type', 'application/json')
        .setBody({email, password})
        .build();
};

export async function getCurrentUser() {
    const url = `${HOST}/users/me`;

    return await new RequestBuilder(url)
        .setMethod('GET')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}
