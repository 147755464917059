import * as React from 'react';
// import styled from 'styled-components';
import {TableRow, TableCell, Button, Icon, TextField, Switch, InputAdornment} from '@mui/material';

import {RateAndRole} from '../../../modules';


// const Container = styled.div`
// `;

export interface IDefaultRoleEditor {
    rateAndRole: RateAndRole;
    onChange: (rateAndRole: RateAndRole) => void;
    onRemoveClick: () => void;
}

export const DefaultRoleEditor: React.FC<IDefaultRoleEditor> = (props: IDefaultRoleEditor) => {
    const {rateAndRole} = props;

    React.useEffect(() => {
        const {role, name, enabled, rate, multiplier} = rateAndRole;
        setRole(role);
        setName(name);
        setEnabled(enabled);
        setRate(rate);
        setMultiplier(multiplier);

    }, [rateAndRole]);

    const [role, setRole] = React.useState(rateAndRole.role);
    const [name, setName] = React.useState(rateAndRole.name);
    const [enabled, setEnabled] = React.useState(rateAndRole.enabled || false);
    const [rate, setRate] = React.useState(rateAndRole.rate);
    const [multiplier, setMultiplier] = React.useState(rateAndRole.multiplier);

    const onRoleChange = (_role: string) => {
        setRole(_role);
        const newRateAndRole: RateAndRole = {role: _role, name, enabled, rate, multiplier};
        props.onChange(newRateAndRole);
    };

    const onNameChange = (_name: string) => {
        setName(_name);
        const newRateAndRole: RateAndRole = {role, name: _name, enabled, rate, multiplier};
        props.onChange(newRateAndRole);
    };

    const onEnabledChange = (_enabled: boolean) => {
        setEnabled(_enabled);
        const newRateAndRole: RateAndRole = {role, name, enabled: _enabled, rate, multiplier};
        props.onChange(newRateAndRole);
    };

    const onRateChange = (_rate: string) => {
        const newRate = parseInt(_rate, 10);
        setRate(newRate);
        const newRateAndRole: RateAndRole = {role, name, enabled, rate: newRate, multiplier};
        props.onChange(newRateAndRole);
    };

    const onMultiplierChange = (_multiplier: string) => {
        const newMultiplier = parseFloat(_multiplier);
        setMultiplier(newMultiplier);
        const newRateAndRole: RateAndRole = {role, name, enabled, rate, multiplier: newMultiplier};
        props.onChange(newRateAndRole);
    };


    return (
        <TableRow>
            <TableCell>
                {
                    rateAndRole.role !== 'DEV' &&
                    <Button onClick={() => props.onRemoveClick()} data-testid="remove-btn" tabIndex={-1}>
                        <Icon color="secondary">close</Icon>
                    </Button>
                }
            </TableCell>
            <TableCell>
                <TextField
                    type="text"
                    value={role}
                    variant="standard"
                    size="small"
                    label="Role"
                    inputProps={{'data-testid': `role-${rateAndRole.role}`}}
                    disabled={rateAndRole.role === 'DEV'}
                    onChange={(event) => onRoleChange(event.target.value) }
                />
            </TableCell>
            <TableCell>
                <TextField
                    type="text"
                    value={name}
                    variant="standard"
                    size="small"
                    label="Name"
                    inputProps={{'data-testid': `name-${rateAndRole.role}`}}
                    style={{minWidth: '250px'}}
                    onChange={(event) => onNameChange(event.target.value) }
                />
            </TableCell>
            <TableCell>
                {
                    rateAndRole.role !== 'DEV' &&
                    <Switch inputProps={{'role': `enabled-${rateAndRole.role}`}} checked={enabled} color="primary" onChange={(event, checked) => onEnabledChange(checked)} />
                }
            </TableCell>
            <TableCell>
                <TextField
                    type="number"
                    value={rate}
                    label="Rate"
                    variant="standard"
                    size="small"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$/hr</InputAdornment>,
                    }}
                    inputProps={{
                        // startAdornment: <InputAdornment position="start">$/hr</InputAdornment>,
                        min: 0, 
                        step: 1, 
                        style: { textAlign: 'right'}, 
                        'data-testid': `rate-${rateAndRole.role}`
                    }}
                    onChange={(event) => onRateChange(event.target.value) }
                />
            </TableCell>
            <TableCell>
                <TextField
                    type="number"
                    value={multiplier}
                    label="Multiplier"
                    variant="standard"
                    size="small"
                    inputProps={{min: 0, step: '0.05', style: { textAlign: 'right' }, 'data-testid': `multiplier-${rateAndRole.role}`}}
                    onChange={(event) => onMultiplierChange(event.target.value)}
                />
            </TableCell>
        </TableRow>
    );
};
