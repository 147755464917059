import { createAction, createReducer } from '@reduxjs/toolkit';
import { IEstimate, ITaskSummary, IVersion, TaskSummaryLine, TaskSummarySettings } from './Models';
import {generateBlankEstimate, calculateRateCard, calculateTaskSummaryLines } from './Helper';

type DataPositionType = 'left' | 'center' | 'right';

export const defaultTaskSummarySettings = (): TaskSummarySettings => ({
    showDescriptions: false,
    summaryType: 'high',
    showRoleHours: false,
    width: 670,
    height: 900,
});

//
// CONSTANTS
//
export const SUMMARY_ESTIMATE_SET = '[Summary] Estimate Set';
export const SUMMARY_ENABLE_ROLE = '[Summary] Enable Role';
export const SUMMARY_DISABLE_ROLE = '[Summary] Disable Role';
export const SUMMARY_POSITION_SET = '[Summary] Position Set';
export const SUMMARY_TOGGLE_COLUMN = '[Summary] Toggle Column';
export const SUMMARY_TASK_SUMMARY_SETTINGS = '[Summary] Task Summary Settings';
export const SUMMARY_EXPORT_CSV = '[Summary] Export CSV';

//
// ACTIONS
//

export const summaryEstimateSetAction = createAction<{version: IVersion}>(SUMMARY_ESTIMATE_SET);
export type SummaryEstimateSetAction = ReturnType<typeof summaryEstimateSetAction>;

export const summaryEnableRoleAction = createAction<{role: string}>(SUMMARY_ENABLE_ROLE);
export type SummaryEnableRoleAction = ReturnType<typeof summaryEnableRoleAction>;

export const summaryDisableRoleAction = createAction<{role: string}>(SUMMARY_DISABLE_ROLE);
export type SummaryDisableRoleAction = ReturnType<typeof summaryDisableRoleAction>;

export const summaryDataPositionAction = createAction<{dataPosition: DataPositionType}>(SUMMARY_POSITION_SET);
export type SummaryDataPositionAction = ReturnType<typeof summaryDataPositionAction>;

export const summaryToggleColumnAction = createAction<{column: 'low'|'mid'|'high'}>(SUMMARY_TOGGLE_COLUMN);
export type SummaryToggleColumnAction = ReturnType<typeof summaryToggleColumnAction>;

export const summaryTaskSummarySettingsAction = createAction<{settings: TaskSummarySettings}>(SUMMARY_TASK_SUMMARY_SETTINGS);
export type SummaryTaskSummarySettingsAction = ReturnType<typeof summaryTaskSummarySettingsAction>;

export const summaryExportCsvAction = createAction<{id: string, versionId: string, summaryType: 'low'|'mid'|'high'}>(SUMMARY_EXPORT_CSV);
export type SummaryExportCSVAction = ReturnType<typeof summaryExportCsvAction>;

export type SummaryActions = 
    SummaryEstimateSetAction
    | SummaryEnableRoleAction
    | SummaryDisableRoleAction
    | SummaryDataPositionAction
    | SummaryToggleColumnAction
    | SummaryTaskSummarySettingsAction
    | SummaryExportCSVAction;


//
// STATE
//
export interface ISummaryState {
    // estimate: IEstimate;
    version: IVersion | undefined;
    settings: {
        columns: {
            low: boolean;
            mid: boolean;
            high: boolean;
        };
        enabledRoles: string[];
        dataPosition: 'left' | 'center' | 'right',
        isMidAvailable: boolean;
    };
    summary: {[key: string]: ITaskSummary};
    totals: {
        hours: {
            low: number;
            mid: number;
            high: number;
        };
        amounts: {
            low: number;
            mid: number;
            high: number;
        };
    },
    taskSummarySettings: TaskSummarySettings;
    taskSummary: TaskSummaryLine[];
}

const initialState: ISummaryState = {
    // estimate: generateBlankEstimate(),
    version: undefined,
    settings: {
        columns: {
            low: true,
            mid: false,
            high: true
        },
        enabledRoles: [],
        dataPosition: 'right',
        isMidAvailable: false
    },
    summary: {},
    totals: {
        hours: {
            low: 0,
            mid: 0,
            high: 0
        },
        amounts: {
            low: 0,
            mid: 0,
            high: 0
        }
    },
    taskSummarySettings: {
        showDescriptions: false,
        summaryType: 'high',
        showRoleHours: false,
        width: 670,
        height: 900,
    },
    taskSummary: [],
};

//
// REDUCER
//

export const summaryReducer = createReducer(initialState, (builder) => builder
    .addCase(summaryEstimateSetAction, (state, action) => {
        const { version } = action.payload;
        // const summary: {[key: string]: ITaskSummary} = calculateEstimateRanges(estimate);
        const summary: {[key: string]: ITaskSummary} = calculateRateCard(version);

        const settings = {...state.settings};
        settings.isMidAvailable = (version.settings.ranges.low !== 1 && version.settings.ranges.high !== 1);
        settings.enabledRoles = version.settings.roles.filter(x => x.enabled).map(x => x.role);

        const totals = {
            hours: {
                low: summary.Total?.low?.hours || 0,
                mid: summary.Total?.hours || 0,
                high: summary.Total?.high?.hours || 0
            },
            amounts: {
                low: summary.Total?.low?.amount || 0,
                mid: summary.Total?.amount || 0,
                high: summary.Total?.high?.amount || 0
            }
        };

        const taskSummary = calculateTaskSummaryLines(version, state.taskSummarySettings.summaryType);

        return {...state, settings, version, summary, totals, taskSummary};
    })
    .addCase(summaryEnableRoleAction, (state, action) => {
        const {role} = action.payload;
        const enabledRoles = [...state.settings.enabledRoles, role];
        const settings = {...state.settings, enabledRoles};

        return {...state, settings};
    })
    .addCase(summaryDisableRoleAction, (state, action) => {
        const {role} = action.payload;
        const enabledRoles = state.settings.enabledRoles.filter(x => x !== role);
        const settings = {...state.settings, enabledRoles};

        return {...state, settings};
    })
    .addCase(summaryDataPositionAction, (state, action) => {
        const {dataPosition} = action.payload;
        const settings = {...state.settings, dataPosition};

        return {...state, settings};
    })
    .addCase(summaryToggleColumnAction, (state, action) => {
        const {column} = action.payload;
        const columns = {...state.settings.columns};

        const value = columns[column];
        columns[column] = !value;
        
        const settings = {...state.settings, columns};

        return {...state, settings};
    })
    .addCase(summaryTaskSummarySettingsAction, (state, action) => {
        const {settings: taskSummarySettings} = action.payload;
        return {...state, taskSummarySettings};
    })
    // This action is really a no-op; the Saga will pick it up and act accordingly.
    .addCase(summaryExportCsvAction, (state, action) => ({...state}))
);
