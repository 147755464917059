import * as React from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {Dialog, DialogContent, DialogActions, DialogTitle, Button, TextField, Autocomplete, Stack, Select, OutlinedInput, MenuItem, Checkbox, ListItemText, FormControl, InputLabel} from '@mui/material';

import {IRootStore} from '../../RootStore';
import {estimationSetAction, generateBlankEstimate, estimationSaveAction, EstimateModeType, getConfigAction, estimationCreateAction} from '../../modules';
import { ModeSwitcher } from '../ui';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DialogContainer = styled.div`
    min-width: 400px;

    .select-container {
        margin-top: 10px;
    }
`;

export interface INewEstimateModalProps {
    open: boolean;
    onCancel: () => void;
    onSave: () => void;
}

export const NewEstimateModal: React.FC<INewEstimateModalProps> = (props: INewEstimateModalProps) => {

    const dispatch = useDispatch();

    const {isBusy} = useSelector((state: IRootStore) => state.estimation);

    const [client, setClient] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [projectCode, setProjectCode] = React.useState('');
    const [mode, setMode] = React.useState<EstimateModeType>('COMPUTED');
    
    const [primaryRole, setPrimaryRole] = React.useState<string>('');
    const [additionalRoles, setAdditionalRoles] = React.useState<string[]>([]);

    const {filterOptions} = useSelector((state: IRootStore) => state.estimateList);
    const {rolesAndRates} = useSelector((state: IRootStore) => state.config);

    const isSaveDisabled = (isBusy || client.length < 1 || title.length < 1 || projectCode.length < 1 || primaryRole.length < 1);

    const onModeChange = () => {
        (mode === 'COMPUTED')
            ? setMode('MANUAL')
            : setMode('COMPUTED');
    };

    React.useEffect(() => {
        setMode('COMPUTED');
        dispatch(getConfigAction({context: 'defaultRolesAndRates'}));
    }, [props.open, dispatch]);

    const onSaveClick = () => {
        dispatch(estimationSetAction({estimate: generateBlankEstimate()}));

        const roles = [];

        const _primaryRole = rolesAndRates.find(x => x.role === primaryRole);

        if (!_primaryRole) {
            // TODO: Find a way to get rid of this!
            console.error('Primary Role not found!');
            return;
        }

        if (_primaryRole) {
            roles.push({..._primaryRole, isPrimary: true, enabled: true, multiplier: 1});
        }
        const _additionalRoles = rolesAndRates
            .filter(x => additionalRoles.includes(x.role) && x.role !== _primaryRole.role)
            .map(x => ({...x, isPrimary: false}));

        roles.push(..._additionalRoles);

        const estimate = {...generateBlankEstimate(), client, title, projectCode};

        dispatch(estimationCreateAction({estimate, primaryRole: {..._primaryRole, isPrimary: true}, mode, additionalRoles: _additionalRoles}));
        props.onSave();
    };

    const primaryRoleEls = rolesAndRates
        .map((roleAndRate, index) => (
            <MenuItem key={index} value={roleAndRate.role}>{roleAndRate.name}</MenuItem>
        ));

    const availableRolesEl = rolesAndRates
        .filter(x => x.role !== primaryRole)
        .map((roleAndRate, index) => (
            <MenuItem key={index} value={roleAndRate.role}>
                <Checkbox checked={additionalRoles.indexOf(roleAndRate.role) > -1} />
                <ListItemText primary={roleAndRate.name} />
            </MenuItem>
        ));

    return (
        <div>
            <Dialog open={props.open}>
                <DialogTitle>New Estimate</DialogTitle>
                <DialogContent>
                    <DialogContainer>                       
                        <Stack spacing={2} >
                            <Autocomplete
                                freeSolo={true}
                                options={filterOptions.clients}
                                getOptionLabel={(option) => option}
                                onChange={(e, val) => setClient(val || '')}
                                renderInput={(params) => <TextField {...params} label="Client" variant="standard" onChange={(e) => setClient(e.target.value)} />}
                            />
                            <TextField 
                                type="text" 
                                label="Title" 
                                variant="standard"
                                size="small"
                                fullWidth={true} 
                                onChange={(event) => setTitle(event.target.value)} 
                            />
                            <TextField 
                                type="text" 
                                label="Project code" 
                                variant="standard"
                                size="small"
                                fullWidth={true} 
                                onChange={(event) => setProjectCode(event.target.value)} 
                            />
                            <ModeSwitcher mode={mode} onChange={onModeChange} />

                            <div>
                                <div className="select-container">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="demo-simple-select-label">Primary Role</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={primaryRole}
                                            label="Primary Role"
                                            required={true}
                                            onChange={(e) => setPrimaryRole(e.target.value)}
                                        >
                                            {primaryRoleEls}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="select-container">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="demo-multiple-checkbox-label">Additional Roles</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            multiple
                                            value={additionalRoles}
                                            disabled={ !primaryRole || primaryRole === '' }
                                            onChange={(e) => setAdditionalRoles(e.target.value as string[])}
                                            input={<OutlinedInput label="Additional Roles" />}
                                            renderValue={(selected: string[]) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {availableRolesEl}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                        </Stack>
                    </DialogContainer>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={props.onCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" disabled={isSaveDisabled} onClick={onSaveClick}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
