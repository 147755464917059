import {call, takeLatest, put, takeEvery, select} from 'redux-saga/effects';
import {IEstimateSearchResponse, IEstimate} from './Models';
import * as EstimateStore from './EstimationStore';
import * as ListStore from './ListStore';
import * as Service from './Service';

import {notificationsAddAction} from '../notification';

export function* listEstimatesSaga(action: ListStore.ListSearchAction) {
    try {
        const {filters, page, size} = action.payload;
        const result: IEstimateSearchResponse = yield call(Service.listEstimates, filters, page, size);
        const {results: estimates, pagination} = result;
        yield put(ListStore.listSearchSuccesssAction({estimates, pagination}));
    } catch (e) {
        console.error(e);

        const message = `Error retreiving estimates: ${e}`;
        yield put(ListStore.listSearchErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'estimateList', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* filters() {
    try {
        const result: {[key: string]: any} = yield call(Service.getFilters);
        yield put(ListStore.listFiltersSuccessAction({filterOptions: result}));

    } catch (e) {
        console.error(e);

        const message = `Error retreiving filters: ${e}`;
        yield put(EstimateStore.estimationErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'estimateList', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* copyEstimateSaga(action: ListStore.ListCopyEstimateAction) {
    try {
        // copy estimate
        const estimate: IEstimate = yield call(Service.copyEstimate, action.payload.id);
        yield put(EstimateStore.estimationSetAction({estimate}));
        yield put(ListStore.listCopyEstimateSuccessAction({estimate: estimate}));

        yield refreshEstimateList();

        yield put(notificationsAddAction({store: 'estimateList', message: `Successfully created copy!`, severity: 'success', dismissable: true, timeout: 10000}));

    } catch (e) {
        console.error(e);

        const message = `Error copying estimate: ${e}`;
        yield put(EstimateStore.estimationErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'estimateList', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* revisionEstimateSaga(action: ListStore.ListCopyEstimateAction) {
    try {
        // revision
        const estimate: IEstimate = yield call(Service.revision, action.payload.id);
        yield put(EstimateStore.estimationSetAction({estimate}));
        yield put(ListStore.listRevisionSuccessAction({estimate}));

        yield refreshEstimateList();

        yield put(notificationsAddAction({store: 'estimateList', message: `New revision created!`, severity: 'success', dismissable: true, timeout: 5000}));
        
    } catch (e: any) {
        console.error(e);

        const message = `Error creating estimate revision: ${e.message}`;
        yield put(EstimateStore.estimationErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'estimateList', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* refreshEstimateList() {
    // refresh list
    const {filters, page, size} = yield select((store) => store.estimateList);
    const result: IEstimateSearchResponse = yield call(Service.listEstimates, filters, page, size);
    const {results: estimates, pagination} = result;
    yield put(ListStore.listSearchSuccesssAction({estimates, pagination}));
}

export function* estimateListSaga() {
    yield takeLatest(ListStore.LIST_SEARCH, listEstimatesSaga);
    yield takeEvery(ListStore.LIST_FILTERS, filters);
    yield takeEvery(ListStore.LIST_COPY, copyEstimateSaga);
    yield takeEvery(ListStore.LIST_REVISION, revisionEstimateSaga);
}
