import * as React from 'react';
import styled from 'styled-components';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IRootStore } from '../../../RootStore';
import { getConfigAction } from '../../../modules';

const Container = styled.div`
    /* width: 100%; */
`;

export interface IRoleSelector {
    excludedRoles: string[];
    onChange: (val: string) => void;
}

export const RoleSelector: React.FC<IRoleSelector> = (props: IRoleSelector) => {
    const {excludedRoles = [], onChange} = props;

    const dispatch = useDispatch();
    
    const [role, setRole] = React.useState('');

    const {rolesAndRates} = useSelector((state: IRootStore) => state.config);

    React.useEffect(() => {
        dispatch(getConfigAction({context: 'defaultRolesAndRates'}));
    }, [dispatch]);

    React.useEffect(() => {
        const found = excludedRoles.includes(role);

        if (found) {
            setRole('');
        }
    }, [excludedRoles, role]);

    const primaryRoleEls = rolesAndRates
        .filter(x => !excludedRoles.includes(x.role))
        .map((roleAndRate, index) => (
            <MenuItem key={index} value={roleAndRate.role}>{roleAndRate.name}</MenuItem>
    ));

    const onRoleSelect = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        const {value} = event.target;
        setRole(value);
        onChange(value);
    };

    return (
        <Container>
            <FormControl fullWidth={true}>
                <InputLabel id="role-select-label">Select Role</InputLabel>
                <Select
                    labelId="role-select-label"
                    value={role}
                    label="Select Role"
                    required={true}
                    onChange={onRoleSelect}
                    disabled={primaryRoleEls.length < 1}
                >
                    {primaryRoleEls}
                </Select>
            </FormControl>
        </Container>
    );
};
