import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IRootStore } from '../../../RootStore';
import { EstimateModeType, versionSettingsChangeModeAction, versionSettingsChangeRoleAction, versionSettingsResetAction, estimationViewSettingsAction } from '../../../modules';
import { ChangePrimaryRoleModal, ConfirmModal, ISettingsModal, SettingsModal } from '../../modals';
import { ModeSwitcher } from '../ModeSwitcher';
import { EstimateRoleTable } from './EstimateRoleTable';
import { EstimateRange } from './EstimateRange';
import { SettingsReadOnly } from './SettingsReadOnly';
import { Divider, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Switch, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material';
import { GeneralInfo } from './GeneralInfo';

type SettingOptions = 'info'|'mode'|'roles'|'ranges'|'add-role'|'resync'|'settings-ro';

const Container = styled.div`
`;

export interface ISettingsMenu {
}

export const SettingsMenu: React.FC<ISettingsMenu> = (props: ISettingsMenu) => {
    const dispatch = useDispatch();

    const {estimate, settings: {showAmounts}} = useSelector((state: IRootStore) => state.estimation);
    const {version} = useSelector((state: IRootStore) => state.version);

    const {settings: {mode}} = version;

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isModeConfirmModalOpen, setIsModeConfirmModalOpen] = React.useState(false);
    const [isResetConfirmModalOpen, setIsResetConfirmModalOpen] = React.useState(false);
    const [isChangeRoleModalOpen, setIsChangeRoleModalOpen] = React.useState(false);

    const [setting, setSetting] = React.useState<SettingOptions>('info');

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [primaryRole = {role: ''}] = version.settings.roles.filter(x => x.isPrimary);

    const isReadOnly = estimate.status === 'PUBLISHED' || estimate.status === 'CLOSED' || estimate.status === 'PENDING';
    
    const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSettingsClose = () => {
        setAnchorEl(null);
    };

    const confirmModeChange = () => {
        const _mode: EstimateModeType = (mode === 'MANUAL') ? 'COMPUTED' : 'MANUAL';

        dispatch(versionSettingsChangeModeAction({mode: _mode}));

        setIsModeConfirmModalOpen(false);
        setIsModalOpen(false);
    }

    const confirmResetClick = () => {
        dispatch(versionSettingsResetAction({estimateId: estimate.id, versionId: version.id}));
        setIsResetConfirmModalOpen(false);
        handleSettingsClose();
    };

    const onChangePrimaryRoleClick = (primaryRole: string) => {
        dispatch(versionSettingsChangeRoleAction({versionId: version.id, primaryRole}));
        setIsChangeRoleModalOpen(false);
        handleSettingsClose();
    };

    const onModeChange = () => {
        setIsModeConfirmModalOpen(true);
    };

    const getSettings = () => {
        const _props: ISettingsModal = {
            open: isModalOpen,
            onClose: () => setIsModalOpen(false),
            title: ''
        };

        switch(setting) {
            case 'info': {
                _props.title = 'Project Information';
                _props.children = <GeneralInfo />
                break;
            }
            case 'mode': {
                _props.title = 'Mode';
                _props.children = <ModeSwitcher mode={mode} onChange={onModeChange} displayText={true} />;
                break;
            } 
            case 'roles': {
                _props.title = 'Roles';
                _props.children = <EstimateRoleTable />;
                break;
            }
            case 'ranges': {
                _props.title = 'Range';
                _props.children = <EstimateRange />
                break;
            }
            case 'settings-ro': {
                _props.title = 'Settings';
                _props.children = <SettingsReadOnly />
                break;
            }
        }

        return _props;
    };

    const onMenuClick = (val: SettingOptions) => {
        setSetting(val);
        setIsModalOpen(true);
        handleSettingsClose();
    };

    const onShowAmountsClick = () => {
        dispatch(estimationViewSettingsAction({showAmounts: !showAmounts}));
    };

    return (
        <Container>
            <div className="button-container">              
                <div>
                    <IconButton onClick={handleSettingsClick}>
                        <Settings fontSize="inherit" />
                    </IconButton>
                </div>
                
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleSettingsClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    {
                        !isReadOnly &&
                        <div>
                            { 
                                !isReadOnly &&
                                <>
                                <MenuItem onClick={() => onMenuClick('info')} style={{minWidth: '275px'}}>
                                    <ListItemIcon>
                                        <Icon>edit</Icon>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <span>Edit Project</span>
                                    </ListItemText>
                                </MenuItem>

                                <Divider />
                                </>
                            }
                            <MenuItem onClick={() => onMenuClick('mode')} style={{minWidth: '275px'}}>
                                <ListItemIcon>
                                    <Icon>calculate</Icon>
                                </ListItemIcon>
                                <ListItemText>
                                    <span>Mode</span>
                                </ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                    {version.settings.mode}
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => onMenuClick('ranges')}>
                                <ListItemIcon>
                                    <Icon>stacked_line_chart</Icon>
                                </ListItemIcon>
                                <ListItemText>
                                    <span>Range</span>
                                </ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                    {version.settings.ranges.low}-{version.settings.ranges.high}
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => onMenuClick('roles')}>
                                <ListItemIcon>
                                    <Icon color="primary">group</Icon>
                                </ListItemIcon>
                                <ListItemText>
                                <span>Manage Roles</span>
                                </ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                    {version.settings.roles.length}
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => setIsChangeRoleModalOpen(true)}>
                                <ListItemIcon>
                                    <Icon color="primary">person</Icon>
                                </ListItemIcon>
                                <ListItemText>
                                    <span>Change Primary Role</span>
                                </ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                    {primaryRole.role}
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => setIsResetConfirmModalOpen(true)}>
                                <ListItemIcon>
                                    <Icon color="secondary">sync</Icon>
                                </ListItemIcon>
                                <ListItemText>
                                    <span>Resync From Global</span>
                                </ListItemText>
                            </MenuItem>
                        </div>
                    }

                    {
                        isReadOnly &&
                        <MenuItem onClick={() => onMenuClick('settings-ro')}>
                            <ListItemText>
                                <span>View settings</span>
                            </ListItemText>
                        </MenuItem>
                    }

                    
                    <Divider />
                    
                    <MenuItem onClick={onShowAmountsClick}>
                        <ListItemIcon>
                            <Icon color="inherit">paid</Icon>
                        </ListItemIcon>
                        <ListItemText>
                            <span>Show Amounts</span>
                        </ListItemText>
                        <span>
                         <Switch checked={showAmounts} />
                        </span>
                    </MenuItem>
                </Menu>
            </div>
            <SettingsModal {...getSettings()} />
            <ConfirmModal isOpen={isModeConfirmModalOpen} onConfirm={confirmModeChange} onCancel={() => setIsModeConfirmModalOpen(false)}>
                <p>Changing the mode will alter all tasks on the estimate. Do you wish to continue?</p>
            </ConfirmModal>
            <ConfirmModal isOpen={isResetConfirmModalOpen} onConfirm={confirmResetClick} onCancel={() => setIsResetConfirmModalOpen(false)}>
                <p>Resyncing settings with the values from the global configuration will overwrite all settings in your estimate will affect all tasks. Do you wish to continue?</p>
            </ConfirmModal>
            <ChangePrimaryRoleModal open={isChangeRoleModalOpen} onCancel={() => setIsChangeRoleModalOpen(false)} onSave={onChangePrimaryRoleClick} />
        </Container>
    );
};
