import * as React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { IRootStore } from '../../../RootStore';
import { IRangeSetting, versionSettingsChangeAction } from '../../../modules';
import { Alert, TextField } from '@mui/material';

const Container = styled.div`
    width: 100%;

    .ranges {
        margin-right: 50px;

        .range-input {
            margin-right: 20px;
        }
    }
`;

export interface IEstimateRange {
}

export const EstimateRange: React.FC<IEstimateRange> = (props: IEstimateRange) => {

    const dispatch = useDispatch();

    const [compRanges, setCompRanges] = React.useState<IRangeSetting>({high: 0, low: 0});
    const [lowError, setLowError] = React.useState(false);
    const [highError, setHighError] = React.useState(false);

    const {roles, ranges, mode} = useSelector((state: IRootStore) => state.version.version.settings);

    React.useEffect(() => {
        if (ranges.high !== compRanges.high && ranges.low !== compRanges.low) {
            setCompRanges(ranges);
        }
    }, [ranges, compRanges]);

    const onRangeChange = (type: 'high' | 'low', value: string) => {
        const val = parseFloat(value);
        const _ranges = {...ranges};
        _ranges[type] = val;

        setCompRanges(_ranges);

        if (isNaN(val)) {
            if (type === 'high') {
                setHighError(true);
            }

            if (type === 'low') {
                setLowError(true);
            }

        }

        if (_ranges.low >= _ranges.high) {
            if (type === 'high') {
                setHighError(true);
            }

            if (type === 'low') {
                setLowError(true);
            }

        } else {

            setLowError(false);
            setHighError(false);

            const settings = {roles, ranges: _ranges, mode};
            dispatch(versionSettingsChangeAction({settings}));
        }


    };

    return (
        <Container>
            <div>
                <p>Set the range for the hours and amounts on the estimate.</p>
            </div>
            {
                (lowError || highError) &&
                <div className="alert-container">
                    <Alert severity="error">Invalid range values! Changes will not be saved!</Alert>
                </div>
            }
            <div className="ranges">
                <TextField
                    type="number"
                    label="Low"
                    variant="standard"
                    size="small"
                    className="range-input"
                    inputProps={{min: 0, step: 0.05, style: { textAlign: 'right' }}}
                    value={compRanges.low}
                    onChange={(event) => onRangeChange('low', event.currentTarget.value)}
                    error={lowError}
                />
                <TextField
                    type="number"
                    label="High"
                    variant="standard"
                    size="small"
                    className="range-input"
                    inputProps={{min: 0, step: 0.05, style: { textAlign: 'right' }}}
                    value={compRanges.high}
                    onChange={(event) => onRangeChange('high', event.currentTarget.value)}
                    error={highError}
                />
            </div>
        </Container>
    );
};
