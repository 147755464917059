import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IRootStore } from '../../../RootStore';
import { Dangerous, Verified } from '@mui/icons-material';

const Container = styled.div`
`;

const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };

export interface IApprovalList {
}

export const ApprovalList: React.FC<IApprovalList> = (props: IApprovalList) => {

    const {approvals} = useSelector((state: IRootStore) => state.estimation.estimate);

    const rowEls = [...approvals]
        .sort((a, b) => {
            if (a.actionDate > b.actionDate) return -1;
            else if (a.actionDate < b.actionDate) return 1;
            else return 0;
        })
        .map((a, index) => (
        <TableRow key={index}>
            <TableCell>
                {
                    a.action === 'APPROVED' &&
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px'}}>
                        <Verified color="primary" />
                        <span>Approved</span>
                    </div>
                }
                {
                    a.action === 'CHANGES_REQUESTED' &&
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px'}}>
                        <Dangerous color="secondary" />
                        <span>Changes Requested</span>
                    </div>
                }
            </TableCell>
            <TableCell>{a.actionUser.name}</TableCell>
            <TableCell>{a.comment}</TableCell>
            <TableCell>{new Intl.DateTimeFormat('en-US', options).format(new Date(a.actionDate))}</TableCell>
        </TableRow>
    ));

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Decision</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Comment</TableCell>
                        <TableCell>Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowEls}
                </TableBody>
            </Table>
        </Container>
    );
};
