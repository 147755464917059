import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IRootStore } from '../../../RootStore';
import { Button, IconButton, Link, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { estimationAddResorceAction, estimationDeleteResourceAction, isReadOnlySelector } from '../../../modules';
import { ConfirmModal } from '../../modals';

const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };

const Container = styled.div`

    .input-container {
        margin-top: 40px;
        width: 100%;
        
        .MuiTextField-root {
            margin-right: 10px;
        }
    }
`;

export interface IResourceList {
}

export const ResourceList: React.FC<IResourceList> = (props: IResourceList) => {
    const dispatch = useDispatch();

    const { resources = [], id } = useSelector((state: IRootStore) => state.estimation.estimate);
    const isReadOnly = useSelector(isReadOnlySelector);

    const [ name, setName ] = React.useState('');
    const [ description, setDescription ] = React.useState('');
    const [ href, setHref ] = React.useState('');

    const [ isConfirmOpen, setIsConfirmOpen ] = React.useState(false);
    const [ selectedResourceId, setSelectedResourceId ] = React.useState<string|undefined>();

    const onAddClick = () => {
        dispatch(estimationAddResorceAction({id, name, description, href}));
        setName('');
        setHref('');
        setDescription('');
    };

    const onRemoveClick = (resourceId: string) => {
        setSelectedResourceId(resourceId);
        setIsConfirmOpen(true);
    };

    const onConfirmDelete = () => {
        dispatch(estimationDeleteResourceAction({ id, resourceId: selectedResourceId! }));
        setSelectedResourceId(undefined);
        setIsConfirmOpen(false);
    };

    const cancelConfirmDelete = () => {
        setSelectedResourceId(undefined);
        setIsConfirmOpen(false);
    };

    const isAddButtonDisabled = name.length < 1 || href.length < 1 || (href?.match(/http(s?):\/\//g) || []).length < 1
    
    const rowEls = resources.map((r, index) => (
        <TableRow key={index}>
            <TableCell>
                <Link href={r.href} target="_blank">{r.name}</Link>
                    {/* <Launch fontSize="small" /> */}
            </TableCell>
            <TableCell>{r.description}</TableCell>
            <TableCell>{r.createUser.name}</TableCell>
            <TableCell>{new Intl.DateTimeFormat('en-US', options).format(new Date(r.createDate))}</TableCell>
            <TableCell>
                {
                    !isReadOnly &&
                    <IconButton onClick={() => onRemoveClick(r._id)}>
                        <Delete />
                    </IconButton>
                }
            </TableCell>

        </TableRow>
    ));

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Created By</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { rowEls }
                </TableBody>
            </Table>
            {
                !isReadOnly &&
                <div className="input-container">
                    {/* <Typography variant="h5">Add Resource</Typography> */}
                    <TextField
                        value={name}
                        label="Name"
                        size="small"
                        sx={{ width: 1/4 }}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        value={href}
                        label="URL"
                        size="small"
                        sx={{ width: 1/4 }}
                        onChange={(e) => setHref(e.target.value)}
                    />
                    <TextField
                        value={description}
                        label="Description"
                        size="small"
                        sx={{ width: 1/4 }}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                
                    <Button 
                        variant="contained" 
                        disabled={isAddButtonDisabled}
                        onClick={onAddClick}
                    >
                        <Add />
                        Add
                    </Button>
                </div>
            }
            <ConfirmModal isOpen={isConfirmOpen} onConfirm={onConfirmDelete} onCancel={cancelConfirmDelete}>
                <p>Are you sure you want to delete the selected resource?</p>
            </ConfirmModal>
        </Container>
    );
};
