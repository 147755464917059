import * as React from 'react';
import {TextField, Autocomplete, Stack} from '@mui/material';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';

import {IRootStore} from '../../../RootStore';
import {estimationInfoUpdateAction, listFiltersAction} from '../../../modules';

const Container = styled.div`
    width: 100%;

    .misc-container {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
    }

    .status-container {
        margin-top: 30px;
    }
`;

export interface IGeneralInfoProps {

}

export const GeneralInfo: React.FC<IGeneralInfoProps> = (props: IGeneralInfoProps) => {

    const dispatch = useDispatch();

    const [_client, setClient] = React.useState('');
    const [_title, setTitle] = React.useState('');
    const [_projectCode, setProjectCode] = React.useState('');
    const [_description, setDescription] = React.useState('');

    const {client, title, projectCode, description} = useSelector((state: IRootStore) => state.estimation.estimate);
    const {filterOptions} = useSelector((state: IRootStore) => state.estimateList);

    React.useEffect(() => {
        if (client !== _client) {
            setClient(client);
        }
        if (title !== _title) {
            setTitle(title);
        }
        if (projectCode !== _projectCode) {
            setProjectCode(projectCode);
        }
        if (description !== _description){
            setDescription(description);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, title, projectCode]);

    React.useEffect(() => {
        dispatch(listFiltersAction());
    }, [dispatch]);

    const onClientChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = event.target;
        setClient(value);
    }

    const onClientSelect = (val: string) => {
        setClient(val);
    }

    const onTitleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = event.target; 
        setTitle(value);
    }

    const onProjectCodeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = event.target; 
        setProjectCode(value);
    }

    const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = event.target;
        setDescription(value);
    }

    const onFieldBlur = () => {
        if (client !== _client || title !== _title || projectCode !== _projectCode || description !== _description) {
            dispatch(estimationInfoUpdateAction({client: _client, title:  _title, projectCode: _projectCode, description: _description}));
        }
    };

    return (
        <Container>
            <form noValidate={true} autoComplete="off">
                <Stack spacing={2}>
                    <div>
                        <Autocomplete
                            freeSolo={true}
                            inputValue={_client}
                            options={filterOptions.clients}
                            getOptionLabel={(option) => option}
                            onChange={(e, val) => onClientSelect(val || '')}
                            onBlur={onFieldBlur}
                            renderInput={(params) => <TextField {...params} label="Client" variant="standard" inputProps={{ ...params.inputProps, "data-testid": "client-input" }} onChange={onClientChange} />}
                        />
                    </div>
                    <div>
                        <TextField
                            variant="standard"
                            label="Title"
                            aria-label="title"
                            inputProps={{ "data-testid": "title-input" }}
                            fullWidth={true}
                            value={_title}
                            onChange={onTitleChange}
                            onBlur={onFieldBlur}
                        />
                    </div>
                    <div>
                        <TextField
                            variant="standard"
                            label="Project Code"
                            aria-label="project-code"
                            fullWidth={true}
                            inputProps={{ "data-testid": "project-code-input" }}
                            value={_projectCode}
                            onChange={onProjectCodeChange}
                            onBlur={onFieldBlur}
                        />
                    </div>

                    <div>
                        <TextField
                            variant="standard"
                            label="Project Description"
                            aria-label="description"
                            inputProps={{ "data-testid": "description-input" }}
                            value={_description}
                            multiline={true}
                            fullWidth={true}
                            rows={5}
                            onChange={onDescriptionChange}
                            onBlur={onFieldBlur}
                        />
                    </div>

                </Stack>
            </form>
        </Container>
    );
};
