import * as React from 'react';
import styled from 'styled-components';
import {Select, MenuItem, InputLabel, Button, TextField, FormControl, Autocomplete, SelectChangeEvent} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

import {listFiltersAction, listSearchAction, StatusType} from '../../../modules';
import {IRootStore} from '../../../RootStore';
import {StatusSelect} from '../../ui';

const Container = styled.div`
    
    .fields-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > DIV {
            margin-right: 15px;
        }

        .field {
            min-width: 18vw;
            width: 30%;
        }
    }
`;

export interface IEstimateFilterProps {
}

export const EstimateFilter: React.FC<IEstimateFilterProps> = (props: IEstimateFilterProps) => {
    const dispatch = useDispatch();

    const [statuses, setStatuses] = React.useState<string[]>([]);
    const [client, setClient] = React.useState('');
    const [author, setAuthor] = React.useState('');

    const {filterOptions, size, filters} = useSelector((state: IRootStore) => state.estimateList);

    React.useEffect(() => {
        dispatch(listFiltersAction());

        const [_statuses] = filters.filter(x => x.name === 'status');
        if (_statuses) {
            setStatuses(_statuses.value.split(','));
        }

        const [_client] = filters.filter(x => x.name === 'client');
        if (_client) {
            setClient(_client.value || '');
        }

        const [_author] = filters.filter(x => x.name === 'author');
        if (_author) {
            setAuthor(_author.value || '');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClearClick = () => {
        setStatuses([]);
        setClient('');
        setAuthor('');

        dispatch(listSearchAction({filters: [], page: 1, size}));
    };

    const onStatusChange = (values: StatusType[]) => {
        setStatuses(values);

        const filters = [
            {name: 'status', value: values.join(',')},
            {name: 'client', value: client},
            {name: 'author', value: author}
        ]
        .filter(x => x.value !== '' && x.value !== undefined && x.value !== null);
        dispatch(listSearchAction({filters, page: 1, size}));
    };

    const onClientChange = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
        setClient(value || '');

        const filters = [
            {name: 'status', value: statuses.join(',')},
            {name: 'client', value: value || ''},
            {name: 'author', value: author}
        ]
        .filter(x => x.value !== '' && x.value !== undefined && x.value !== null);

        dispatch(listSearchAction({filters, page: 1, size}));
    };

    const onAuthorChange = (event: SelectChangeEvent) => {
        const {value} = event.target;
        setAuthor(value);

        const filters = [
            {name: 'status', value: statuses.join(',')},
            {name: 'client', value: client},
            {name: 'author', value}
        ]
        .filter(x => x.value !== '' && x.value !== undefined && x.value !== null);
        
        dispatch(listSearchAction({filters, page: 1, size}));
    };

    return (
        <Container>
            <div className="fields-container">
                <div style={{alignSelf: 'end', marginRight: '20px'}}>
                    <Button variant="outlined" onClick={onClearClick}>Clear</Button>
                </div>
                <div>
                    <StatusSelect onChange={onStatusChange} selectedStatuses={statuses as StatusType[]} />
                </div>
                <div>
                    <Autocomplete
                        freeSolo={true}
                        className="field"
                        inputValue={client}
                        options={filterOptions.clients}
                        getOptionLabel={(option) => option}
                        onChange={onClientChange}
                        renderInput={(params) => <TextField {...params} label="Client" variant="standard"  onChange={(e) => setClient(e.target.value)} />}
                    />
                </div>
                <div>
                    <FormControl variant="standard">
                        <InputLabel id="select-author">Author</InputLabel>
                        <Select
                            labelId="select-author"
                            value={author}
                            className="field"
                            displayEmpty={true}
                            onChange={onAuthorChange}
                        >
                            <MenuItem value=""></MenuItem>
                            {
                                filterOptions.authors.map((x, index) => (
                                    <MenuItem key={index} value={x._id}>{x.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </div>
        </Container>
    );
};
