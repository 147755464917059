import * as React from 'react';
import { Navigate } from 'react-router-dom';
import decode from 'jwt-decode';

export const ProtectedRoute = ({ children, ...rest }: {children: JSX.Element}) => {

    const [intervalId, setIntervalId] = React.useState(0);

    const token = (localStorage.getItem('token')) ? decode(localStorage.getItem('token')) : undefined;
    const now = Date.now();
    const isExpired = (token && token.exp && (new Date(token.exp).getTime() * 1000) < now);

    const shouldRedirectToLogin = ((!token) || (token && isExpired));

    React.useEffect(() => {

        checkToken();

        return () => {
            clearInterval(intervalId);
            setIntervalId(0);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkToken = () => {
        // TODO: Find a better place for this!
        const id = window.setInterval(() => {
            // Interval to check the status of the token every 5 seconds.
            const token = (localStorage.getItem('token')) ? decode(localStorage.getItem('token')) : undefined;
            const now = Date.now();
            const isExpired = (token && token.exp && (new Date(token.exp).getTime() * 1000) < now);

            let shouldRedirect = false

            if (!token || isExpired) {
                shouldRedirect = true;
                localStorage.removeItem('token');
            }

            if (shouldRedirect) {
                console.warn(`Token is missing or expired, redirecting to login!`);
                window.location.hash = '#/login';
            }

        }, 5000);

        setIntervalId(id);
    };

    return (
        <>
            {
                shouldRedirectToLogin 
                ? <Navigate to="/login" replace={true} />
                : children
            }            
        </>
    )
};
