import { RequestBuilder } from "../util";

const HOST = `${process.env.REACT_APP_BACKEND_HOST}/reporting`;

export async function summaryEstimate(daysAgo: number, statuses: string[] = []) {

    const url = `${HOST}/estimate-summary`;

    return new RequestBuilder(url)
        .setMethod('GET')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .addParam('daysAgo', `${daysAgo}`)
        .addParam('status', statuses.join(','))
        .build();
}