import * as React from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {Table, TableHead, TableBody, TableRow, TableCell, Typography} from '@mui/material';

import {IRootStore} from '../../../RootStore';

const Container = styled.div`
    .ranges-and-mode {
        display: flex;
        margin-bottom: 50px;

        > DIV {
            margin-right: 50px;
        }
    }
`;

export interface ISettingsReadOnlyProps {
}

export const SettingsReadOnly: React.FC<ISettingsReadOnlyProps> = (props: ISettingsReadOnlyProps) => {


    const {settings} = useSelector((state: IRootStore) => state.version.version);

    const roleEls = settings.roles
        .filter(x => x.enabled)
        .map((x, index) => (
            <TableRow key={index}>
                <TableCell>{x.role}</TableCell>
                <TableCell>{x.name}</TableCell>
                <TableCell>${x.rate}</TableCell>
                <TableCell>{x.multiplier}</TableCell>
            </TableRow>
        ));

    return (
        <Container>

            <div className="ranges-and-mode">
                <div>
                    <Typography variant="h4">Range</Typography>
                    <Typography variant="h6">{settings.ranges.low} -- {settings.ranges.high}</Typography>
                </div>

                <div>
                    <Typography variant="h4">Mode</Typography>
                    <Typography variant="h6">{settings.mode}</Typography>
                </div>
            </div>

            <div>
                <Typography variant="h4">Roles</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Role</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell>Multiplier</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {roleEls}
                    </TableBody>
                </Table>
            </div>

        </Container>
    );
};
