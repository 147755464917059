import {configureStore, getDefaultMiddleware, PreloadedState, combineReducers} from '@reduxjs/toolkit';
import {authReducer, configReducer, estimationReducer, listReducer, notificationsReducer, userReducer, summaryReducer, reportingReducer, summarySagas, versionReducer, versionSagas, assumptionsReducer, assumptionsSagas} from './modules';
import {authSagas, configSagas, estimationSaga, userSagas, estimateListSaga, reportingSaga} from './modules';
import createSagaMiddleware from '@redux-saga/core';

export const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
    assumptions: assumptionsReducer,
    auth: authReducer,
    config: configReducer,
    estimation: estimationReducer,
    estimateList: listReducer,
    notifications: notificationsReducer,
    reporting: reportingReducer,
    summary: summaryReducer,
    user: userReducer,
    version: versionReducer
});

export type RootReducer = ReturnType<typeof rootReducer>;

export function createStore(preloadedState?: PreloadedState<RootReducer>) {
    return configureStore({
        reducer: rootReducer,
        middleware: [
            ...getDefaultMiddleware({thunk: false, serializableCheck: false}),
            sagaMiddleware
        ],
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState
    });
};

export const store = createStore();

export const initSagas = () => {
    sagaMiddleware.run(authSagas);
    sagaMiddleware.run(assumptionsSagas);
    sagaMiddleware.run(configSagas);
    sagaMiddleware.run(estimationSaga);
    sagaMiddleware.run(estimateListSaga);
    sagaMiddleware.run(userSagas);
    sagaMiddleware.run(reportingSaga);
    sagaMiddleware.run(summarySagas);
    sagaMiddleware.run(versionSagas);
};

export type IRootStore = ReturnType<typeof store.getState>
