import { createSelector } from "@reduxjs/toolkit";
import { IRootStore } from "../../RootStore";
import { IEstimate, IRoleSetting, IVersion } from "./Models";

//
// ESTIMATION
//

export const isReadOnlySelector = createSelector(
    [(state: IRootStore) => state.estimation.estimate],
    (estimate: IEstimate) => ['PUBLISHED', 'CLOSED', 'PENDING'].includes(estimate.status)
);


//
// VERSIONS
//

export const versionSettingsSelector = createSelector(
    [(state: IRootStore) => state.version.version],
    (version: IVersion) => version.settings
);

export const primaryRoleSelector = createSelector(
    [(state: IRootStore) => state.version.version.settings.roles],
    (roles: IRoleSetting[]) => roles.find(x => x.isPrimary)
);
