import {call, takeLatest, put} from 'redux-saga/effects';
import { notificationsAddAction } from '../notification';
import { SummaryData } from './Models';
import { summaryEstimate } from './Service';
import { reportingErrorAction, ReportingListActinon, reportingListSuccessAction, REPORTING_LIST } from './Store';

export function* summaryEstimateSaga(action: ReportingListActinon) {
    try {
        const {daysAgo, statuses} = action.payload;
        const result: SummaryData = yield call(summaryEstimate, daysAgo, statuses as string[]);
        yield put(reportingListSuccessAction({summary: result}));

    } catch (e) {
        console.error(e);

        const message = `Error retreiving estimates: ${e}`;
        yield put(reportingErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'estimateList', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* reportingSaga() {
    yield takeLatest(REPORTING_LIST, summaryEstimateSaga);
}