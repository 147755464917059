import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { Edit } from '@mui/icons-material';
import * as React from 'react';
import styled from 'styled-components';
import { StatusType } from '../../../modules';

const Container = styled.div`
`;

const options: {status: StatusType, text: string}[] = [
    {status: 'DRAFT', text: 'Draft'},
    {status: 'REVIEW', text: 'Review'},
    {status: 'PENDING', text: 'Pending'},
    {status: 'PUBLISHED', text: 'Published'},
    {status: 'CLOSED', text: 'Closed'},
];

const buttonMap = {
    'DRAFT': {icon: <Edit />, text: 'Draft'},
    'REVIEW': {icon: <Edit />, text: 'Review'},
    'PENDING': {icon: <Edit />, text: 'Pending'},
    'PUBLISHED': {icon: <Edit />, text: 'Published'},
    'CLOSED': {icon: <Edit />, text: 'Closed'},
}

export interface IStatusButton {
    currentStatus: StatusType;
    disabled?: boolean;
    onClick: (status: StatusType) => void;
}

export const StatusButton: React.FC<IStatusButton> = (props: IStatusButton) => {
    const { currentStatus, onClick, disabled } = props;

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const onMenuItemClick = (status: StatusType) => {
        onClick(status);
        setOpen(false);
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const buttonOption = buttonMap[currentStatus];

    return (
        <Container>
            <Button
                ref={anchorRef}
                variant='text'
                color={['PUBLISHED', 'CLOSED'].includes(currentStatus) ? 'secondary' : 'primary'}
                disabled={disabled || currentStatus === 'PUBLISHED'}
                startIcon={buttonOption.icon}
                onClick={handleToggle}
            >
                {buttonOption.text}
            </Button>
            <Popper
                sx={{
                    zIndex: 3,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {
                                        currentStatus !== 'CLOSED' && options
                                        .filter(x => x.status !== currentStatus)
                                        .map((option, index) => (
                                            <MenuItem
                                                key={option.status}
                                                onClick={() => onMenuItemClick(option.status)}
                                            >
                                                Move to {option.text}
                                            </MenuItem>
                                        ))
                                    }
                                    {
                                        currentStatus === 'CLOSED' &&
                                        <MenuItem key={'DRAFT'} onClick={() => onMenuItemClick('DRAFT')}>Move to Draft</MenuItem>
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Container>
    );
};
