
import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as Store from './AssumptionsStore';
import * as Service from './Service';
import { notificationsAddAction } from '../notification';
import { IAssumption } from './Models';

export function* listAssumptionsSaga(action: Store.AssumptionsListAction) {
    try {
        const assumptions: IAssumption[] = yield call(Service.getAssumptions, action.payload.estimateId);
        yield put(Store.assumptionsListSuccessAction({assumptions}));

    } catch (e) {
        console.error(e);

        const message = `Error listing assumptions: ${e}`;
        yield put(Store.assumptionsErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'assumptions', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* createAssumptionSaga(action: Store.AssumptionsCreateAction) {
    try {
        const {estimateId} = yield select((store) => store.assumptions);

        const assumption: IAssumption = yield call(Service.createAssumption, estimateId, action.payload.assumption);
        yield put(Store.assumptionsCreateSuccessAction({assumption}));

    } catch (e) {
        console.error(e);

        const message = `Error creating assumption: ${e}`;
        yield put(Store.assumptionsErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'assumptions', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* updateAssumptionSaga(action: Store.AssumptionsUpdateAction) {
    try {
        const {id = '', assumption} = action.payload.assumption;

        const {estimateId} = yield select((store) => store.assumptions);

        const _assumption: IAssumption = yield call(Service.updateAssumption, estimateId, id, assumption);
        yield put(Store.assumptionsUpdateSuccessAction({assumption: _assumption}));

    } catch (e) {
        console.error(e);

        const message = `Error updating assumption: ${e}`;
        yield put(Store.assumptionsErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'assumptions', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}

export function* deleteAssumptionSaga(action: Store.AssumptionsDeleteAction) {
    try {
        const {estimateId} = yield select((store) => store.assumptions);
        yield call(Service.deleteAssumption, estimateId, action.payload.id);
        yield put(Store.assumptionsDeleteSuccessAction({id: action.payload.id}));

    } catch (e) {
        console.error(e);

        const message = `Error deleting assumption: ${e}`;
        yield put(Store.assumptionsErrorAction({error: message}));
        yield put(notificationsAddAction({store: 'assumptions', message, severity: 'error', dismissable: true, timeout: undefined}));
    }
}


export function* assumptionsSagas() {
    yield takeEvery(Store.ASSUMPTIONS_LIST, listAssumptionsSaga);
    yield takeEvery(Store.ASSUMPTIONS_CREATE, createAssumptionSaga);
    yield takeEvery(Store.ASSUMPTIONS_UPDATE, updateAssumptionSaga);
    yield takeEvery(Store.ASSUMPTIONS_DELETE, deleteAssumptionSaga);
}