import * as React from 'react';
import styled from 'styled-components';
import {Popover, Button} from '@mui/material';

import {NumberFormat} from '..';
import {ITaskSummary} from '../../../modules';
import {RoleHourAmountsTable} from './RoleHourAmountsTable';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: .875rem;

    .col {
        display: flex;
        flex-direction: column;
        margin: 0 5px;
        min-width: 50px;
        text-align: right;
        align-items: flex-end;

        &.summary {
            font-weight: bold;
            min-width: 88px;
        }
    }

    .strike {
        color: #ddd;
        text-decoration: line-through;
    }
`;

export interface IItemSummaryProps {
    summary: {[key: string]: ITaskSummary};
    showAmounts: boolean;
    strike: boolean;
}

export const ItemSummary: React.FC<IItemSummaryProps> = (props: IItemSummaryProps) => {

    const {summary = {}, showAmounts} = props;
    const {Total = {hours: 0, amount: 0}} = summary;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const taskSummary = Object.entries(summary)
        .filter(([role]) => role !== 'Total')
        .map(([role, _taskSummary]) => ({role, hours: _taskSummary.hours, amount: _taskSummary.amount, label: role}));
    
    const totalHours = Total.hours;
    const totalAmount = Total.amount;

    const fontSize = (showAmounts) ? '.65rem' : '12px';

    return (
        <Container {...props}>
            <Button variant="text" onClick={handleClick} tabIndex={-1} color='inherit' style={{fontSize}}>
                <span className={`${props.strike ? 'strike' : ''}`}>
                    <NumberFormat value={(summary.Total) ? summary.Total!.hours : 0} />&nbsp;<span>hrs</span>
                    {
                        showAmounts &&
                        <span>
                            <br/>
                            <NumberFormat value={(summary.Total) ? summary.Total!.amount : 0} isCurrency={true} />
                        </span>
                    }
                </span>
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <RoleHourAmountsTable summary={taskSummary} totalHours={totalHours} totalAmount={totalAmount} showAmounts={showAmounts} />
            </Popover>
        </Container>
    );
};
