import * as React from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Backdrop, CircularProgress, Tabs, Tab, Button} from '@mui/material';
import {ArrowBack} from '@mui/icons-material';

import {IRootStore} from '../../RootStore';
import {UsersTab, DefaultRanges, DefaultRatesAndRoles} from '../ui';

const Container = styled.div`
    background-color: #fff;
    padding: 15px;

    .button-container {
        margin-bottom: 20px;
    }

    .actions-container {
        display: flex;
        justify-content: space-between;
    }

    .backdrop {
      color: #fff;
      z-index: 1000;
    }
`;

export interface IAdminPageProps {
    
}

export const AdminPage: React.FC<IAdminPageProps> = (props: IAdminPageProps) => {
    const navigate = useNavigate();

    const [tabValue, setTabValue] = React.useState(0);

    const {isBusy} = useSelector((state: IRootStore) => state.user);
    const {user} = useSelector((state: IRootStore) => state.auth);

    const onBackClick = () => {
        navigate('/estimates');
    };

    React.useEffect(() => {
        if (user.id && !user.isAdmin) {
            navigate('/estimates');
        }
    }, [navigate, user.isAdmin, user.id]);

    return (
        <Container>

            <Backdrop className="backdrop" open={isBusy}>
                <CircularProgress />
            </Backdrop>

            <div className="button-container">
                <Button startIcon={<ArrowBack />} color="primary" onClick={onBackClick}>Back to estimates</Button>
            </div>

            <h2>Admin</h2>

            <Tabs value={tabValue} indicatorColor="primary">
                <Tab label="Users" onClick={() => setTabValue(0)} />
                <Tab label="Roles &amp; Rates" onClick={() => setTabValue(1)} />
                <Tab label="Ranges" onClick={() => setTabValue(2)} />
            </Tabs>

            {
                tabValue === 0 &&
                <UsersTab />
            }
            {
                tabValue === 1 &&
                <DefaultRatesAndRoles />
            }
            {
                tabValue === 2 &&
                <DefaultRanges />
            }
        </Container>
    );
};
