import * as React from 'react';
import {IconButton, Menu, MenuItem} from '@mui/material';
import {MoreVert} from '@mui/icons-material';

import {IUser} from '../../../modules';

export interface IUserActionsProps {
    user: IUser;
    onEditUser: (user: IUser) => void;
    onChangePassword: (user: IUser) => void;
}

export const UserActions: React.FC<IUserActionsProps> = (props: IUserActionsProps) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChangePwdClick = () => {
        props.onChangePassword(props.user);
        handleClose();
    };

    const onEditClick = () => {
        props.onEditUser(props.user);
        handleClose();
    };

    return (
        <div>
            <IconButton onClick={handleClick} size="large">
                <MoreVert />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={onEditClick}>Edit</MenuItem>
                {
                    props.user.isActive &&
                    <MenuItem onClick={onChangePwdClick}>Change password</MenuItem>
                }
            </Menu>
        </div>
    );
};
