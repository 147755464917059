import * as React from 'react';
import styled from 'styled-components';
import { RateCard, RateCardControls } from '../summary';
import { useDispatch } from 'react-redux';
import { notificationsAddAction, summaryEstimateSetAction } from '../../../modules';
import { useSelector } from 'react-redux';
import { IRootStore } from '../../../RootStore';

const Container = styled.div`
    max-width: 800px;

    .rate-card-container {
        position: relative;
        overflow: hidden;
    }

    .water-mark {
        position: absolute;
        color: rgba(255, 0, 0, .15);
        font-stretch: expanded;
        font-size: 8rem;
        width: 100%;
        text-align: center;
        transform: rotate(-10deg);
        left: 105px;
    }
`;

export interface ISalesSummaryRateCard {
}

export const SalesSummaryRateCard: React.FC<ISalesSummaryRateCard> = (props: ISalesSummaryRateCard) => {
    const dispatch = useDispatch();

    const {version} = useSelector((state: IRootStore) => state.version);
    const {status} = useSelector((state: IRootStore) => state.estimation.estimate);

    const rateCardRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        dispatch(summaryEstimateSetAction({version}));
    }, [dispatch, version]);

    const PrintRateCard = React.forwardRef<HTMLDivElement>((props, ref) => (
        <div ref={ref}>
            <RateCard />
        </div>
    ));

    const onCopyRateCardClick = async () => {
        dispatch(notificationsAddAction({store: 'estimate', dismissable: true, message: 'Rate Card copied to clipboard!', severity: 'success', timeout: 5000}));
    };
    
    return (
        <Container>
            <div className="rate-card-container">
                {
                    status === 'DRAFT' &&
                    <div className="water-mark">{ new Array(10).fill('DRAFT').join(' ') }</div>
                }
                <PrintRateCard ref={rateCardRef} />
            </div>
            <div className="controls">
                <RateCardControls onCopyImageClick={onCopyRateCardClick} rateCardRef={rateCardRef} />
            </div>
        </Container>
    );
};
