import * as React from 'react';
import {Dialog, DialogContent, DialogActions, DialogTitle, Button, TextField} from '@mui/material';

import {IUser} from '../../modules';

export interface IChangePasswordModalProps {
    user: IUser;
    open: boolean;
    onCancel: () => void;
    onSave: (password: string) => void;
}

export const ChangePasswordModal: React.FC<IChangePasswordModalProps> = (props: IChangePasswordModalProps) => {

    const [pwd, setPwd] = React.useState('');

    React.useEffect(() => {
        setPwd('');
    }, [props.open]);

    const onPwdChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = e.target;
        setPwd(value);
    };

    return (
        <div>
            <Dialog open={props.open}>
                <DialogTitle>Change password for {props.user.email}</DialogTitle>
                <DialogContent>
                    <TextField type="password" fullWidth={true} value={pwd} onChange={onPwdChange} />
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={props.onCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" disabled={(pwd.length < 1)} onClick={() => props.onSave(pwd)}>Change Password</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
