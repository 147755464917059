import {createAction, createReducer} from '@reduxjs/toolkit';
import {RatesAndRoles, Ranges} from './Models';

//
// CONSTANTS
//
export const CONFIG_GET = '[Config] Get';
export const CONFIG_GET_SUCCESS = '[Config] Get Success';
export const CONFIG_GET_ERROR = '[Config] Get Error';

export const CONFIG_UPDATE = '[Config] Update';
export const CONFIG_UPDATE_SUCCESS = '[Config] Update Success';
export const CONFIG_UPDATE_ERROR = '[Config] Update Error';

export type ConfigContext = 'defaultRolesAndRates' | 'defaultRanges';

//
// ACTIONS
//

export const getConfigAction = createAction<{context: ConfigContext}>(CONFIG_GET)
export type GetConfigAction = ReturnType<typeof getConfigAction>;

export const getConfigSuccessAction = createAction<{context: ConfigContext, content: any}>(CONFIG_GET_SUCCESS);
export type GetConfigSuccessAction = ReturnType<typeof getConfigSuccessAction>;

export const getConfigErrorAction = createAction<{message: string}>(CONFIG_GET_ERROR);
export type GetConfigErrorAction = ReturnType<typeof getConfigErrorAction>;

export const updateConfigAction = createAction<{context: ConfigContext, content: any}>(CONFIG_UPDATE);
export type UpdateConfigAction = ReturnType<typeof updateConfigAction>;


export const updateConfigSuccessAction = createAction<{context: ConfigContext, content: any}>(CONFIG_UPDATE_SUCCESS);
export type UpdateConfigSuccessAction = ReturnType<typeof updateConfigSuccessAction>;

export const updateConfigErrorAction = createAction<{message: string}>(CONFIG_UPDATE_ERROR);
export type UpdateConfigErrorAction = ReturnType<typeof updateConfigErrorAction>;

export type ConfigActionsType = 
    GetConfigAction
    | GetConfigSuccessAction
    | GetConfigErrorAction
    | UpdateConfigAction
    | UpdateConfigSuccessAction
    | UpdateConfigErrorAction;

//
// STATE
//

export interface IConfigState {
    isBusy: boolean;
    message: string;
    context: ConfigContext | '';
    rolesAndRates: RatesAndRoles;
    ranges: Ranges;
}

const initialState: IConfigState = {
    isBusy: false,
    message: '',
    context: '',
    rolesAndRates: [],
    ranges: {
        high: 0,
        low: 0
    }
};

//
// REDUCER
//
export const configReducer = createReducer(initialState, (builder) => builder
    .addCase(getConfigAction, (state, action) => ({...state, isBusy: true, context: action.payload.context}))
    .addCase(getConfigSuccessAction, (state, action) => {
        const newState = {...state, isBusy: false, rolesAndRates: [...state.rolesAndRates], ranges: {...state.ranges}};

        if (action.payload.context === 'defaultRolesAndRates') {
            newState.rolesAndRates = action.payload.content.content;
        } else if (action.payload.context === 'defaultRanges') {
            newState.ranges = action.payload.content.content;
        }

        return newState;
    })
    .addCase(getConfigErrorAction, (state, action) => ({...state, isBusy: false, message: action.payload.message}))
    .addCase(updateConfigAction, (state, action) => {
        const newState = {...state, isBusy: true, rolesAndRates: [...state.rolesAndRates], ranges: {...state.ranges}};

            if (action.payload.context === 'defaultRolesAndRates') {
                newState.rolesAndRates = action.payload.content;
            } else if (action.payload.context === 'defaultRanges') {
                newState.ranges = action.payload.content;
            }

            return newState;
    })
    .addCase(updateConfigSuccessAction, (state, action) => {
        const newState = {...state, isBusy: false, rolesAndRates: [...state.rolesAndRates], ranges: {...state.ranges}};

            if (action.payload.context === 'defaultRolesAndRates') {
                newState.rolesAndRates = action.payload.content.content;
            } else if (action.payload.context === 'defaultRanges') {
                newState.ranges = action.payload.content.content;
            }

            return newState;
    })
    .addCase(updateConfigErrorAction, (state, action) => ({...state, isBusy: false, message: action.payload.message}))
);

