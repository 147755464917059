import * as React from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {Switch, FormControlLabel, Button} from '@mui/material';

import {IRootStore} from '../../../RootStore';
import {userListAction, IUser, userUpdateAction, userChangePasswordAction} from '../../../modules';
import {UserList} from './UserList';
import {EditUserModal, ChangePasswordModal} from '../../modals';


const Container = styled.div`
    background-color: #fff;
    padding: 15px;

    .actions-container {
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }

    .backdrop {
        color: #fff;
        z-index: 1000;
    }
`;

export interface IUsersTab {

}

export const UsersTab: React.FC<IUsersTab> = (props: IUsersTab) => {
    const dispatch = useDispatch();

    const [onlyActiveUsers, setOnlyActiveUsers] = React.useState(true);
    const [editingUser, setEditingUser] = React.useState<IUser>({} as IUser);
    const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
    const [isChangePwdModalOpen, setIsChangePwdModalOpen] = React.useState(false);

    const {userList} = useSelector((state: IRootStore) => state.user);

    React.useEffect(() => {
        dispatch(userListAction());
    }, [dispatch]);

    const onEditUser = (user: IUser) => {
        setEditingUser(user);
        setIsEditModalOpen(true);
    };

    const onChangePassword = (user: IUser) => {
        setEditingUser(user);
        setIsChangePwdModalOpen(true);
    };

    const onEditUserModalCancel = () => {
        setIsEditModalOpen(false);
    };

    const onEditUserModalSave = (user: IUser) => {
        setIsEditModalOpen(false);
        dispatch(userUpdateAction({user}));
    };

    const onNewUserButtonClick = () => {
        setIsEditModalOpen(true);
        setEditingUser({} as IUser);
    };

    const onChangePwdModalCancel = () => {
        setIsChangePwdModalOpen(false);
    };

    const onChangePwdModalSave = (password: string) => {
        dispatch(userChangePasswordAction({user: editingUser, password, isAdmin: true}));
        setIsChangePwdModalOpen(false);
    };

    return(
        <Container>
            <div className="actions-container">
                <Button variant="contained" color="primary" onClick={onNewUserButtonClick}>Add User</Button>
                <FormControlLabel
                    label={`${(!onlyActiveUsers) ? 'Hide' : 'Show'} inactive users`}
                    control={<Switch checked={!onlyActiveUsers} onChange={() => setOnlyActiveUsers(!onlyActiveUsers)} />}
                />

            </div>
            <UserList users={userList} onlyActiveUsers={onlyActiveUsers} onEditUser={onEditUser} onChangePassword={onChangePassword} />
            <EditUserModal user={editingUser} open={isEditModalOpen} onCancel={onEditUserModalCancel} onSave={onEditUserModalSave} />
            <ChangePasswordModal user={editingUser} open={isChangePwdModalOpen} onCancel={onChangePwdModalCancel} onSave={onChangePwdModalSave} />
        </Container>
    );
};
