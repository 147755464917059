import { TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { reportingListAction, StatusType } from '../../modules';
import { IRootStore } from '../../RootStore';
import { NumberFormat, StatusIndicator, StatusSelect } from '../ui';

const Container = styled.div`

    background-color: #fff;
    padding: 20px;
    min-height: 600px;

    .options-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        margin: 10px 20px 20px 20px;
        
        > DIV {
            width: 50%;
        }
    }

    .tile-container {
        border-top: 1px solid #eee;
        padding-top: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .tile {
        border: 2px solid #999;
        margin-right: 40px;
        margin-bottom: 40px;
        min-width: 350px;
        border-radius: 4px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        /* min-height: 300px; */

        .status {
            text-align: center;
            background-color: #eee;
        }

        .count {
            text-align: center;
            margin: 10px;
        }
        
        .hours-amount {
            display: flex;
            justify-content: space-between;
            padding: 5px 10px;
        }
    }

`;

export interface IReportingPage {
}

export const ReportingPage: React.FC<IReportingPage> = (props: IReportingPage) => {
    const dispatch = useDispatch();

    const [statuses, setStatuses] = React.useState<StatusType[]>([]);
    const [daysAgo, setDaysAgo] = React.useState(90);

    const {summary} = useSelector((state: IRootStore) => state.reporting);

    React.useEffect(() => {
        dispatch(reportingListAction({daysAgo, statuses: []}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDaysAgoChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = event.target;
        const val = parseInt(value, 10);
        setDaysAgo(val);
        // dispatch(reportingListAction({daysAgo: val, statuses}));
    };

    const onDaysAgoBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(reportingListAction({daysAgo, statuses}));
    };


    const onStatusChange = (_statuses: StatusType[]) => {
        setStatuses(_statuses);
        dispatch(reportingListAction({daysAgo, statuses: _statuses}));
    };

    const tileEls = Object.entries(summary)
        .map(([status, summary]) => (
            <div className="tile">
                <div className="status">
                    <Typography variant="h4">
                        <StatusIndicator status={status as StatusType} showText={true} />
                    </Typography>
                </div>
                <div>
                    <div className="count">
                        <Typography variant="h4">
                            {summary.count}
                        </Typography>
                    </div>
                    
                    <Typography variant="h5">
                        <div className="hours-amount">
                            <span>{summary.hours} hours</span>
                            <span>$<NumberFormat value={summary.amount} /></span>
                        </div>
                    </Typography>
                </div>
            </div>
        ));

    return (
        <Container>
            <div className="options-container">
                <div>
                    <TextField
                        type="number"
                        label="Days"
                        value={daysAgo}
                        onChange={onDaysAgoChange}
                        onBlur={onDaysAgoBlur}
                    />
                </div>
                <div>
                    <StatusSelect onChange={onStatusChange} />
                </div>
            </div>
            <div className="tile-container">
                {tileEls}
            </div>
        </Container>
    );
};