import { Button, FormControl, FormControlLabel, Icon, Popover, Radio, RadioGroup, TextField } from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { EstimateDecision, estimationAddDecisionAction } from '../../../modules';
import { useSelector } from 'react-redux';
import { IRootStore } from '../../../RootStore';

const Container = styled.div`

`;

const PopoverContent = styled.div`
    min-width: 300px;
    padding: 20px;

    .button-container {
        margin-top: 10px;
        text-align: right;
        
        > BUTTON {
            margin-left: 10px;
        }
    }
`;

export interface IApproval {
    disabled: boolean;
}

export const Approval: React.FC<IApproval> = (props: IApproval) => {
    const {disabled} = props;
    const dispatch = useDispatch();

    const {id, approvals} = useSelector((state: IRootStore) => state.estimation.estimate);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [value, setValue] = React.useState<EstimateDecision|''>('');
    const [comment, setComment] = React.useState('');

    const latestApproval = [...approvals].pop();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value as EstimateDecision);
    };

    const onCancelClick = () => {
        setAnchorEl(null);
        setValue('');
        setComment('');
    };

    const onSubmitClick = () => {
        if (value !== '') {
            dispatch(estimationAddDecisionAction({id, action: value, comment}));
            onCancelClick();
        }
    };

    const isSubmitEnabled = value === 'APPROVED' || (value === 'CHANGES_REQUESTED' && comment.length > 0);

    const getButtonText = () => {
        if (!latestApproval) {
            return 'Add Review';
        } else if (latestApproval?.action === 'APPROVED') {
            return 'Approved';
        } else {
            return 'Changes Requested';
        }
    };

    const getButtonColor = () => {
        if (!latestApproval) {
            return 'primary';
        } else if (latestApproval?.action === 'APPROVED') {
            return 'primary';
        } else {
            return 'secondary';
        }
    };

    const getButtonStyle = () => {
        if (!latestApproval) {
            return 'text';
        } else if (latestApproval?.action === 'APPROVED') {
            return 'text';
        } else {
            return 'outlined';
        }
    };

    return (
        <Container>
            <Button aria-describedby={id} variant={getButtonStyle()} color={getButtonColor()} onClick={handleClick} disabled={disabled}>
                <span>{getButtonText()}</span>
                <Icon>arrow_drop_down</Icon>
            </Button>
            <Popover
                // id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <PopoverContent>
                    <FormControl fullWidth={true}>
                        {/* <FormLabel>Estimate Approval</FormLabel> */}
                        <RadioGroup
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="APPROVED" control={<Radio />} label="Approved" />
                            <FormControlLabel value="CHANGES_REQUESTED" control={<Radio />} label="Request Changes" />
                        </RadioGroup>
                        <TextField 
                            multiline={true}
                            value={comment}
                            placeholder="Provide feedback"
                            required={value === 'CHANGES_REQUESTED'}
                            rows={4}
                            error={value === 'CHANGES_REQUESTED' && comment.length < 1}
                            onChange={(e) => setComment(e.target.value)}
                        />
                        <div className="button-container">
                            <Button variant="text" onClick={onCancelClick}>Cancel</Button>
                            <Button variant="contained" disabled={!isSubmitEnabled} onClick={onSubmitClick}>Submit</Button>
                        </div>
                    </FormControl>
                </PopoverContent>
            </Popover>
        </Container>
    );
};
