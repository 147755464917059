import { Alert, Stack, Switch, Typography } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    .alert-container {
        margin-bottom: 20px;
    }
`;


type ModeType = 'COMPUTED' | 'MANUAL';

export interface IModeSwitcher {
    mode: ModeType;
    onChange: (mode: ModeType) => void;
    displayText?: boolean
}

export const ModeSwitcher: React.FC<IModeSwitcher> = (props: IModeSwitcher) => {
    const {mode, onChange, displayText} = props;

    const onModeChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const val = checked ? 'COMPUTED' : 'MANUAL';
        onChange(val);
    };

    return (
        <Container>
            {
                displayText &&
                <>
                    <div className="alert-container">
                        <Alert severity="warning">Changing the mode will update all tasks!</Alert>
                    </div>
                    <p>The mode controls how hours are calculated on tasks.</p>
                </>
            }
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Manual</Typography>
                <Switch onChange={onModeChange} checked={mode === 'COMPUTED'} />
                <Typography>Computed</Typography>
            </Stack>
        </Container>
    );
};
