import * as React from 'react';
import styled from 'styled-components';
import { IRootStore } from '../../../RootStore';
import { useSelector } from 'react-redux';
import {FormControlLabel, IconButton, Switch, TextField} from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { calculateBurndownLine, calculateRateCard } from '../../../modules';
import { DateTime } from 'luxon';
import { Add, Close } from '@mui/icons-material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const CHART_COLORS = [
    '0, 110, 144',
    // '24, 108, 163',
    // '73, 102, 174',
    '121, 90, 173',
    // '163, 71, 157',
    '195, 46, 127',
    // '213, 25, 87',
    '214, 40, 40',
    '168, 100, 0',
    '113, 127, 0',
    '54, 141, 76',
    '0, 144, 106'
];

const Container = styled.div`
    .chart-container {
        margin-bottom: 20px;
    }

    .settings-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .setting-line {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        .settings-top-line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        INPUT {
            width: 100px;
        }

        .role-name {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 15px;
        }

        .resources-container {
            .resource-line {
                margin-top: 10px;

                .remove-resource {
                    display: inline-block;
                    width: 50px;
                }
            }

            .date-picker {
                INPUT {
                    padding: 8px 14px;
                }
            }
        }
    }
`;

interface _IRoleSetting {
    // count: number;
    // hoursPerDay: number;
    name: string;
    enabled: boolean;
    // startDate: DateTime;
    resources: _IResourceSetting[];
}

interface _IResourceSetting {
    name: string;
    hoursPerDay: number;
    startDate: DateTime;
    endDate?: DateTime;
}

export interface IProjectPlan {
}

export const ProjectPlan: React.FC<IProjectPlan> = (props: IProjectPlan) => {

    const [projectStartDate, setProjectStartDate] = React.useState<DateTime | null>(DateTime.now());
    const [roleSettings, setRoleSettings] = React.useState<{[key: string]: _IRoleSetting}>({});

    const {version} = useSelector((state: IRootStore) => state.version);

    const {settings} = version;

    React.useEffect(() => {
        const _settings: {[key: string]: _IRoleSetting} = {}

        settings.roles
            .filter(x => x.enabled)
            .forEach((role) => {
                const resources = [{name: 'r1', hoursPerDay: 8, startDate: DateTime.now()}]
                _settings[role.role] = {name: role.name, enabled: true, resources};
            });
            
        setRoleSettings(_settings);

    }, [settings]);

    // const updateCount = (role: string, value: string) => {
    //     const _settings = {...roleSettings};
    //     const val = parseFloat(value);
    //     // _settings[role].count = val < .5 ? .5 : val
    //     _settings[role].count = isNaN(val) ? 0 : val
    //     setRoleSettings(_settings);
    // };

    const updateEnabled = (role: string, value: boolean) => {
        const _settings = {...roleSettings};
        _settings[role].enabled = value;
        setRoleSettings(_settings);
    };

    const updateHoursPerDay = (role: string, index: number, value: string) => {
        const _settings = {...roleSettings};
        const val = parseFloat(value);
        // _settings[role].hoursPerDay = val < .5 ? .5 : val;
        _settings[role].resources[index].hoursPerDay = isNaN(val) ? 0 : val;
        setRoleSettings(_settings);
    };

    const updateName = (role: string, index: number, value: string) => {
        const _settings = {...roleSettings};
        _settings[role].resources[index].name = value;
        setRoleSettings(_settings);
    };
    
    const updateRoleStartDate = (role: string, index: number, val: DateTime | null) => {
        if (val === null || val < projectStartDate!) {
            return;
        }

        const _settings = {...roleSettings};


        const {startDate: _startDate, endDate: _endDate} = _settings[role].resources[index];

        if (_endDate) {
            const diff = _endDate.diff(_startDate, ['days']).toObject();
            const {days} = diff;

            _settings[role].resources[index].endDate = val?.plus({days});
        }


        _settings[role].resources[index].startDate = val;
        setRoleSettings(_settings);
    };

    const updateRoleEndDate = (role: string, index: number, val: DateTime | null) => {
        const _settings = {...roleSettings};
        if (val === null || val < projectStartDate!) {
            _settings[role].resources[index].endDate = undefined;
        } else {
            _settings[role].resources[index].endDate = val;
        }

        setRoleSettings(_settings);
    };

    const onStartDateChange = (val: DateTime | null) => {
        if (val === null) {
            return;
        }

        setProjectStartDate(val);

        const _settings = {...roleSettings};
        Object.entries(_settings)
            .forEach(([role, setting]) => {
                _settings[role].resources.forEach((r, idx) => {
                    const {startDate: _startDate, endDate: _endDate} = _settings[role].resources[idx];

                    if (_endDate) {
                        const diff = _endDate.diff(_startDate, ['days']).toObject();
                        const {days} = diff;

                        _settings[role].resources[idx].endDate = val?.plus({days});
                    }

                    _settings[role].resources[idx].startDate = val;
                });
            });
        setRoleSettings(_settings);
    };

    const addResource = (role: string) => {
        const _settings = {...roleSettings};

        _settings[role].resources.push({
            name: 'name',
            hoursPerDay: 8,
            startDate: projectStartDate!
        });

        setRoleSettings(_settings);
    }

    const removeResource = (role: string, index: number) => {
        const _settings = {...roleSettings};

        if (_settings[role].resources.length > 1) {

            _settings[role].resources.splice(index, 1);
            setRoleSettings(_settings);
        }

    }

    const rateCard = calculateRateCard(version);
    const labelSet: string[] = [];

    const datasets = Object.entries(rateCard)
        .filter(([role, val]) => role !== 'Total' && val.hours > 0)
        .map(([role, value], index) => {

            const _setting = roleSettings[role];

            let data: {x: string | null, y: number}[] = [];

            if (_setting && _setting.enabled) {

                const inputs = _setting.resources.map(r => ({startDate: r.startDate.toJSDate(), decrementVal: r.hoursPerDay, endDate: r.endDate?.toJSDate()}));

                data = calculateBurndownLine(inputs, value.hours);
                data.forEach((_data) => { 
                    if (_data.x) {
                        labelSet.push(_data.x);
                    } 
                });
            }

            const colorIndex = isNaN(CHART_COLORS.length % index)
                ? CHART_COLORS.length - 1
                : CHART_COLORS.length % index;

            const color = CHART_COLORS[colorIndex];

            return {
                label: role,
                data,
                borderColor: `rgb(${color})`,
                backgroundColor: `rgba(${color}, 0.5)`,
                options: {
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'day'
                            },
                            min: projectStartDate?.toSQL()
                        },
                    }
                }
            };
        });

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Project Burndown',
          },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Hours'
                }
            }
        }
      };
      

    const labels = Array.from(
        new Set(
            labelSet.sort((a, b) => {
                const d1 = DateTime.fromSQL(a);
                const d2 = DateTime.fromSQL(b);

                if (d1 > d2) { return 1; }
                else if (d1 < d2) { return -1; }
                else { return 0; }
            })
        )
    );

    const data = {
        labels,
        datasets,
    };

    const roleSettingsEls = Object.entries(roleSettings)
        .map(([role, setting], index) => (
            <div className="setting-line" key={index}>
                <div className="settings-top-line">
                    <span className="role-name">
                        {setting.name}({role}/{rateCard[role].hours || 0})
                        <IconButton color="primary" onClick={() => addResource(role)}>
                            <Add />
                        </IconButton>
                    </span>
                    <FormControlLabel
                        label={`Enabled`}
                        control={<Switch checked={setting.enabled} 
                            onChange={(event, checked) => updateEnabled(role, checked) } 
                        />}
                    />
                </div>
                <div className="resources-container">
                {
                    setting.resources.map((s, idx) => (
                        <div className="resource-line" key={idx}>
                            <div className="remove-resource">
                                {
                                    setting.resources.length > 1 &&
                                    <IconButton 
                                        color="error" 
                                        // disabled={setting.resources.length <= 1}
                                        onClick={() => removeResource(role, idx)}
                                    >
                                        {/* <Remove /> */}
                                        <Close />
                                    </IconButton>
                                }
                            </div>
                            <TextField
                                label="Name"
                                size="small"
                                type="text"
                                onChange={(event) => updateName(role, idx, event.currentTarget.value)}
                                value={s.name}
                            />
                            <TextField
                                label="Hrs/Day"
                                type="number"
                                size="small"
                                value={s.hoursPerDay}
                                onChange={(event) => updateHoursPerDay(role, idx, event.currentTarget.value)}
                                inputProps={{min: .5, max: 10, step: .5}}
                            />
                            <DatePicker
                                label="Role Start Date" 
                                className="date-picker"
                                // slotProps={{
                                //     field: { clearable: true },
                                // }}
                                value={s.startDate} 
                                onChange={(val) => updateRoleStartDate(role, idx, val)} 
                            />
                            <DatePicker 
                                label="Role End Date" 
                                className="date-picker"
                                value={s.endDate} 
                                // slotProps={{
                                //     field: { clearable: true },
                                // }}
                                onChange={(val) => updateRoleEndDate(role, idx, val)} 
                            />
                        </div>
                    ))
                }
                {/* <TextField
                    label="Hrs/Day"
                    type="number"
                    value={setting.hoursPerDay}
                    onChange={(event) => updateHoursPerDay(role, event.currentTarget.value)}
                    inputProps={{min: .5, max: 10, step: .5}}
                />
                <DatePicker label="Role Start Date" value={startDate} onChange={(val) => updateRoleStartDate(role, val)} /> */}
                </div>
            </div>
        ));

    return (
        <Container>

            <div className="chart-container">
                <Line options={options} data={data} />
            </div>

            <div className="settings-container">
                <div>
                    <DatePicker label="Project Start Date" value={projectStartDate} onChange={onStartDateChange} />
                </div>

                <div>
                    {roleSettingsEls}
                </div>
            </div>

        </Container>
    );
};
