import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import {Button, Icon, Tooltip} from '@mui/material';

const Container = styled.div`
`;

export interface ICopyImageButton {
    onClick: () => void;
    elementRef: React.RefObject<HTMLElement>;
}

export const CopyImageButton: React.FC<ICopyImageButton> = (props: ICopyImageButton) => {

    const [isDisabled, setIsDisabled] = React.useState(false);

    React.useEffect(() => {
        try {
            // Check to see if the browser supports the Clipboard API
            // FireFox does not currently support this.
            new ClipboardItem({'image/png': Promise.resolve('')});
        } catch (e) {
            console.warn('Browser does not have Clipboard API enabled!');
            setIsDisabled(true);
        }
    }, []);

    
    const copyImageToClipboard = async () => {
        const {elementRef, onClick} = props;

        if(!elementRef.current) {
            throw new Error("'node' must be a RefObject")
        }
    
        const element = ReactDOM.findDOMNode(elementRef.current);

        if (!element) {
            throw new Error('Element is not defined');
        }

        return html2canvas(element as HTMLElement, {
            scrollY: -window.scrollY,
            useCORS: true,
        }).then(canvas => {
            const imageType = 'image/png';

            canvas.toBlob(async (blob) => {
                if (!blob) {
                    console.warn('blob is null');
                    return;
                }

                await navigator.clipboard.write([
                    new ClipboardItem({'image/png': Promise.resolve(blob)})
                ]);

                onClick()
            }, imageType, 1);
        });
    };


    return (
        <Container>
            {
                !isDisabled &&
                <Button color="primary" variant="contained" onClick={copyImageToClipboard} disabled={isDisabled}>
                    <Icon>file_copy</Icon>&nbsp;&nbsp;Copy to Clipboard
                </Button>
            }
            {
                isDisabled &&
                <Tooltip title="Your browser does not support this feature" placement="top">
                    <span>
                        <Button color="primary" variant="contained" disabled={isDisabled}>
                            <Icon>file_copy</Icon>&nbsp;&nbsp;Copy to Clipboard
                        </Button>
                    </span>
                </Tooltip>
            }
        </Container>
    );
};