import * as React from 'react';
import {HashRouter, Routes, Route} from 'react-router-dom';
import styled from 'styled-components';

import {LoginPage, ListPage, EstimatePage, EstimateSummaryPage, AdminPage, ReportingPage, Editor} from './components/pages';
import {ProtectedRoute, Header, NotificationCenter} from './components';

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #eee;
    margin-top: 1px;
    /* min-height: calc(100vh - 122px); */
    min-height: calc(100vh - 61px);
    height: 100%;

    .header-container {
        position: sticky;
        top: 0;
    }
`;

const HeaderContainer = styled.div`
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
`;
export interface IAppRoutesProps {
    children?: React.ReactNode;
}

export const AppRoutes: React.FC<IAppRoutesProps> = (props: IAppRoutesProps) => {

    return (
        <HashRouter>
            <HeaderContainer className="header-container">
                <Header />
            </HeaderContainer>

            <NotificationCenter />
            
            <PageContainer>
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/estimates" element={
                        <ProtectedRoute>
                            <ListPage />
                        </ProtectedRoute>
                    } />
                    <Route path="/estimate/:id/review" element={
                        <ProtectedRoute>
                            <EstimateSummaryPage />
                        </ProtectedRoute>
                    } />
                    <Route path="/estimate/:id" element={
                        <ProtectedRoute>
                            <EstimatePage />
                        </ProtectedRoute>
                    } />
                    <Route path="/reporting" element={
                        <ProtectedRoute>
                            <ReportingPage />
                        </ProtectedRoute>
                    } />
                    <Route path="/admin" element={
                        <ProtectedRoute>
                            <AdminPage />
                        </ProtectedRoute>
                    } />
                    <Route path="/editor" element={
                        <ProtectedRoute>
                            <Editor />
                        </ProtectedRoute>
                    } />
                </Routes>
            </PageContainer>
        </HashRouter>
    );
};
