import { Business, Chat, Edit, FormatListBulleted, History, KeyboardArrowLeftSharp, KeyboardArrowRightSharp, Sell, Summarize, TrendingDown, Verified } from '@mui/icons-material';
import { Badge, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

type NavItem = 'info' | 'settings' | 'tasks' | 'approvals' | 'assumptions' | 'comments' | 'history' | 'projectPlan' | 'rateCard' | 'taskSummary';
// TODO: Remove the 'any'
type Item = {name: string, icon: JSX.Element, action: (event: React.MouseEvent<any, MouseEvent>) => void};

const Container = styled.div`
    .drawer-header {
        display: flex; 
        align-items: center; 
        justify-content: flex-end; 
        padding: 5px;
    }
`;

export interface IEstimationNavPanel {
    onClick: (action: NavItem) => void;
    readOnly: boolean;
    counts?: {[key: string]: number}
}

export const EstimationNavPanel: React.FC<IEstimationNavPanel> = (props: IEstimationNavPanel) => {

    const {onClick, counts, readOnly} = props;

    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const drawerWidth = drawerOpen ? '200px' : '50px';

    const mapIconEl = (item: Item) => ((
        <ListItem key={item.name} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
                onClick={item.action}
                sx={{
                    minHeight: 48,
                    justifyContent: drawerOpen ? 'initial' : 'center',
                    px: 2.5,
                }}
            >
                <Tooltip title={item.name}>
                    <span style={{display: 'flex', alignItems: 'center'}}>
                        
                        {
                            counts && counts[item.name] && counts[item.name] !== 0 ?
                            // <Badge badgeContent={counts[item.name]} color="primary" sx={{mr: drawerOpen ? 2 : 'auto'}}>
                            <Badge badgeContent={' '} variant="dot" color="primary" sx={{mr: drawerOpen ? 2 : 'auto'}}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 'auto', //drawerOpen ? 3 : 'auto',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                            </Badge>
                            :
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: drawerOpen ? 2 : 'auto',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                {item.icon}
                            </ListItemIcon>
                        }
                        {
                            drawerOpen && <Typography variant="overline">{item.name}</Typography>
                        }
                    </span>
                </Tooltip>
            {/* <ListItemText primary={x.name} sx={{ opacity: open ? 1 : 0 }} /> */}
            </ListItemButton>
        </ListItem>
    ));

    const navItemsEls = [
        { name: 'Tasks & Hours', icon: <FormatListBulleted />, action: () => onClick('tasks') },
        { name: 'Approvals', icon: <Verified />, action: () => onClick('approvals') },
        { name: 'Assumptions', icon: <Business />, action: () => onClick('assumptions') },
        { name: 'Comments', icon: <Chat />, action: () => onClick('comments') },
        { name: 'History', icon: <History />, action: () => onClick('history') },
        { name: 'Project Plan', icon: <TrendingDown />, action: () => onClick('projectPlan') },
    ]
    .map(mapIconEl);

    const summaryItemEls = [
        { name: 'Rate Card', icon: <Sell color="primary" />, action: () => onClick('rateCard') },
        { name: 'Task Summary', icon: <Summarize color="primary" />, action: () => onClick('taskSummary') },
    ]
    .map(mapIconEl);

    const settingItemEls = [
        { name: 'Project Info', icon: <Edit />, action: () => onClick('info') },
    ]
    .map(mapIconEl);
    

    return (
        <Container style={{width: drawerWidth}}>
            <Box sx={{ display: 'flex' }}>
                <Drawer variant="permanent" open={drawerOpen}
                    sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', top: '60px', zIndex: 0 },
                }}>
                    <div className="drawer-header">
                        <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
                            {
                                drawerOpen 
                                ? <KeyboardArrowLeftSharp />
                                : <KeyboardArrowRightSharp />
                            }
                        </IconButton>
                    </div>

                    <Divider />

                    {
                        !readOnly &&
                        <>
                            <List>
                                {settingItemEls}
                            </List>
                            
                            <Divider />
                        </>
                    }
                    
                    <List>
                        {navItemsEls}
                    </List>
                    
                    <Divider />
                    
                    <List>
                        {summaryItemEls}
                    </List>
                </Drawer>
            </Box>
        </Container>
    );
};
