import * as React from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {Table, TableRow, TableCell, TableHead, TableBody, TableFooter} from '@mui/material';
import { IRoleSetting } from '../../../modules';

import {NumberFormat} from '..';
import { IRootStore } from '../../../RootStore';

const RoleMap: {[key: string]: string} = {
    'BA': 'Business Analyst',
    'IA': 'Information Architect/UI/UX',
    'DEV': 'Engineer',
    'PM': 'Project Manager',
    'QA': 'Quality Engineer',
    'SA': 'Solutions Architect',
    'TL': 'Team Lead',
    'PL': 'Practice Lead'
};

const Container = styled.div`

    TABLE {
        border: 1px solid #ccc;

        TH {
            border: none;
        }
    }

    .top-row {
        background-color: #327ac2;
        color: white;
        font-weight: bold;
    }

    .second-row {
        background-color: #7c9fc2;
        color: white;
        font-weight: bold;
    }

    .header-label {
        color: white;
        font-weight: bold;
        font-size: 1.2rem;
    }

    .total {
        font-weight: bold;
        color: #000;
        font-size: 14px;
    }
`;

export interface IRateCard {
    ref?: any;
}

export const RateCard: React.FC<IRateCard> = (props: IRateCard) => {
    
    const {settings, summary, version} = useSelector((state: IRootStore) => state.summary);
    
    const {isMidAvailable, columns} = settings;
    const showMid = (isMidAvailable && columns.mid);
    const showLow = columns.low;
    const showHigh = columns.high;


    const roleMap: {[key: string]: IRoleSetting} = {};

    if (version) {
        version.settings.roles.forEach(x => {
            roleMap[x.role] = x;
        });
    }

    const amountsEl = Object.keys(summary)
        .filter(x => x !== 'Total')
        .filter(x => settings.enabledRoles.includes(x))
        .sort((a, b) => {
            const rate1 = roleMap[a].rate;
            const rate2 = roleMap[b].rate;

            if (rate1 > rate2) return -1;
            else if (rate1 < rate2) return 1;
            else return 0;
        })
        .map((x, index) => (
            <TableRow key={index}>
                <TableCell>
                    <span contentEditable={true}>{ roleMap[x].name || RoleMap[x] }</span>
                </TableCell>
                <TableCell>
                    <NumberFormat value={roleMap[x].rate} isCurrency={true} />
                </TableCell>
                {
                    showLow &&
                    <TableCell align={settings.dataPosition}>
                        <NumberFormat value={ summary[x]!.low!.hours } />
                    </TableCell>
                }
                {
                    showMid &&
                    <TableCell align={settings.dataPosition}>
                        <NumberFormat value={ summary[x]!.hours } />
                    </TableCell>
                }
                {
                    showHigh &&
                    <TableCell align={settings.dataPosition}>
                        <NumberFormat value={ summary[x]!.high!.hours } />
                    </TableCell>
                }
                {
                    showLow &&
                    <TableCell align={settings.dataPosition}>
                        <NumberFormat value={ summary[x]!.low!.amount } isCurrency={true} />
                    </TableCell>
                }
                {
                    showMid &&
                    <TableCell align={settings.dataPosition}>
                        <NumberFormat value={ summary[x]!.amount } isCurrency={true} />
                    </TableCell>
                }
                {
                    showHigh &&
                    <TableCell align={settings.dataPosition}>
                        <NumberFormat value={ summary[x]!.high!.amount } isCurrency={true} />
                    </TableCell>
                }
            </TableRow>
        ));

    const totalHours = (summary.Total?.hours) ? summary.Total!.hours : 0;
    const totalAmount = (summary.Total?.amount) ? summary.Total!.amount : 0;

    const totalLow = (summary.Total?.low) ? summary.Total!.low : {hours: 0, amount: 0};
    const totalHigh = (summary.Total?.high) ? summary.Total!.high : {hours: 0, amount: 0};

    const colspan = [columns.high, columns.mid, columns.low]
                .filter(x => x === true)
                .reduce((acc, cur) => acc + 1, 0);

    const secondRowHeadersEl = (
        <>
            {
                showLow &&
                <TableCell align={settings.dataPosition}>
                    {
                        colspan > 1 &&
                        <span className="header-label">Low</span>
                    }
                </TableCell>
            }
            {
                showMid &&
                <TableCell align={settings.dataPosition}>
                    {
                        colspan > 1 &&
                        <span className="header-label">Mid</span>
                    }
                </TableCell>
            }
            {
                showHigh && 
                <TableCell align={settings.dataPosition}>
                    {
                        colspan > 1 &&
                        <span className="header-label">High</span>
                    }
                </TableCell>
            }
        </>
    );

    return (
        <Container>
           <div>
                <Table size="small">
                    <TableHead>
                        <TableRow className="top-row">
                            <TableCell colSpan={2}></TableCell>
                            <TableCell colSpan={colspan} align="center">
                                <span className="header-label">Est. Hours</span>
                            </TableCell>
                            <TableCell colSpan={colspan} align="center">
                                <span className="header-label">Est. Budget</span>
                            </TableCell>
                        </TableRow>
                        <TableRow className="second-row">
                            <TableCell>
                                <span className="header-label">Project Role</span>
                            </TableCell>
                            <TableCell>
                                <span className="header-label">Rate</span>
                            </TableCell>
                            
                            {/* Yes, the following elements are in here twice. This is intentional!!! */}
                            {secondRowHeadersEl}
                            {secondRowHeadersEl}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            amountsEl
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow className="totals">
                            <TableCell className="total">Total</TableCell>
                            <TableCell></TableCell>
                            {
                                showLow &&
                                <TableCell align={settings.dataPosition}>
                                    <span className="total">
                                        <NumberFormat value={ totalLow.hours } />
                                    </span>
                                </TableCell>
                            }
                            {
                                showMid &&
                                <TableCell align={settings.dataPosition}>
                                    <span className="total">
                                        <NumberFormat value={ totalHours } />
                                    </span>
                                </TableCell>
                            }
                            {
                                showHigh &&
                                <TableCell align={settings.dataPosition}>
                                    <span className="total">
                                        <NumberFormat value={ totalHigh.hours } />
                                    </span>
                                </TableCell>
                            }
                            {
                                showLow &&
                                <TableCell align={settings.dataPosition}>
                                    <span className="total">
                                        <NumberFormat value={ totalLow.amount } isCurrency={true} />
                                    </span>
                                </TableCell>
                            }
                            {
                                showMid &&
                                <TableCell align={settings.dataPosition}>
                                    <span className="total">
                                        <NumberFormat value={ totalAmount } isCurrency={true} />
                                    </span>
                                </TableCell>
                            }
                            {
                                showHigh &&
                                <TableCell align={settings.dataPosition}>
                                    <span className="total">
                                        <NumberFormat value={ totalHigh.amount } isCurrency={true} />
                                    </span>
                                </TableCell>
                            }
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        </Container>
    );
};
