import * as React from 'react';
import styled from 'styled-components';
import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TablePagination} from '@mui/material';

import {IPagination, IEstimateWithVersion, IEstimate} from '../../../modules';
import {EstimateActions, StatusIndicator, NumberFormat} from '../../ui';

const Container = styled.div`

    TBODY > TR {
        &:hover {
            background-color: #eee;
            cursor: pointer;
        }
    }
`;

export interface IEstimateListProps {
    estimates: IEstimateWithVersion[];
    pagination: IPagination;
    page: number;
    size: number;
    onEstimateClick: (id: string) => void;
    onPaginationChange: (page: number, size: number) => void;
}

export const EstimateList: React.FC<IEstimateListProps> = (props: IEstimateListProps) => {

    const {estimates, pagination, page, size, onEstimateClick, onPaginationChange} = props;

    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

    const onChangePage = (event: any, page: number) => {
        onPaginationChange(page + 1, size);
    };

    const onChangeRowsPerPage = (event: any) => {
        const size = parseInt(event.target.value, 10);
        onPaginationChange(page, size);
    };

    
    const rowEls = estimates.map((estimate, index) => {

        // const summary: {[key: string]: ITaskSummary} = calculateRateCard(estimate);


        return (
            <TableRow key={index} onClick={() => onEstimateClick(estimate.id)}>
                <TableCell>{estimate.client}</TableCell>
                <TableCell>{estimate.projectCode}: {estimate.title}</TableCell>
                <TableCell align="center">{estimate.version.version}</TableCell>
                <TableCell align="center">
                    <StatusIndicator status={estimate.status} showText={false} />
                </TableCell>
                <TableCell align="right">
                    <NumberFormat value={estimate.version.summary.hours || 0} />
                    {/* <span>TBD</span> */}
                </TableCell>
                <TableCell align="right">
                    <NumberFormat value={estimate.version.summary.amount || 0} isCurrency={true} />
                    {/* <span>TBD</span> */}
                </TableCell>
                <TableCell>{estimate.createUser.name}</TableCell>
                <TableCell align="right">{new Intl.DateTimeFormat('en-US', options).format(new Date(estimate.createDate))}</TableCell>
                <TableCell align="right">{new Intl.DateTimeFormat('en-US', options).format(new Date(estimate.updateDate))}</TableCell>
                <TableCell>
                    <EstimateActions estimate={estimate as unknown as IEstimate} />
                </TableCell>
            </TableRow>
        );
    });


    return (
        <Container>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Client</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell align="center">Version</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="right">Hours</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell>Author</TableCell>
                            <TableCell align="right">Created</TableCell>
                            <TableCell align="right">Updated</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowEls}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={7}
                    count={pagination.total}
                    rowsPerPage={size}
                    page={page - 1}
                    // SelectProps={{
                    //     inputProps: { 'aria-label': 'rows per page' },
                    //     native: true,
                    // }}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    component="div"
                    // ActionsComponent={TablePaginationActions}
                />
            </TableContainer>
        </Container>
    );
};
