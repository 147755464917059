import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {Link as RouterLink} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Divider, FormControlLabel, Icon, Link, ListItemIcon, ListItemText, Menu, MenuItem, Switch, Typography } from '@mui/material';

import { IRootStore } from '../../../RootStore';
import { GeneralInfo } from './GeneralInfo';
import { ChangePrimaryRoleModal, ConfirmModal, ConfirmPublishModal, ISettingsModal, SettingsModal } from '../../modals';
import { ModeSwitcher } from '../ModeSwitcher';
import { EstimateModeType, StatusType, estimationSetStatusAction, versionSettingsChangeModeAction, versionSettingsChangeRoleAction, versionSettingsResetAction, estimationViewSettingsAction } from '../../../modules';
import { EstimateRoleTable } from './EstimateRoleTable';
import { EstimateRange } from './EstimateRange';
import { StatusButton } from './StatusButton';
import { SettingsReadOnly } from './SettingsReadOnly';

const Container = styled.div`
    background-color: white;
    max-width: 350px;
    width: 100%;

    .text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        width: 100%;
    }
    .button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        padding-bottom: 10px;
    }

    .secondary-info-container {
        border-top: 1px solid #eee;
        padding-top: 10px;
        font-size: .65rem;
        text-align: right;

        > span {
            padding: 10px 0;
        }
    }

    .revision-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

type SettingOptions = 'info'|'mode'|'roles'|'ranges'|'add-role'|'resync'|'settings-ro';
const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };

export interface IProjectInfo {

}

export const ProjectInfo: React.FC<IProjectInfo> = (props: IProjectInfo) => {
    const dispatch = useDispatch();

    const {estimate, settings: {showAmounts}} = useSelector((state: IRootStore) => state.estimation);
    const {version} = useSelector((state: IRootStore) => state.version);

    const {settings: {mode}} = version;

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isModeConfirmModalOpen, setIsModeConfirmModalOpen] = React.useState(false);
    const [isResetConfirmModalOpen, setIsResetConfirmModalOpen] = React.useState(false);
    const [isChangeRoleModalOpen, setIsChangeRoleModalOpen] = React.useState(false);
    const [isPublishOpen, setIsPublishOpen] = React.useState(false);

    const [setting, setSetting] = React.useState<SettingOptions>('info');

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [primaryRole] = version.settings.roles.filter(x => x.isPrimary);

    const isReadOnly = estimate.status === 'PUBLISHED' || estimate.status === 'CLOSED' || estimate.status === 'PENDING';
    
    const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {

        if (isReadOnly) {
            onMenuClick('settings-ro');
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleSettingsClose = () => {
        setAnchorEl(null);
    };

    const confirmModeChange = () => {
        const _mode: EstimateModeType = (mode === 'MANUAL') ? 'COMPUTED' : 'MANUAL';

        dispatch(versionSettingsChangeModeAction({mode: _mode}));

        setIsModeConfirmModalOpen(false);
        setIsModalOpen(false);
    }

    const confirmResetClick = () => {
        // dispatch(versionSettingsResetAction());
        setIsResetConfirmModalOpen(false);
        handleSettingsClose();
    };

    const onChangePrimaryRoleClick = (primaryRole: string) => {
        // dispatch(versionSettingsChangeRoleAction({primaryRole}));
        setIsChangeRoleModalOpen(false);
        handleSettingsClose();
    };

    const onModeChange = () => {
        setIsModeConfirmModalOpen(true);
    };

    const onCancel = () => {
        setIsPublishOpen(false);
    };

    const onStatusClick = (_status: StatusType) => {
        if (_status === 'PUBLISHED') {
          setIsPublishOpen(true);
        } else {
          dispatch(estimationSetStatusAction({ id: estimate.id, status: _status }));
        }
    }
    
    const onConfirm = () => {
        dispatch(estimationSetStatusAction({ id: estimate.id, status: 'PUBLISHED' }));
        setIsPublishOpen(false);
    };

    const getSettings = () => {
        const _props: ISettingsModal = {
            open: isModalOpen,
            onClose: () => setIsModalOpen(false),
            title: ''
        };

        switch(setting) {
            case 'info': {
                _props.title = 'Project Info';
                _props.children = <GeneralInfo />;
                break;
            }
            case 'mode': {
                _props.title = 'Mode';
                _props.children = <ModeSwitcher mode={mode} onChange={onModeChange} displayText={true} />;
                break;
            } 
            case 'roles': {
                _props.title = 'Roles';
                _props.children = <EstimateRoleTable />;
                break;
            }
            case 'ranges': {
                _props.title = 'Range';
                _props.children = <EstimateRange />
                break;
            }
            case 'settings-ro': {
                _props.title = 'Settings';
                _props.children = <SettingsReadOnly />
                break;
            }
        }

        return _props;
    };

    const onMenuClick = (val: SettingOptions) => {
        setSetting(val);
        setIsModalOpen(true);
        handleSettingsClose();
    };

    return (
        <Container>
            <Typography variant="h5" align="left">
                <span className="text">
                    {estimate.client}
                </span>
            </Typography>
            <Typography variant="body1" align="left">
                <span className="text">
                    {estimate.projectCode}: {estimate.title}
                </span>
            </Typography>
            <div className="revision-container">
                {/* <Typography variant="caption">
                    v{estimate.version}
                </Typography>
                {
                    estimate.revisions && estimate.revisions.parentId &&
                    <Link component={RouterLink} to={`/estimate/${estimate.revisions.parentId}`}>Parent Revision</Link>
                }
                {
                    estimate.revisions && estimate.revisions.childId &&
                    <Link component={RouterLink} to={`/estimate/${estimate.revisions.childId}`}>Child Revision</Link>
                } */}
            </div>
            <div className="button-container">
                {
                    estimate.status !== 'PUBLISHED' &&
                    <div>
                        <StatusButton currentStatus={estimate.status} onClick={onStatusClick} />
                    </div>
                }
                
                <div>
                    {
                        !isReadOnly &&
                        <Button onClick={() => onMenuClick('info')}>
                            <Icon color="disabled">edit</Icon>
                        </Button>
                    }
                    <Button onClick={handleSettingsClick}>
                        <Icon color="disabled">settings</Icon>
                    </Button>
                </div>
                
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleSettingsClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => onMenuClick('mode')} style={{minWidth: '275px'}}>
                        <ListItemIcon>
                            <Icon>calculate</Icon>
                        </ListItemIcon>
                        <ListItemText>
                            <span>Mode</span>
                        </ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            {version.settings.mode}
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => onMenuClick('ranges')}>
                        <ListItemIcon>
                            <Icon>stacked_line_chart</Icon>
                        </ListItemIcon>
                        <ListItemText>
                            <span>Range</span>
                        </ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            {version.settings.ranges.low}-{version.settings.ranges.high}
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => onMenuClick('roles')}>
                        <ListItemIcon>
                            <Icon color="primary">group</Icon>
                        </ListItemIcon>
                        <ListItemText>
                        <span>Manage Roles</span>
                        </ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            {version.settings.roles.length}
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => setIsChangeRoleModalOpen(true)}>
                        <ListItemIcon>
                            <Icon color="primary">person</Icon>
                        </ListItemIcon>
                        <ListItemText>
                            <span>Change Primary Role</span>
                        </ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            {primaryRole.role}
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => setIsResetConfirmModalOpen(true)}>
                        <ListItemIcon>
                            <Icon color="secondary">sync</Icon>
                        </ListItemIcon>
                        <ListItemText>
                            <span>Resync From Global</span>
                        </ListItemText>
                    </MenuItem>
                    
                    <Divider />
                    
                    <MenuItem onClick={() => dispatch(estimationViewSettingsAction({showAmounts: !showAmounts}))}>
                        <ListItemIcon>
                            <Icon color="inherit">paid</Icon>
                        </ListItemIcon>
                        <ListItemText>
                            <span>Show Amounts</span>
                        </ListItemText>
                        <span>
                         <Switch checked={showAmounts} />
                        </span>
                    </MenuItem>
                </Menu>
            </div>
            <div className="secondary-info-container">
                <span>Updated: <br />
                    {estimate.updateUser.name}&nbsp;|&nbsp;
                    {new Intl.DateTimeFormat('en-US', options).format(new Date(estimate.updateDate))}
                </span>
            </div>
            <SettingsModal {...getSettings()} />
            <ConfirmModal isOpen={isModeConfirmModalOpen} onConfirm={confirmModeChange} onCancel={() => setIsModeConfirmModalOpen(false)}>
                <p>Changing the mode will alter all tasks on the estimate. Do you wish to continue?</p>
            </ConfirmModal>
            <ConfirmModal isOpen={isResetConfirmModalOpen} onConfirm={confirmResetClick} onCancel={() => setIsResetConfirmModalOpen(false)}>
                <p>Resyncing settings with the values from the global configuration will overwrite all settings in your estimate will affect all tasks. Do you wish to continue?</p>
            </ConfirmModal>
            <ChangePrimaryRoleModal open={isChangeRoleModalOpen} onCancel={() => setIsChangeRoleModalOpen(false)} onSave={onChangePrimaryRoleClick} />
            <ConfirmPublishModal
                isOpen={isPublishOpen}
                onCancel={onCancel}
                onConfirm={onConfirm}
            />
        </Container>
    );
};
