import * as React from 'react';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import {AppRoutes} from './AppRoutes';

const AppContainer = styled.div`

`;

export function App() {

  return (
    <AppContainer className="App">
      <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DndProvider backend={HTML5Backend}>
            <AppRoutes />
          </DndProvider>
        </LocalizationProvider>
    </AppContainer>
  );
}
