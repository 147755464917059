import * as React from 'react';
import styled from 'styled-components';
import {Table, TableBody, TableHead, TableRow, TableCell} from '@mui/material';

import { IEstimate, SummaryType } from '../../../modules';
import {NumberFormat} from '../../ui';
import { calculateTaskSummaryLines } from '../../../modules';
import { useSelector } from 'react-redux';
import { IRootStore } from '../../../RootStore';

const Container = styled.div`
    /* max-width: 670px;
    max-height: 900px; */
    /* max-width: 7.5in;
    max-height: 10.5in; */
    /* border: 1px solid gray; */

    .description {
        white-space: pre-wrap;
        font-style: italic;
        margin-top: 5px;
    }

    .role-hours {
        display: inline-block;
        margin-right: 10px;
    }

    .header-row {
        background-color: #f4f4f4;
        > TD {
            font-weight: bold;
            font-size: .85rem;
            padding: 5px;
        }
    }

    THEAD {
        position: sticky;
        top: 0;
        background-color: #fff;

        TH {
            border-bottom: 1px solid gray;
            padding: 16px 8px;
        }
    }

    .row {
        > TD {
            font-size: .75rem;
            padding: 3px;
            vertical-align: top;
        }
    }
`;

export interface ITaskSummary {
    estimate?: IEstimate;
    showDescriptions: boolean;
    showRoleHours: boolean;
    valueType: SummaryType;
}

export const TaskSummary: React.FC<ITaskSummary> = (props: ITaskSummary) => {

    const {showDescriptions, valueType, showRoleHours} = props;

    const {version} = useSelector((state: IRootStore) => state.version);

    const lines = calculateTaskSummaryLines(version, valueType);
    
    const rowEls = lines.map((line, idx) => {

        if (line.section) {
            return (
                <TableRow key={idx} className="header-row" style={{fontWeight: 'bold'}}>
                    <TableCell colSpan={2}>{line.name}</TableCell>
                    <TableCell align="right">
                        <NumberFormat value={line.hours || 0} />
                    </TableCell>
                </TableRow>
            );
        } else {
            return (
                <TableRow key={idx} className="row">
                    <TableCell colSpan={2}>
                        <div>{line.name}</div>
                        {
                            showDescriptions &&
                            <div className="description">{line.description}</div>
                        }
                        {
                            showRoleHours && Object.entries(line.roleHours || {})
                                            .filter(([role, hours]) => role !== 'Total' && hours > 0)
                                            .map(([role, hours], _index) => (<span key={_index} style={{display: 'inline-block', marginRight: '7px'}}>{role}: {hours}</span>))
                        
                        }
                    </TableCell>
                    <TableCell align="right">
                        <NumberFormat value={line.hours || 0} />
                    </TableCell>
                </TableRow>
            );
        }
    });

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <div>Name</div>
                        </TableCell>
                        {/* <TableCell>Description</TableCell> */}
                        <TableCell align="right" colSpan={2}>
                            <div>Hours</div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowEls}
                </TableBody>
            </Table>
        </Container>
    );
};
