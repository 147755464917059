import * as React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { XYCoord } from 'dnd-core';

interface DragItem {
    index: number;
    id: string;
    type: string;
  }

export interface IDraggableProps {
    // item: any;
    id: string | number;
    index: number;
    onMove: (dragIndex: number, hoverIndex: number) => void;
    key?: React.Key;
    children?: React.ReactNode;
}

export const Draggable: React.FC<IDraggableProps> = (props: IDraggableProps) => {

    const {id, index, onMove} = props;

    const ref = React.useRef<HTMLDivElement>(null)
    
    const [, drop] = useDrop({
        accept: 'ITask',
        hover(item, monitor) {
            if (!ref.current) {
                return
            }

            const dragIndex = (item as any).index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            onMove(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            (item as DragItem).index = hoverIndex
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'ITask',
        item: { type: 'ITask', id, index },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref))

    const opacity = isDragging ? 0.4 : 1

    return (
        <div ref={ref} style={{ opacity, width: '100%' }}>
            {props.children}
        </div>
    );
};
