import { FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, TextField } from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { summaryTaskSummarySettingsAction, SummaryType } from '../../../modules';
import { useSelector } from 'react-redux';
import { IRootStore } from '../../../RootStore';

const Container = styled.div`
    display: flex;
    flex-direction: column;

    padding: 10px;

    > * {
        margin-bottom: 20px;
    }
`;

export interface ITaskSummaryControls {
}

export const TaskSummaryControls: React.FC<ITaskSummaryControls> = (props: ITaskSummaryControls) => {
    const dispatch = useDispatch();

    const [width, setWidth] = React.useState(670);
    const [height, setHeight] = React.useState(900);

    const {taskSummarySettings: {showRoleHours, showDescriptions, summaryType}} = useSelector((state: IRootStore) => state.summary);

    const dispatchSettings = (attribute: Record<string, unknown>) => {
        const settings = {
            showDescriptions,
            summaryType,
            showRoleHours,
            width,
            height,
            ...attribute
        };

        dispatch(summaryTaskSummarySettingsAction({settings}));
    };

    const onShowDescriptionsChange = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
        dispatchSettings({showDescriptions: checked});
    };

    const onValueTypeChange = (event: SelectChangeEvent<SummaryType>) => {
        const value = event.target.value as SummaryType;
        dispatchSettings({summaryType: value});
    };

    const onShowRoleHoursChange = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
        dispatchSettings({showRoleHours: checked});
    };

    const onWidthChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const val = parseInt(event.target.value, 10);
        if (val < 0) {
            setWidth(50);
        } else if (val > 5000) {
            setWidth(5000)
        } else {
            setWidth(val);
        }
    }

    const onWidthBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatchSettings({width});
    }

    const onHeightChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const val = parseInt(event.target.value, 10);
        if (val < 0) {
            setHeight(50);
        } else if (val > 5000) {
            setHeight(5000)
        } else {
            setHeight(val);
        }
    }

    const onHeightBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatchSettings({height});
    }

    return (
        <Container>
            <FormGroup>
                <FormControlLabel control={<Switch checked={showDescriptions} onChange={onShowDescriptionsChange} />} label="Show Descriptions" />
            </FormGroup>

            <FormGroup>
                <FormControlLabel control={<Switch checked={showRoleHours} onChange={onShowRoleHoursChange} />} label="Show Role Hours" />
            </FormGroup>

            <FormControl>
                <InputLabel id="demo-multiple-checkbox-label">Type</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    value={summaryType}
                    label="Type"
                    style={{width: '200px'}}
                    onChange={onValueTypeChange}
                >
                    <MenuItem value={'low'}>Low</MenuItem>
                    <MenuItem value={'mid'}>Mid</MenuItem>
                    <MenuItem value={'high'}>High</MenuItem>
                </Select>
            </FormControl>

            <FormControl>
                <TextField
                    label="Width (px)"
                    type="number"
                    value={width}
                    onChange={onWidthChange}
                    onBlur={onWidthBlur}
                />
            </FormControl>

            <FormControl>
                <TextField
                    label="Height (px)"
                    type="number"
                    value={height}
                    onChange={onHeightChange}
                    onBlur={onHeightBlur}
                />
            </FormControl>

        </Container>
    );
};
