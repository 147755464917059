import {createAction, createReducer} from '@reduxjs/toolkit';

//
// CONSTANTS
//
export const NOTIFICATIONS_ADD = '[Notifications] Add';
export const NOTIFICATIONS_REMOVE = '[Notifications] Remove';
export const NOTIFICATIONS_CLEAR = '[Notifications] Clear';

export type NotificationStoresType = 'auth' | 'assumptions' | 'estimateList' | 'estimate' | 'config' | 'user' | 'version';
export type NotificationSeverityType = 'error' | 'warning' | 'info' | 'success';

//
// ACTIONS
//
export const notificationsAddAction = createAction<{store: NotificationStoresType, message: string, severity: NotificationSeverityType, dismissable?: boolean, timeout?: number}>(NOTIFICATIONS_ADD);
export type NotificationsAddAction = ReturnType<typeof notificationsAddAction>;

export const notificationsRemoveAction = createAction<{store: NotificationStoresType}>(NOTIFICATIONS_REMOVE);
export type NotificationsRemoveAction = ReturnType<typeof notificationsRemoveAction>;

export const notificationsClearAction = createAction(NOTIFICATIONS_CLEAR);
export type NotificationsClearAction = ReturnType<typeof notificationsClearAction>;

export type NotificationsActionsType = 
    NotificationsAddAction
    | NotificationsRemoveAction
    | NotificationsClearAction;

//
// STATE
//
export interface INotification {
    message: string;
    severity: NotificationSeverityType;
    dismissable?: boolean;
    timeout?: number;
}

export interface INotificationState {
    stores: { [store in NotificationStoresType]: INotification | undefined }
}

const initialState: INotificationState = {
    stores: {
        auth: undefined,
        assumptions: undefined,
        config: undefined,
        estimate: undefined,
        estimateList: undefined,
        user: undefined,
        version: undefined,
    }
};

//
// REDUCER
//
export const notificationsReducer = createReducer(initialState, (builder) => builder
    .addCase(notificationsAddAction, (state, action) => {
        const {store, message, severity, dismissable, timeout} = action.payload;
        const notification: INotification = {message, severity, dismissable, timeout};
        const newState = {...state, stores: {...state.stores}};
        newState.stores[store] = notification;
        return newState;
    })
    .addCase(notificationsRemoveAction, (state, action) => {
        const newState = {...state, stores: {...state.stores}};
        newState.stores[action.payload.store] = undefined;
        return newState;
    })
    .addCase(notificationsClearAction, (state, action) => {
        return {
            stores: {
                auth: undefined,
                assumptions: undefined,
                config: undefined,
                estimate: undefined,
                estimateList: undefined,
                user: undefined,
                version: undefined
            }
        };
    })
);
