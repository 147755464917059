import * as React from 'react';

export interface INumberFormat {
    value: number;
    isCurrency?: boolean;
}

export const NumberFormat: React.FC<INumberFormat> = (props: INumberFormat) => {
    const {value, isCurrency} = props;
    
    const options: Intl.NumberFormatOptions = (isCurrency)
    ? {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0}
    : {};

    return (
        <span>{Intl.NumberFormat('en-US', options).format(value || 0)}</span>
    );
};
