import * as React from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {Typography} from '@mui/material';

import {StatusStepper} from '../../ui';
import {IRootStore} from '../../../RootStore';

const Container = styled.div`
    width: 100%;
    
    .status-container {
        margin-top: 30px;
    }
`;

export interface IGeneralInfoReadOnlyProps {
}

export const GeneralInfoReadOnly: React.FC<IGeneralInfoReadOnlyProps> = (props: IGeneralInfoReadOnlyProps) => {

    const {client, title, projectCode} = useSelector((state: IRootStore) => state.estimation.estimate);

    return (
        <Container>
            <Typography variant="h4">{client}</Typography>
            <Typography variant="h6">{projectCode}: {title}</Typography>
            {/* <Typography variant="h6">v{version}</Typography> */}
            <div className="status-container">
                <StatusStepper />
            </div>
        </Container>
    );
}
