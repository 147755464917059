import * as React from 'react';
import styled from 'styled-components';
import { TextField, Popover, Button, Tooltip } from '@mui/material'
import { Add } from '@mui/icons-material';

const Container = styled.div`
    max-height: 300px;
    max-width: 175px;

    .description-button {
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        display: block;
        width: 100%;
        max-height: 85px;
    }

    .disabled {
        color: #ddd;
        text-decoration: line-through;
    }
`;

export interface ITaskDescription {
    value: string;
    onChange: (val: string) => void;
    isVisible: boolean;
}

export const TaskDescription: React.FC<ITaskDescription> = (props: ITaskDescription) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [description, setDescription] = React.useState<string>();

    const {value, onChange, isVisible} = props;

    React.useEffect(() => {
        if (value !== description) {
            setDescription(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => { // 
        setAnchorEl(event.currentTarget as HTMLButtonElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value: val} = event.currentTarget;
        setDescription(val);
        onChange(val);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'task-description-popover' : undefined;

    const hasContent = description && description.trim().length > 0;

    const MAX_CHAR = 50;

    const fontSize = (hasContent) ? '.65rem' : '14px';

    return (
        <Container>
            <Tooltip title={`Task description`} placement="bottom">
                <Button 
                    style={{width: '150px', textAlign: 'left', fontSize}}
                    aria-describedby={id} 
                    onClick={handleClick} 
                    // variant={hasContent ? 'text' : 'outlined'} 
                    variant="text" 
                    color={hasContent ? 'inherit' : 'primary'}
                >
                    {
                        hasContent &&
                        <span className={`description-button ${isVisible ? '' : 'disabled'}`}>
                            {description?.trim().replace(/\n/g, ' ').slice(0, MAX_CHAR)}
                            {description.length > MAX_CHAR ? '...' : ''}
                        </span>
                    }
                    {
                        !hasContent &&
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%'}}>
                            <Add />
                            <span>&nbsp;Details</span>
                        </div>
                    }
                </Button>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div style={{padding: '6px', maxHeight: '300px'}}>
                    <TextField
                        label="Description"
                        multiline={true}
                        fullWidth={true}
                        style={{width: '40vw'}}
                        value={description}
                        onChange={onDescriptionChange}
                        onBlur={handleClose}
                    />
                </div>
            </Popover>
        </Container>
    );
};
