import * as React from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {Dialog, DialogActions, DialogTitle, DialogContent, Button, TextField} from '@mui/material';

import {estimationAddCommentAction} from '../../modules';

const Container = styled.div`
    
`;

const ContentContainer = styled.div`
    min-width: 400px;
    padding: 10px 20px;
`;

export interface IAddCommentModal {
    estimateId: string;
    open: boolean;
    onCancel: () => void;
    onSave: () => void;
}

export const AddCommentModal: React.FC<IAddCommentModal> = (props: IAddCommentModal) => {
    const dispatch = useDispatch();

    const [comment, setComment] = React.useState('');

    const onSave = () => {
        dispatch(estimationAddCommentAction({id: props.estimateId, comment}));
        props.onSave();
    };

    React.useEffect(() => {
        setComment('');
    }, [props.open]);

    return (
        <Container>
            <Dialog open={props.open}>
                <DialogTitle>Add Comment</DialogTitle>
                <DialogContent>
                    <ContentContainer>
                        <TextField type="text" label="Add a comment" variant="outlined" autoFocus={true} multiline={true} fullWidth={true} rows={4} value={comment} onChange={(event) => setComment(event.target.value)} />
                    </ContentContainer>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={props.onCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" disabled={(comment.length < 1)} onClick={onSave}>Save Comment</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};
