import * as React from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux'
import {Button, List, ListItem, Divider, ListItemText, Icon} from '@mui/material';

import { IRootStore } from '../../../RootStore';
import {AddCommentModal} from '../../modals';
import { useDispatch } from 'react-redux';
import { estimationListCommentsAction } from '../../../modules';

const Container = styled.div`
    width: 100%;
    
    .line-item {
        white-space: pre-line;
    }
`;

export interface ICommentsList {
}

export const CommentsList: React.FC<ICommentsList> = (props: ICommentsList) => {
    const dispatch = useDispatch();

    const [isCommentModalOpen, setIsCommentModalOpen] = React.useState(false);

    const {comments, estimate} = useSelector((state: IRootStore) => state.estimation);

    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

    React.useEffect(() => {
        dispatch(estimationListCommentsAction({id: estimate.id}));
    }, [dispatch, estimate.id]);

    return(
        <Container>
            {
                estimate.status !== 'PUBLISHED' &&
                <Button variant="contained" color="primary" onClick={() => setIsCommentModalOpen(true)}><Icon>chat</Icon>&nbsp;Add Comment</Button>
            }
            
            <List>
                {
                    [...comments]
                    .sort((a, b) => {
                        if (a.createDate > b.createDate) return -1
                        else if (a.createDate < b.createDate) return 1;
                        else return 0;
                    })
                    .map((comment, idx) => (
                        <React.Fragment key={idx}>
                            <ListItem>
                                <ListItemText
                                    className="line-item"
                                    primary={new Intl.DateTimeFormat('en-US', options).format(new Date(comment.createDate))}
                                    secondary={
                                        <React.Fragment>
                                            <strong>{comment.createUser.name} &nbsp; &nbsp;</strong>
                                            {comment.comment}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))
                }
            </List>

            <AddCommentModal estimateId={estimate.id} open={isCommentModalOpen} onCancel={() => setIsCommentModalOpen(false)} onSave={() => setIsCommentModalOpen(false)} />
        </Container>
    );
};
