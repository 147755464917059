
type HttpMethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export class RequestBuilder {

    private url: string = '';
    private options: any = {
        method: 'GET',
        headers: {},
    };

    private urlSearchParams = new URLSearchParams();

    constructor(url: string) {
        this.url = url;
    }

    async build() {
        try {
            const url = new URL(this.url);
            url.search = this.urlSearchParams.toString();

            const response = await fetch(url.toString(), this.options);

            if (response && response.status) {
                const authErrorCodes = [401, 403]; 
                if (authErrorCodes.includes(response.status) && !this.url.includes('/login')) {
                    localStorage.removeItem('token');
                    window.location.pathname = '/';
                    throw new Error(`Unauthorized`);
                } else if (response.status === 0 || response.status >= 400) {
                    console.error(response);
                    const text = await response.text();
                    throw new Error(text);
                }
            }

            if (response.headers.get('Content-Type')?.includes('application/json')) {
                return response.json();
            } else {
                return response.text();
            }


        } catch (e: any) {
            // assume server JSON error
            const error = JSON.parse(e.message);
            const {error: {message}} = error;

            console.error(message);
            throw new Error(message);
        }
    }

    debug() {
        console.log(this.url, this.options, this.urlSearchParams);
        return this;
    }

    setMethod(method: HttpMethodType) {
        this.options.method = method;
        return this;
    }

    addHeader(name: string, value: any) {
        this.options.headers[name] = value;
        return this;
    }

    addHeaders(values: {name: string, value: any}[]){
        values.forEach(x => {
            this.options.headers[x.name] = x.value;
        });
        return this;
    }

    addParam(name: string, value: string) {
        this.urlSearchParams.append(name, value);
        return this;
    }

    addParams(params: {name: string, value: string}[]) {
        params.forEach(x => this.addParam(x.name, x.value));
        return this;
    }

    addAuthorizationHeader() {
        this.addHeader('Authorization', `Bearer ${localStorage.getItem('token')}`);
        return this;
    }

    setBody<T>(body: T) {
        this.options.body = JSON.stringify(body);
        return this;
    }
}
