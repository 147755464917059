import * as React from 'react';
import styled from 'styled-components';
import {Dialog, DialogContent, DialogActions, DialogTitle, Button} from '@mui/material';

const Container = styled.div`
`;

export interface IConfirmModalProps {
    isOpen: boolean;
    children?: React.ReactNode;
    onCancel: () => void;
    onConfirm: () => void;
}

export const ConfirmModal: React.FC<IConfirmModalProps> = (props: IConfirmModalProps) => {
    return (
        <Container>
            <Dialog open={props.isOpen}>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    {props.children}
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={props.onCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={() => props.onConfirm()}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
