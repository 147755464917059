import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {TextField, Table, TableHead, TableBody, TableRow, TableCell, Button} from '@mui/material';

import { IRootStore } from '../../../RootStore';
import {getConfigAction, Ranges, updateConfigAction} from '../../../modules';

const Container = styled.div`
    TABLE {
        margin-bottom: 20px;
    }
`;

export interface IDefaultRanges {
}

export const DefaultRanges: React.FC<IDefaultRanges> = (props: IDefaultRanges) => {
    const dispatch = useDispatch();

    const {ranges, isBusy} = useSelector((state: IRootStore) => state.config);

    const [updatedRanges, setUpdatedRanges] = React.useState({...ranges});

    React.useEffect(() => {
        dispatch(getConfigAction({context: 'defaultRanges'}));
    }, [dispatch]);

    React.useEffect(() => {
        setUpdatedRanges(ranges);
    }, [ranges]);

    const onChange = (key: string, value: string) => {
        const val = parseFloat(value);
        const updates: Ranges = {...updatedRanges};

        if (key === 'high') {
            updates.high = val;
        } else if (key === 'low') {
            updates.low = val;
        }

        setUpdatedRanges(updates);
    };

    const onSaveClick = () => {
        dispatch(updateConfigAction({context: 'defaultRanges', content: updatedRanges}));
    };

    return(
        <Container>
            <h3>Ranges</h3>
            <p>Changing these settings will only affect newly created estimates. Prior estimates will not be changed or updated.</p>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Low</TableCell>
                        <TableCell>High</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <TextField
                                type="number"
                                value={updatedRanges.low}
                                variant="standard"
                                size="small"
                                inputProps={{'data-testid': `range-input-low`, min: 0, step: '0.05', style: { textAlign: 'right' }}}
                                onChange={(event) => onChange('low', event.target.value)}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                type="number"
                                value={updatedRanges.high}
                                variant="standard"
                                size="small"
                                inputProps={{'data-testid': `range-input-high`, min: 0, step: '0.05', style: { textAlign: 'right' }}}
                                onChange={(event) => onChange('high', event.target.value)}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Button variant="contained" color="primary" disabled={isBusy} onClick={onSaveClick}>Save</Button>
        </Container>
    );
};
