import * as React from 'react';
import styled from 'styled-components';
import { createEditor, Transforms, Node, Editor as SlateEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import {Button, ButtonGroup, Icon} from '@mui/material';

const DefaultElement = (props: any) => {
    return <p {...props.attributes}>{props.children}</p>
}

const CodeElement = (props: any) => {
    return (
      <pre {...props.attributes}>
        <code style={{backgroundColor: '#eee', padding: '5px', lineHeight: '0'}}>{props.children}</code>
      </pre>
    )
};

const Container = styled.div`
    background-color: #fff;
    padding: 20px;

    .editor-container {
        border: 1px solid;
    }

    CODE {
        background-color: #eee;
    }
`;

const ToolBar = () => {
    return (
        <div>
            <ButtonGroup>
                <Button><Icon>format_bold</Icon></Button>
                <Button><Icon>format_italic</Icon></Button>
                <Button><Icon>format_underline</Icon></Button>
            </ButtonGroup>
            <ButtonGroup>
                <Button><Icon>code</Icon></Button>
                <Button><Icon>format_quote</Icon></Button>
                <Button><Icon>format_list_bulleted</Icon></Button>
                <Button><Icon>format_list_numbered</Icon></Button>
                <Button><Icon>format_align_left</Icon></Button>
                <Button><Icon>format_align_center</Icon></Button>
                <Button><Icon>format_align_right</Icon></Button>
                <Button><Icon>format_align_justify</Icon></Button>
            </ButtonGroup>
        </div>
    );
};

const Leaf = ({ attributes, children, leaf }: any) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>
    }
  
    if (leaf.code) {
      children = <code>{children}</code>
    }
  
    if (leaf.italic) {
      children = <em>{children}</em>
    }
  
    if (leaf.underline) {
      children = <u>{children}</u>
    }
  
    return <span {...attributes}>{children}</span>
  };

export interface IEditor {
}

export const Editor: React.FC<IEditor> = (props: IEditor) => {
    const [editor] = React.useState(() => withReact(createEditor()));

    const initialValue = [
        {
            type: 'paragraph',
            children: [
                { text: 'A line of text in a paragraph.' },
                { text: 'But this should be bold.', bold: true },
                { text: 'And this should be italic', italic: true },
                { text: 'And this should be underlined', underline: true },
                { text: '<FooBar />', code: true }
            ],
        },
        {
            type: 'paragraph',
            children: [
                { text: 'Another paragraph.' }
            ]
        }
    ];

    const renderElement = React.useCallback((props: any) => {
        const {type} = props.element;

        switch(type) {
            case 'code':
                return <CodeElement {...props} />
            default:
                return <DefaultElement {...props} />
        }
    }, []);

    const onKeyDown = (event: any) => {
        if (event.key === '`' && event.ctrlKey) {
            // Prevent the "`" from being inserted by default.
            event.preventDefault()
            // Otherwise, set the currently selected blocks type to "code".
            Transforms.setNodes(
              editor,
              { type: 'code' } as Partial<Node>,
              { match: (node, path) => SlateEditor.isBlock(editor, event.target) }
            )
        }
    };

    const renderLeaf = React.useCallback((props: any) => <Leaf {...props} />, []);

    return (
        <Container>
            <div className="editor-container">
                <Slate editor={editor} value={initialValue} onChange={(v) => console.log(v)}>
                    <ToolBar />
                    <Editable
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        onKeyDown={onKeyDown}
                        placeholder='Enter some text...'
                        spellCheck={true}
                        autoFocus={true}
                    />
                </Slate>
            </div>
        </Container>
    );
};
