import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button, Icon, Paper, Switch, InputAdornment, Tooltip } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { versionSettingsChangeAction, IRoleSetting } from '../../../modules';
import { IRootStore } from '../../../RootStore';
import {ConfirmModal} from '../../modals';
import { AddRole } from './AddRole';

const Container = styled.div`
    .add-role-container {
        margin-top: 15px;
    }
`;

export interface IEstimateRoleTable {
}

export const EstimateRoleTable: React.FC<IEstimateRoleTable> = (props: IEstimateRoleTable) => {
    const dispatch = useDispatch();

    const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
    const [roleToRemove, setRoleToRemove] = React.useState<string|null>(null);

    const {roles, ranges, mode} = useSelector((state: IRootStore) => state.version.version.settings);

    const onRoleChange = (roleSetting: IRoleSetting, name: string, enabled: boolean, rate?: string, multiplier?: string) => {
        const _rate = (rate) ? parseFloat(rate) : roleSetting.rate;
        const _multiplier = (multiplier) ? parseFloat(multiplier) : roleSetting.multiplier;
        const _setting = {...roleSetting, name, enabled, rate: _rate, multiplier: _multiplier};

        const _roleSettings = roles.map(x => {
            if (x.role === _setting.role) {
                return _setting;
            }

            return x;
        });

        const settings = {roles: _roleSettings, ranges, mode};

        dispatch(versionSettingsChangeAction({settings}));

    };

    const onRemoveRoleClick = (role: string) => {
        setRoleToRemove(role);
        setIsConfirmModalOpen(true);
    };

    const onConfirmRoleRemove = () => {
        const newRoles = roles.filter(x => x.role !== roleToRemove);

        const settings = {roles: newRoles, ranges, mode};

        dispatch(versionSettingsChangeAction({settings}));
        setIsConfirmModalOpen(false);
        setRoleToRemove(null);
    };

    const onConfirmModalCancel = () => {
        setRoleToRemove(null);
        setIsConfirmModalOpen(false);
    };

    const generateRoleEl = (role: IRoleSetting, key: number | string) => (
        <TableRow key={key}>
            <TableCell align="center">
                {
                    !role.isPrimary &&
                    <Button onClick={() => onRemoveRoleClick(role.role)}>
                        <Icon color="secondary">close</Icon>
                    </Button>
                }
                {
                    role.isPrimary &&
                    <div>
                        <Tooltip title="Primary role">
                            <Icon color="primary">star</Icon>
                        </Tooltip>
                    </div>
                }
            </TableCell>
            <TableCell>{role.role}</TableCell>
            <TableCell>
                <TextField
                    type="text"
                    variant="standard"
                    size="small"
                    defaultValue={role.name}
                    onBlur={(event) => onRoleChange(role, event.currentTarget.value, role.enabled )}
                    style={{minWidth: '250px'}}
                />
            </TableCell>
            <TableCell>
                {
                    !role.isPrimary &&
                    <Switch checked={role.enabled} color="primary" onChange={(event, checked) => onRoleChange(role, role.name, checked)} />
                }
            </TableCell>
            <TableCell>
                <TextField
                    type="currency"
                    variant="standard"
                    size="small"
                    defaultValue={role.rate}
                    inputProps={{min: 0, style: { textAlign: 'right' }}}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$/hr</InputAdornment>,
                    }}
                    style={{maxWidth: '125px'}}
                    onBlur={(event) => onRoleChange(role, role.name, role.enabled, event.currentTarget.value)}
                />
            </TableCell>
            <TableCell>
                {
                    !role.isPrimary &&
                    <TextField
                        type="number"
                        variant="standard"
                        size="small"
                        defaultValue={role.multiplier}
                        inputProps={{min: 0, step: '0.1', style: { textAlign: 'right' }}}
                        disabled={mode === 'MANUAL'}
                        style={{maxWidth: '125px'}}
                        onBlur={(event) => onRoleChange(role, role.name, role.enabled, undefined, event.currentTarget.value)}
                    />
                }
            </TableCell>
        </TableRow>
    );

    const primaryRoleEl = roles.filter(x => x.isPrimary)
        .map((x) => generateRoleEl(x, 'primary'))

    const rowsEl = roles
                .filter(x => !x.isPrimary)
                .map((x, index) => generateRoleEl(x, index));
    
    return (
        <Container>
            <TableContainer component={Paper} elevation={1}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Enabled</TableCell>
                                <TableCell>Rate</TableCell>
                                <TableCell>Multiplier</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {primaryRoleEl}
                            {rowsEl}
                        </TableBody>
                    </Table>
            </TableContainer>
            <div className="add-role-container">
                <AddRole />
            </div>
            <ConfirmModal isOpen={isConfirmModalOpen} onCancel={onConfirmModalCancel} onConfirm={onConfirmRoleRemove}>
                <div>
                    Are you sure you want to remove the {roleToRemove} role from your estimate?
                </div>
            </ConfirmModal>
        </Container>
    );
};
