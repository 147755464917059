import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

import { VersionList } from '../ui/estimation/VersionList';

const Container = styled.div`
`;

export interface IEstimateVersionModal {
    open: boolean;
    onCancel: () => void;
    onSelect: (versionId: string|null) => void;
}

export const EstimateVersionModal: React.FC<IEstimateVersionModal> = (props: IEstimateVersionModal) => {
    const {open, onCancel, onSelect} = props;

    const [versionId, setVersionId] = React.useState<string|null>(null);

    const onSelectClick = () => {
        onSelect(versionId);
        setVersionId(null);
    };

    return (
        <Dialog open={open} maxWidth="lg">
            <DialogTitle>Revisions</DialogTitle>
            <DialogContent>
                <Container>
                    <VersionList onSelect={(id) => setVersionId(id)} />
                </Container>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={onCancel}>Close</Button>
                <Button variant="contained" color="primary" onClick={onSelectClick} disabled={versionId === null}>Select</Button>
            </DialogActions>
        </Dialog>
    );
};
