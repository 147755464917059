import {takeEvery, put, call, takeLatest} from 'redux-saga/effects';
import {ILoginReponse, IAuthUser} from './Models';
import * as Store from './AuthStore';
import * as Service from './Service';

export function* loginSaga(action: Store.AuthLoginAction) {
    try {
        const {email, password} = action.payload;

        const result: ILoginReponse = yield call(Service.login, email, password);

        localStorage.setItem('token', result.token);

        // set the user on the auth store
        yield put(Store.authLoginSuccessAction(result));

        yield window.location.hash = '#/estimates';

    } catch (e) {
        console.error(e);
        yield put(Store.authErrorAction({message: 'Incorrect username or password.'}));
    }
}

export function* logoutSaga() {
    yield localStorage.removeItem('token');
}

export function* currentUserSaga() {
    try {
        const token: string = yield localStorage.getItem('token');
        let result: IAuthUser;

        if (token) {
            result  = yield call(Service.getCurrentUser)
    
        } else {
            result = {} as IAuthUser;
        }

        yield put(Store.authCurrentUserSuccessAction({user: result}));
        
    } catch (e: any) {
        console.error(e);
        yield put(Store.authErrorAction(e.message));
    }
}

export function* authSagas() {
    yield takeEvery(Store.AUTH_LOGIN, loginSaga);
    yield takeEvery(Store.AUTH_LOGOUT, logoutSaga);
    yield takeLatest(Store.AUTH_CURRENT_USER, currentUserSaga);
}
