import * as React from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {Snackbar, Alert, AlertTitle} from '@mui/material';

import { IRootStore } from '../../RootStore';
import {NotificationStoresType, notificationsRemoveAction} from '../../modules';

const Container = styled.div`
`;

const titleMap = {
    'error': 'Error',
    'info': 'Informational',
    'warning': 'Warning',
    'success': 'Success'
};

export interface INotificationCenter {
}

export const NotificationCenter: React.FC<INotificationCenter> = (props: INotificationCenter) => {
    const dispatch = useDispatch();

    const {stores} = useSelector((state: IRootStore) => state.notifications);

    const notificationEls = Object.keys(stores)
        .filter(x => stores[x as NotificationStoresType] !== undefined)
        .map((key, index) => {

            const notification = stores[key as NotificationStoresType];
            const {message, severity, timeout, dismissable} = notification!;

            if (timeout) {
                setTimeout(() => {
                    dispatch(notificationsRemoveAction({store: key as NotificationStoresType}));
                }, timeout);
            }

            const props: {[key: string]: any} = {
                key: index,
                severity,
            };

            if (dismissable) {
                props.onClose = () => dispatch(notificationsRemoveAction({store: key as NotificationStoresType}))
            }

            return (
                <Alert {...props}>
                    <AlertTitle>{titleMap[severity]}</AlertTitle>
                    {message}
                </Alert>
            );            
        })

    if (notificationEls.length < 1) {
        return null;
    }

    return(
        <Container>
            <Snackbar open={notificationEls.length > 0} autoHideDuration={6000} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <div>
                    {notificationEls}
                </div>
            </Snackbar>
        </Container>
    );
};
