import * as React from 'react';
import styled from 'styled-components';

import {DefaultRatesAndRoles, DefaultRanges} from '..';

const Container = styled.div`
`;

export interface ISettingsTab {
}

export const SettingsTab: React.FC<ISettingsTab> = (props: ISettingsTab) => {

    return(
        <Container>
            <p>Changing these settings will only affect newly created estimates. Prior estimates will not be changed or updated.</p>
            <DefaultRatesAndRoles />
            <DefaultRanges />
        </Container>
    );
};

